import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EC18 from"assets/img/EC18.png";
import EC19 from"assets/img/EC19.png";
import EC20 from"assets/img/EC20.png";
import EC21 from"assets/img/EC21.png";

// Define CustomPrevArrow and CustomNextArrow components outside of SliderPage

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      {/* custom left arrow icon or text */}
      {/* <span style={{ color: "blue", fontSize: "24px" }}>{"<"}</span> */}
      <span>{"<"}</span>
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      {/* custom right arrow icon or text with red color */}
      {/* <span style={{ color: "red", backgroundColor: "red", fontSize: "24px" }}>{">"}</span> */}
      <span>{">"}</span>
    </div>
  );
};

const SliderPage = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <section>
      <div>
        <Slider {...settings}>
          <div>
            <div className="center-button">
              <img
                class="w-32 "
                src={EC18}
                alt=""
              />
            </div>
          </div>

          <div className="">
            <div className="center-button">
              <img
                class="w-32"
                src={EC19}
                alt=""
              />
            </div>
          </div>

          <div className="">
            <div class="center-button">
              <img
                class="w-32 "
                src={EC20}
                alt=""
              />
            </div>
          </div>

          <div className=" ">
            <div class="center-button">
              <img
                class="w-32 "
                src={EC21}
                alt=""
              />
            </div>
          </div>

          <div className="">
            <div class="center-button">
              <img
                class="w-32 "
                src={EC18}
                alt=""
              />
            </div>
          </div>

          <div className="">
            <div class="center-button">
              <img
                class="w-32 "
                src={EC19}
                alt=""
              />
            </div>
          </div>

          <div className="">
            <div class="center-button">
              <img
                class="w-32 "
                src={EC20}
                alt=""
              />
            </div>
          </div>

          <div className="">
            <div class="center-button">
              <img
                class="w-32"
                src={EC21}
                alt=""
              />
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default SliderPage;
