import React from "react";
import { Link } from "react-router-dom";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Footer2 from "components/Footers/Footer2";
import iconn1 from "assets/img/iconn1.png";
import Abanner4 from "assets/img/Abanner4.jpg";
import EC5 from "assets/img/EC5.png";
import EC6 from "assets/img/EC6.png";
import EC7 from "assets/img/EC7.png";
import EC8 from "assets/img/EC8.png";
import EC9 from "assets/img/EC9.png";
import EC10 from "assets/img/EC10.png";
import EC11 from "assets/img/EC11.png";
import EC12 from "assets/img/EC12.png";
import EC13 from "assets/img/EC13.png";

export default function Services2() {
  return (
    <>
      <Indexnavbar2 fixed />
      <section className="xl:block lg:block md:hidden sm:hidden hidden roboto ">
        <div className="xl:block lg:block md:hidden hidden sm:hidden roboto">
          <div
            style={{
              backgroundImage: `url(${Abanner4})`,
              width: "100%",
              height: "300px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div class="container mx-auto xl:px-20">
              <h3 class="xl:pt-28 lg:pt-32 text-white font-bold figtree xl:text-5xlll xl:ml-2 lg:text-4xl text-center">
                Services
              </h3>
              <div className="flex justify-center xl:mt-2">
                <p class="xl:text-sm text-white blue-on-hover lg:text-sm">
                  <a href="/">Home</a>
                </p>
                <p class="xl:text-base1 text-white ml-2 xl:-mt-2 lg:text-base1 ">
                  <a href="/">»</a>
                </p>
                <p class="xl:text-sm text-skyblue ml-2 lg:text-sm">
                  <a href="/">Services</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:block md:hidden sm:hidden hidden xl:pb-16 lg:pb-16">
        <div className="container mx-auto xl:px-20 lg:px-20">
          <p class="xl:text-base font-semibold xl:pt-16 text-skyblue text-center lg:text-base lg:pt-16">
            WORKING PROCESS
          </p>
          <div className="flex justify-center mt-2">
            <p class="xl:text-5xlll font-bold text-darkblack lg:text-4xl">
              How Our
            </p>
            <p class="xl:text-5xlll font-bold text-skyblue ml-2 lg:text-4xl">
              Fishred
            </p>
            <p class="xl:text-5xlll font-bold text-darkblack ml-2 lg:text-4xl">
              Works
            </p>
          </div>
          <div className="flex justify-content-between gap-0 mt-8">
            <div>
              <div className="bg-newshade32 w-8 h-8 rounded-full absolute border-9 xl:ml-12 lg:ml-6">
                <p class="xl:text-sm text-white text-center xl:pt-1 lg:pt-0 lg:text-sm">
                  01
                </p>
              </div>
              <div className="center-button ">
                <img class="w-20 rounded-full border-8 p-2" src={EC11} alt="" />
              </div>
              <h2 class="xl:text-lg xl:pt-2 font-bold text-darkblack blue-on-hover text-center xl:text-base lg:pt-1">
                Choose Destination
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                It is a long established fact that a reader will be distracted.
              </p>
            </div>

            <div>
              <div>
                <img class="w-52 mt-4" src={iconn1} />
              </div>
            </div>

            <div className="">
              <div className="bg-newshade32 w-8 h-8 rounded-full absolute border-9 xl:ml-12 lg:ml-6">
                <p class="xl:text-sm text-white text-center xl:pt-1 lg:text-sm">
                  02
                </p>
              </div>
              <div className="center-button ">
                <img class="w-20 rounded-full border-8 p-2" src={EC12} alt="" />
              </div>
              <h2 class="xl:text-lg xl:pt-2 font-bold text-darkblack blue-on-hover text-center lg:text-sm lg:pt-1">
                Make An Appointment
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                It is a long established fact that a reader will be distracted.
              </p>
            </div>

            <div>
              <div>
                <img class="w-52 mt-4" src={iconn1} />
              </div>
            </div>

            <div>
              <div className="bg-newshade32 w-8 h-8 rounded-full absolute border-9 xl:ml-12 lg:ml-6">
                <p class="xl:text-sm text-white text-center xl:pt-1 lg:pt-0 lg:text-sm">
                  03
                </p>
              </div>
              <div className="center-button ">
                <img class="w-20 rounded-full border-8 p-2" src={EC13} alt="" />
              </div>
              <h2 class="xl:text-lg xl:pt-2 font-bold text-darkblack blue-on-hover text-center lg:text-base lg:pt-1">
                Consult Expert Staff
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                It is a long established fact that a reader will be distracted.
              </p>
            </div>

            <div>
              <div>
                <img class="w-52 mt-4" src={iconn1} />
              </div>
            </div>

            <div>
              <div className="bg-newshade32 w-8 h-8 rounded-full absolute border-9 xl:ml-12 lg:ml-6">
                <p class="xl:text-sm text-white text-center xl:pt-1 lg:pt-0 lg:text-sm">
                  04
                </p>
              </div>
              <div className="center-button ">
                <img class="w-20 rounded-full border-8 p-2" src={EC12} alt="" />
              </div>
              <h2 class="xl:text-lg xl:pt-2 font-bold text-darkblack blue-on-hover text-center lg:text-base lg:pt-1">
                Enjoy Your Fishing
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                It is a long established fact that a reader will be distracted.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:block md:hidden sm:hidden hidden roboto bg-newshade34 xl:pb-20 lg:pb-16">
        <div className="container mx-auto xl:px-20 lg:px-20">
          <p class="xl:text-base font-semibold xl:pt-16 text-skyblue text-center lg:text-base lg:pt-16">
            SERVICES
          </p>
          <div className="flex justify-center mt-2">
            <p class="xl:text-5xlll font-bold text-darkblack lg:text-4xl">
              What We
            </p>
            <p class="xl:text-5xlll font-bold text-skyblue ml-2 lg:text-4xl">
              Offers To You
            </p>
          </div>
          <p class="xl:text-sm text-grey-600 text-center mt-4 lg:text-sm">
            It is a long established fact that a reader will be distracted by
            the readable<br></br> content of a page when looking at its layout.
          </p>

          <div className="grid grid-cols-3  mt-8">
            <div>
              <div className="bg-white custom-borderrrr xl:p-6 lg:p-6">
                <div className="center-button ">
                  <img
                    class="w-20 rounded-full border-8 p-2"
                    src={EC5}
                    alt=""
                  />
                </div>
                <h2 class="xl:text-base1 xl:pt-2 font-bold text-darkblack blue-on-hover text-center lg:text-base1 lg:pt-2">
                  Solo & Team Fishing
                </h2>
                <p class="text-smm text-grey-600 pt-2 text-center">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>
                <div className="center-button">
                  <a href="/">
                    <button class="text-smm bg-newshade32 text-white font-semibold xl:px-6 xl:py-2 xl:mt-5 rounded-lg lg:px-6 lg:py-2 lg:mt-5">
                      Read More ➜{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div>
              <div className="bg-white custom-borderrrr xl:p-6 ml-4 lg:p-6">
                <div className="center-button ">
                  <img
                    class="w-20 rounded-full border-8 p-2 "
                    src={EC6}
                    alt=""
                  />
                </div>
                <h2 class="xl:text-base1 xl:pt-2 font-bold text-darkblack blue-on-hover text-center lg:text-base1 lg:pt-2">
                  Fishing Competitions
                </h2>
                <p class="text-smm text-grey-600 pt-2 text-center">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>
                <div className="center-button">
                  <a href="/">
                    <button class="text-smm bg-newshade32 text-white font-semibold xl:px-6 xl:py-2 xl:mt-5 rounded-lg lg:px-6 lg:py-2 lg:mt-5">
                      Read More ➜{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div>
              <div className="bg-white custom-borderrrr xl:p-6 ml-4 lg:p-6">
                <div className="center-button ">
                  <img
                    class="w-20 rounded-full border-8 p-2 "
                    src={EC7}
                    alt=""
                  />
                </div>
                <h2 class="xl:text-base1 xl:pt-2 font-bold text-darkblack blue-on-hover text-center lg:text-base1 lg:pt-2 ">
                  Fishing Equipments
                </h2>
                <p class="text-smm text-grey-600 pt-2 text-center">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>
                <div className="center-button">
                  <a href="/">
                    <button class="text-smm bg-newshade32 text-white font-semibold xl:px-6 xl:py-2 xl:mt-5 rounded-lg lg:px-6 lg:py-2 lg:mt-5">
                      Read More ➜{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-3  mt-4">
            <div>
              <div className="bg-white custom-borderrrr xl:p-6 lg:p-6">
                <div className="center-button ">
                  <img
                    class="w-20 rounded-full border-8 p-2 "
                    src={EC8}
                    alt=""
                  />
                </div>
                <h2 class="xl:text-base1 xl:pt-2 font-bold text-darkblack blue-on-hover text-center lg:text-base1 lg:pt-2">
                  Fishing Guidence
                </h2>
                <p class="text-smm text-grey-600 pt-2 text-center">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>
                <div className="center-button">
                  <a href="/">
                    <button class="text-smm bg-newshade32 text-white font-semibold xl:px-6 xl:py-2 xl:mt-5 rounded-lg lg:px-6 lg:py-2">
                      Read More ➜{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div>
              <div className="bg-white custom-borderrrr xl:p-6 ml-4 lg:p-6">
                <div className="center-button ">
                  <img
                    class="w-20 rounded-full border-8 p-2 "
                    src={EC10}
                    alt=""
                  />
                </div>
                <h2 class="xl:text-base1 xl:pt-2 font-bold text-darkblack blue-on-hover text-center lg:text-base1 lg:pt-2">
                  Fishing Tour
                </h2>
                <p class="text-smm text-grey-600 pt-2 text-center">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>
                <div className="center-button">
                  <a href="/">
                    <button class="text-smm bg-newshade32 text-white font-semibold xl:px-6 xl:py-2 xl:mt-5 rounded-lg lg:px-6 lg:py-2">
                      Read More ➜{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div>
              <div className="bg-white custom-borderrrr xl:p-6 ml-4 lg:p-6">
                <div className="center-button ">
                  <img
                    class="w-20 rounded-full border-8 p-2 "
                    src={EC11}
                    alt=""
                  />
                </div>
                <h2 class="xl:text-base1 xl:pt-2 font-bold text-darkblack blue-on-hover text-center lg:text-base1 lg:pt-2">
                  Fishing Boats
                </h2>
                <p class="text-smm text-grey-600 pt-2 text-center">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>
                <div className="center-button">
                  <a href="/">
                    <button class="text-smm bg-newshade32 text-white font-semibold xl:px-6 xl:py-2 xl:mt-5 rounded-lg lg:px-6 lg:py-2">
                      Read More ➜{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>










          </div>
        </div>
      </section>

      {/*TAB VIEW*/}

      <div className="xl:hidden lg:hidden md:block hidden sm:hidden roboto">
        <div
          style={{
            backgroundImage: `url(${Abanner4})`,
            width: "100%",
            height: "300px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div class="container mx-auto px-12">
            <h3 class="pt-32  text-white font-bold figtree text-4xl ml-2 text-center">
              Services
            </h3>
            <div className="flex justify-center">
              <p class="text-sm text-white blue-on-hover ">
                <a href="/">Home</a>
              </p>
              <p class="text-base1 text-white ml-2 mt-0 ">
                <a href="/">»</a>
              </p>
              <p class="text-sm text-skyblue ml-2 ">
                <a href="/">services</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden pb-16 px-12">
        <div className="container mx-auto ">
          <p class="text-base font-semibold pt-8 text-skyblue text-center ">
            WORKING PROCESS
          </p>
          <div className="flex justify-center mt-2">
            <p class="text-4xl font-bold text-darkblack ">How Our</p>
            <p class="text-4xl font-bold text-skyblue ml-2 ">Fishred</p>
            <p class="text-4xl font-bold text-darkblack ml-2 ">Works</p>
          </div>

          <div className="grid grid-cols-2 mt-12">
            <div>
              <div className="bg-newshade32 w-8 h-8 rounded-full absolute border-9 ml-28 ">
                <p class="text-sm text-white text-center pt-1 text-sm">01</p>
              </div>
              <div className="center-button ">
                <img class="w-20 rounded-full border-8 p-2" src={EC11} alt="" />
              </div>
              <h2 class="text-lg pt-2 font-bold text-darkblack blue-on-hover text-center  lg:pt-1">
                Choose Destination
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                It is a long established fact that a reader will be distracted.
              </p>
            </div>

            <div className="">
              <div className="bg-newshade32 w-8 h-8 rounded-full absolute border-9 ml-28 ">
                <p class="text-sm text-white text-center pt-1 ">02</p>
              </div>
              <div className="center-button ">
                <img class="w-20 rounded-full border-8 p-2" src={EC12} alt="" />
              </div>
              <h2 class="text-lg pt-2 font-bold text-darkblack blue-on-hover text-center ">
                Make An Appointment
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                It is a long established fact that a reader will be distracted.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-2 mt-12">
            <div>
              <div className="bg-newshade32 w-8 h-8 rounded-full absolute border-9 ml-28 ">
                <p class="text-sm text-white text-center pt-1 ">03</p>
              </div>
              <div className="center-button ">
                <img class="w-20 rounded-full border-8 p-2" src={EC13} alt="" />
              </div>
              <h2 class="text-lg pt-2 font-bold text-darkblack blue-on-hover text-center lg:text-base ">
                Consult Expert Staff
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                It is a long established fact that a reader will be distracted.
              </p>
            </div>

            <div>
              <div className="bg-newshade32 w-8 h-8 rounded-full absolute border-9 ml-28 ">
                <p class="text-sm text-white text-center pt-1 ">04</p>
              </div>
              <div className="center-button ">
                <img class="w-20 rounded-full border-8 p-2" src={EC12} alt="" />
              </div>
              <h2 class="text-lg pt-2 font-bold text-darkblack blue-on-hover text-center lg:text-base ">
                Enjoy Your Fishing
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                It is a long established fact that a reader will be distracted.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden roboto bg-newshade34 pb-16">
        <div className="container mx-auto px-12">
          <p class="text-base font-semibold pt-8 text-skyblue text-center ">
            SERVICES
          </p>
          <div className="flex justify-center mt-2">
            <p class=" font-bold text-darkblack text-4xl">What We</p>
            <p class=" font-bold text-skyblue ml-2 text-4xl">Offers To You</p>
          </div>
          <p class="text-sm text-grey-600 text-center mt-4 ">
            It is a long established fact that a reader will be distracted by
            the readable<br></br> content of a page when looking at its layout.
          </p>

          <div className="grid grid-cols-2 mt-8">
            <div>
              <div className="bg-white custom-borderrrr p-6 ">
                <div className="center-button ">
                  <img
                    class="w-20 rounded-full border-8 p-2"
                    src={EC5}
                    alt=""
                  />
                </div>
                <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center ">
                  Solo & Team Fishing
                </h2>
                <p class="text-smm text-grey-600 pt-2 text-center">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>
                <div className="center-button">
                  <a href="/">
                    <button class="text-smm bg-newshade32 text-white font-semibold px-6 py-2 mt-4 rounded-lg ">
                      Read More ➜{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div>
              <div className="bg-white custom-borderrrr p-6 ml-4 ">
                <div className="center-button ">
                  <img
                    class="w-20 rounded-full border-8 p-2 "
                    src={EC6}
                    alt=""
                  />
                </div>
                <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center ">
                  Fishing Competitions
                </h2>
                <p class="text-smm text-grey-600 pt-2 text-center">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>
                <div className="center-button">
                  <a href="/">
                    <button class="text-smm bg-newshade32 text-white font-semibold px-6 py-2 mt-4 rounded-lg ">
                      Read More ➜{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 mt-12">
            <div>
              <div className="bg-white custom-borderrrr p-6  ">
                <div className="center-button ">
                  <img
                    class="w-20 rounded-full border-8 p-2 "
                    src={EC7}
                    alt=""
                  />
                </div>
                <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center ">
                  Fishing Equipments
                </h2>
                <p class="text-smm text-grey-600 pt-2 text-center">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>
                <div className="center-button">
                  <a href="/">
                    <button class="text-smm bg-newshade32 text-white font-semibold px-6 py-2 mt-4 rounded-lg ">
                      Read More ➜{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div>
              <div className="bg-white custom-borderrrr p-6 ml-4">
                <div className="center-button ">
                  <img
                    class="w-20 rounded-full border-8 p-2 "
                    src={EC8}
                    alt=""
                  />
                </div>
                <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center ">
                  Fishing Guidence
                </h2>
                <p class="text-smm text-grey-600 pt-2 text-center">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>
                <div className="center-button">
                  <a href="/">
                    <button class="text-smm bg-newshade32 text-white font-semibold px-6 py-2 mt-4 rounded-lg ">
                      Read More ➜{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 mt-8">
          <div>
              <div className="bg-white custom-borderrrr p-6  ">
                <div className="center-button ">
                  <img
                    class="w-20 rounded-full border-8 p-2 "
                    src={EC10}
                    alt=""
                  />
                </div>
                <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center ">
                  Fishing Tour
                </h2>
                <p class="text-smm text-grey-600 pt-2 text-center">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>
                <div className="center-button">
                  <a href="/">
                    <button class="text-smm bg-newshade32 text-white font-semibold px-6 py-2 mt-4 rounded-lg ">
                      Read More ➜{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div>
              <div className="bg-white custom-borderrrr p-6 ml-4 ">
                <div className="center-button ">
                  <img
                    class="w-20 rounded-full border-8 p-2 "
                    src={EC11}
                    alt=""
                  />
                </div>
                <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center ">
                  Fishing Boats
                </h2>
                <p class="text-smm text-grey-600 pt-2 text-center">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>
                <div className="center-button">
                  <a href="/">
                    <button class="text-smm bg-newshade32 text-white font-semibold px-6 py-2 mt-4 rounded-lg ">
                      Read More ➜{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*MOBILE VIEW*/}

      <div className="xl:hidden lg:hidden md:hidden block sm:block roboto">
        <div
          style={{
            backgroundImage: `url(${Abanner4})`,
            width: "100%",
            height: "300px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div class="container mx-auto px-12">
            <h3 class="pt-32  text-white font-bold figtree text-4xl ml-2 text-center">
              Services
            </h3>
            <div className="flex justify-center">
              <p class="text-sm text-white blue-on-hover ">
                <a href="/">Home</a>
              </p>
              <p class="text-base1 text-white ml-2 mt-0 ">
                <a href="/">»</a>
              </p>
              <p class="text-sm text-skyblue ml-2 ">
                <a href="/">services</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="xl:hidden lg:hidden md:hidden sm:block block pb-16 px-12">
        <div className="container mx-auto ">
          <p class="text-base font-semibold pt-8 text-skyblue text-center ">
            WORKING PROCESS
          </p>
          <div className="flex justify-center mt-2">
            <p class="text-4xl font-bold text-darkblack ">How Our</p>
            <p class="text-4xl font-bold text-skyblue ml-2 ">Fishred</p>
            </div>
            <p class="text-3xl font-bold text-darkblack text-center ">Works</p>
          <div className=" mt-12">
            <div>
              <div className="bg-newshade32 w-8 h-8 rounded-full absolute border-9 ml-24 ">
                <p class="text-sm text-white text-center pt-1 text-sm">01</p>
              </div>
              <div className="center-button ">
                <img class="w-20 rounded-full border-8 p-2" src={EC11} alt="" />
              </div>
              <h2 class="text-lg pt-2 font-bold text-darkblack blue-on-hover text-center  lg:pt-1">
                Choose Destination
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                It is a long established fact that a reader will be distracted.
              </p>
            </div>

            <div className="mt-8">
              <div className="bg-newshade32 w-8 h-8 rounded-full absolute border-9 ml-24 ">
                <p class="text-sm text-white text-center pt-1 ">02</p>
              </div>
              <div className="center-button ">
                <img class="w-20 rounded-full border-8 p-2" src={EC12} alt="" />
              </div>
              <h2 class="text-lg pt-2 font-bold text-darkblack blue-on-hover text-center ">
                Make An Appointment
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                It is a long established fact that a reader will be distracted.
              </p>
            </div>
          </div>

          <div className=" mt-8">
            <div>
              <div className="bg-newshade32 w-8 h-8 rounded-full absolute border-9 ml-24 ">
                <p class="text-sm text-white text-center pt-1 ">03</p>
              </div>
              <div className="center-button ">
                <img class="w-20 rounded-full border-8 p-2" src={EC13} alt="" />
              </div>
              <h2 class="text-lg pt-2 font-bold text-darkblack blue-on-hover text-center lg:text-base ">
                Consult Expert Staff
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                It is a long established fact that a reader will be distracted.
              </p>
            </div>

            <div className="mt-8">
              <div className="bg-newshade32 w-8 h-8 rounded-full absolute border-9 ml-24  ">
                <p class="text-sm text-white text-center pt-1 ">04</p>
              </div>
              <div className="center-button ">
                <img class="w-20 rounded-full border-8 p-2" src={EC12} alt="" />
              </div>
              <h2 class="text-lg pt-2 font-bold text-darkblack blue-on-hover text-center lg:text-base ">
                Enjoy Your Fishing
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                It is a long established fact that a reader will be distracted.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block roboto bg-newshade34 pb-16">
        <div className="container mx-auto px-4">
          <p class="text-base font-semibold pt-8 text-skyblue text-center ">
            SERVICES
          </p>
          <div className=" justify-center mt-2">
            <p class=" font-bold text-darkblack text-4xl text-center">What We</p>
            <p class=" font-bold text-skyblue  text-4xl text-center">Offers To You</p>
          </div>
          <p class="text-sm text-grey-600 text-center mt-4 ">
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout.
          </p>

          <div className=" mt-8">
            <div>
              <div className="bg-white custom-borderrrr p-6 ">
                <div className="center-button ">
                  <img
                    class="w-20 rounded-full border-8 p-2"
                    src={EC5}
                    alt=""
                  />
                </div>
                <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center ">
                  Solo & Team Fishing
                </h2>
                <p class="text-smm text-grey-600 pt-2 text-center">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>
                <div className="center-button">
                  <a href="/">
                    <button class="text-smm bg-newshade32 text-white font-semibold px-6 py-2 mt-4 rounded-lg ">
                      Read More ➜{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <div className="bg-white custom-borderrrr p-6 ">
                <div className="center-button ">
                  <img
                    class="w-20 rounded-full border-8 p-2 "
                    src={EC6}
                    alt=""
                  />
                </div>
                <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center ">
                  Fishing Competitions
                </h2>
                <p class="text-smm text-grey-600 pt-2 text-center">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>
                <div className="center-button">
                  <a href="/">
                    <button class="text-smm bg-newshade32 text-white font-semibold px-6 py-2 mt-4 rounded-lg ">
                      Read More ➜{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className=" mt-8">
            <div>
              <div className="bg-white custom-borderrrr p-6  ">
                <div className="center-button ">
                  <img
                    class="w-20 rounded-full border-8 p-2 "
                    src={EC7}
                    alt=""
                  />
                </div>
                <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center ">
                  Fishing Equipments
                </h2>
                <p class="text-smm text-grey-600 pt-2 text-center">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>
                <div className="center-button">
                  <a href="/">
                    <button class="text-smm bg-newshade32 text-white font-semibold px-6 py-2 mt-4 rounded-lg ">
                      Read More ➜{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <div className="bg-white custom-borderrrr p-6 ">
                <div className="center-button ">
                  <img
                    class="w-20 rounded-full border-8 p-2 "
                    src={EC8}
                    alt=""
                  />
                </div>
                <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center ">
                  Fishing Guidence
                </h2>
                <p class="text-smm text-grey-600 pt-2 text-center">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>
                <div className="center-button">
                  <a href="/">
                    <button class="text-smm bg-newshade32 text-white font-semibold px-6 py-2 mt-4 rounded-lg ">
                      Read More ➜{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className=" mt-8">
          <div>
              <div className="bg-white custom-borderrrr p-6  ">
                <div className="center-button ">
                  <img
                    class="w-20 rounded-full border-8 p-2 "
                    src={EC10}
                    alt=""
                  />
                </div>
                <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center ">
                  Fishing Tour
                </h2>
                <p class="text-smm text-grey-600 pt-2 text-center">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>
                <div className="center-button">
                  <a href="/">
                    <button class="text-smm bg-newshade32 text-white font-semibold px-6 py-2 mt-4 rounded-lg ">
                      Read More ➜{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <div className="bg-white custom-borderrrr p-6  ">
                <div className="center-button ">
                  <img
                    class="w-20 rounded-full border-8 p-2 "
                    src={EC11}
                    alt=""
                  />
                </div>
                <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center ">
                  Fishing Boats
                </h2>
                <p class="text-smm text-grey-600 pt-2 text-center">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>
                <div className="center-button">
                  <a href="/">
                    <button class="text-smm bg-newshade32 text-white font-semibold px-6 py-2 mt-4 rounded-lg ">
                      Read More ➜{" "}
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*ENDD*/}

      <Footer2 />
    </>
  );
}
