import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer2 from "components/Footers/Footer2";
import Abanner4 from "assets/img/Abanner4.jpg";
import Acontact1 from "assets/img/Acontact1.jpg";
import mail from "assets/img/mail.png";
import location from "assets/img/location.png";
import alaram from "assets/img/alarm.png";
import telephone from "assets/img/telephone.png";
import calendar from "assets/img/calendar.png";

export default function Homepage1() {
  return (
    <section>
      <Indexnavbar2 />

      <section className="xl:block lg:block md:hidden sm:hidden hidden roboto ">
        <div className="xl:block lg:block md:hidden hidden sm:hidden roboto">
          <div
            style={{
              backgroundImage: `url(${Abanner4})`,
              width: "100%",
              height: "300px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div class="container mx-auto xl:px-20 lg:px-20">
              <h3 class="xl:pt-28 lg:pt-32 text-white font-bold figtree xl:text-5xl xl:ml-2 lg:text-4xl text-center">
                Contact
              </h3>
              <div className="flex justify-center xl:mt-2 lg:mt-2">
                <p class="xl:text-sm text-white blue-on-hover lg:text-sm">
                  <a href="/">Home</a>
                </p>
                <p class="xl:text-base1 text-white ml-2 xl:-mt-2 lg:text-base1 ">
                  <a href="/">»</a>
                </p>
                <p class="xl:text-sm text-skyblue ml-2 lg:text-sm">
                  <a href="/">Contact Us</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:block md:hidden sm:hidden hidden xl:mt-20 roboto lg:mt-20">
        <div className="container mx-auto xl:px-20 lg:px-20">
          <div className="grid grid-cols-4">
            <div className="bg-newshade34 custom-borderrrr xl:p-6 lg:p-6">
              <div className="center-button ">
                <img
                  class="w-14 rounded-full  p-2 bg-newshade32"
                  src={location}
                />
              </div>
              <h2 class="xl:text-base1 xl:pt-2 font-bold text-darkblack blue-on-hover text-center lg:text-base1 lg:pt-2">
                Office Address
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                25/B Milford, New York, USA
              </p>
            </div>

            <div className="bg-newshade34 custom-borderrrr xl:p-6 ml-4 lg:p-6">
              <div className="center-button ">
                <img
                  class="w-14 rounded-full  p-2 bg-newshade32"
                  src={telephone}
                />
              </div>
              <h2 class="xl:text-base1 xl:pt-2 font-bold text-darkblack blue-on-hover text-center lg:text-base1 lg:pt-2">
                Call Us
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                +2 123 4565 789
              </p>
            </div>

            <div className="bg-newshade34 custom-borderrrr xl:p-6 ml-4 lg:p-6">
              <div className="center-button ">
                <img class="w-14 rounded-full  p-2 bg-newshade32" src={mail} />
              </div>
              <h2 class="xl:text-base1 xl:pt-2 font-bold text-darkblack blue-on-hover text-center lg:text-base1 lg:pt-2">
                Email Us
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                info@example.com
              </p>
            </div>

            <div className="bg-newshade34 custom-borderrrr xl:p-6 ml-4 lg:p-6">
              <div className="center-button ">
                <img
                  class="w-14 rounded-full  p-2 bg-newshade32"
                  src={alaram}
                />
              </div>
              <h2 class="xl:text-base1 xl:pt-2 font-bold text-darkblack blue-on-hover text-center lg:text-base1 lg:pt-2">
                Open Time
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                Mon - Sat (10.00AM - 05.30PM)
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:block md:hidden sm:hidden hidden xl:mt-20 roboto container mx-auto xl:px-20 xl:pb-16 lg:px-20 lg:pb-16 lg:mt-20">
        <div className="container mx-aut0">
          <div className="grid grid-cols-2">
            <div>
              <div className="">
                <img
                  class="custom-borderrr xl:w-10/12 lg:w-12/12"
                  src={Acontact1}
                  alt=""
                />
              </div>
            </div>

            <div className="bg-newshade34 xl:-ml-24 xl:p-6 lg:-ml-24 lg:p-6">
              <h1 class="xl:text-base1 font-bold text-darkblack xl:pt-4 lg:text-base1 lg:pt-2">
                Get Your Quote
              </h1>
              <p class="xl:text-sm pt-4 text-grey-600 lg:text-sm">
                It is a long established fact that a reader will be distracted
                by the readable content majority have suffered alteration page
                when looking at its layout.
              </p>

              <form>
                <div className="xl:mt-8 lg:mt-8 ">
                  <div className="flex ">
                    <input
                      class="bg-white rounded-md border-5  xl:w-6/12 lg:w-6/12"
                      type="text"
                      id="text"
                      placeholder="Your Name"
                    />
                    <input
                      class="bg-white rounded-md border-5  xl:w-6/12 ml-2 lg:w-6/12"
                      type="text"
                      id="text"
                      placeholder="Email Address"
                    />
                  </div>

                  <div className="flex mt-4 ">
                    <input
                      class="bg-white rounded-md border-5  xl:w-6/12 lg:w-6/12"
                      type="number"
                      id="number"
                      placeholder="Phone Number"
                    />
                    <select
                      class="bg-white rounded-md border-5  xl:w-6/12 ml-2 lg:w-6/12"
                      id="service"
                      name="service"
                    >
                      <option value="Choose Service">Choose Service</option>
                      <option value="Solo & team fishing">
                        Solo & team fishing
                      </option>
                      <option value="fishing tour">fishing tour</option>
                      <option value="fishing competiton">
                        fishing competiton
                      </option>
                    </select>
                  </div>

                  <textarea
                    class="rounded-md mt-4 xl:w-12/12 border-5 lg:w-12/12"
                    id="Message"
                    name="Write Message"
                    rows="5"
                    cols="53"
                    placeholder="Write Message"
                  ></textarea>

                  <div>
                    <button className="text-white xl:px-6 xl:py-3 xl:mt-4 bg-newshade32 rounded-md lg:px-6 lg:p-2 lg:mt-4">
                      Submit Now
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/*TAB VIEW*/}

      <div className="xl:hidden lg:hidden md:block hidden sm:hidden roboto">
        <div
          style={{
            backgroundImage: `url(${Abanner4})`,
            width: "100%",
            height: "300px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div class="container mx-auto px-12">
            <h3 class="pt-32  text-white font-bold figtree text-4xl ml-2 text-center">
              Contact Us
            </h3>
            <div className="flex justify-center">
              <p class="text-sm text-white blue-on-hover ">
                <a href="/">Home</a>
              </p>
              <p class="text-base1 text-white ml-2 mt-0 ">
                <a href="/">»</a>
              </p>
              <p class="text-sm text-skyblue ml-2 ">
                <a href="/">Contact</a>
              </p>
            </div>
          </div>
        </div>
      </div>


      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 roboto pb-16 ">
        <div className="container mx-auto px-12">
          <div className="grid grid-cols-2">
            <div className="bg-newshade34 custom-borderrrr p-6 ">
              <div className="center-button ">
                <img
                  class="w-14 rounded-full  p-2 bg-newshade32"
                  src={location}
                />
              </div>
              <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center">
                Office Address
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                25/B Milford, New York, USA
              </p>
            </div>

            <div className="bg-newshade34 custom-borderrrr p-6 ml-4 ">
              <div className="center-button ">
                <img
                  class="w-14 rounded-full  p-2 bg-newshade32"
                  src={telephone}
                />
              </div>
              <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center ">
                Call Us
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                +2 123 4565 789
              </p>
            </div>
            </div>


            <div className="grid grid-cols-2 mt-8">
            <div className="bg-newshade34 custom-borderrrr p-6 ml-4 ">
              <div className="center-button ">
                <img class="w-14 rounded-full  p-2 bg-newshade32" src={mail} />
              </div>
              <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center  ">
                Email Us
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                info@example.com
              </p>
            </div>

            <div className="bg-newshade34 custom-borderrrr p-6 ml-4 ">
              <div className="center-button ">
                <img
                  class="w-14 rounded-full  p-2 bg-newshade32"
                  src={alaram}
                />
              </div>
              <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center ">
                Open Time
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                Mon - Sat (10.00AM - 05.30PM)
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 roboto container mx-auto  pb-16 px-12 ">
            <div className="container mx-aut0">
             <div className="">
               <div>
                <div className="">
                  <img class="custom-borderrr w-10/12 " src={Acontact1} alt=""/>
                </div>
               </div>

               <div className="bg-newshade34 mt-12 p-6 pb-8">
                 <h1 class="text-base1 font-bold text-darkblack pt-4 ">Get Your Quote</h1>
                 <p class="text-sm pt-4 text-grey-600 ">It is a long established fact that a reader will be distracted by the readable content  majority have suffered alteration page when looking at its layout.</p>

                 <form>
                 <div className="mt-8  ">
              
                <input
                  class="bg-white rounded-md border-5  w-12/12 "
                  type="text"
                  id="text"
                  placeholder="Your Name"
                />
                <br></br>
                <input
                  class="bg-white rounded-md border-5 mt-4 w-12/12  "
                  type="text"
                  id="text"
                  placeholder="Email Address"
                />
              

               <br></br>
                <input
                  class="bg-white rounded-md border-5 mt-4 w-12/12 "
                  type="number"
                  id="number"
                  placeholder="Phone Number"
                />
                <br></br>

                <select class="bg-white rounded-md border-5 mt-4 w-12/12  " id="service" name="service">
                 <option value="Choose Service">Choose Service</option>
                 <option value="Solo & team fishing">Solo & team fishing</option>
                 <option value="fishing tour">fishing tour</option>
                 <option value="fishing competiton">fishing competiton</option>
                 </select>
              

              <textarea
                class="rounded-md mt-4 w-12/12 border-5 "
                id="Message"
                name="Write Message"
                rows="5"
                cols="53"
                placeholder="Write Message"
              ></textarea>

              <div>
                <button className="text-white px-6 py-3 mt-4 bg-newshade32 rounded-md ">
                  Submit Now
                </button>
              </div>
              </div>
                 </form>
               </div>
             </div>
            </div>

          </section>


      {/*MOBILE VIEW*/}

      <div className="xl:hidden lg:hidden md:hidden block sm:block roboto">
        <div
          style={{
            backgroundImage: `url(${Abanner4})`,
            width: "100%",
            height: "300px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div class="container mx-auto px-12">
            <h3 class="pt-32  text-white font-bold figtree text-4xl ml-2 text-center">
              Contact Us
            </h3>
            <div className="flex justify-center">
              <p class="text-sm text-white blue-on-hover ">
                <a href="/">Home</a>
              </p>
              <p class="text-base1 text-white ml-2 mt-0 ">
                <a href="/">»</a>
              </p>
              <p class="text-sm text-skyblue ml-2 ">
                <a href="/">Contact</a>
              </p>
            </div>
          </div>
        </div>
      </div>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 roboto ">
        <div className="container mx-auto px-12">
          <div className="">
            <div className="bg-newshade34 custom-borderrrr p-6 ">
              <div className="center-button ">
                <img
                  class="w-14 rounded-full  p-2 bg-newshade32"
                  src={location}
                />
              </div>
              <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center">
                Office Address
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                25/B Milford, New York, USA
              </p>
            </div>

            <div className="bg-newshade34 custom-borderrrr p-6 mt-8 ">
              <div className="center-button ">
                <img
                  class="w-14 rounded-full  p-2 bg-newshade32"
                  src={telephone}
                />
              </div>
              <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center ">
                Call Us
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                +2 123 4565 789
              </p>
            </div>
            </div>


            <div className=" mt-8">
            <div className="bg-newshade34 custom-borderrrr p-6  ">
              <div className="center-button ">
                <img class="w-14 rounded-full  p-2 bg-newshade32" src={mail} />
              </div>
              <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center  ">
                Email Us
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                info@example.com
              </p>
            </div>

            <div className="bg-newshade34 custom-borderrrr p-6 mt-8 ">
              <div className="center-button ">
                <img
                  class="w-14 rounded-full  p-2 bg-newshade32"
                  src={alaram}
                />
              </div>
              <h2 class="text-base1 pt-2 font-bold text-darkblack blue-on-hover text-center ">
                Open Time
              </h2>
              <p class="text-smm text-grey-600 pt-2 text-center">
                Mon - Sat (10.00AM - 05.30PM)
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 roboto container mx-auto  pb-16 px-12 ">
            <div className="container mx-aut0">
             <div className="">
               <div>
                <div className="">
                  <img class="custom-borderrr w-12/12 " src={Acontact1} alt=""/>
                </div>
               </div>

               <div className="bg-newshade34 mt-12 p-6 pb-8">
                 <h1 class="text-base1 font-bold text-darkblack pt-4 ">Get Your Quote</h1>
                 <p class="text-sm pt-4 text-grey-600 ">It is a long established fact that a reader will be distracted by the readable content  majority have suffered alteration page when looking at its layout.</p>

                 <form>
                 <div className="mt-8  ">
              
                <input
                  class="bg-white rounded-md border-5  w-12/12 "
                  type="text"
                  id="text"
                  placeholder="Your Name"
                />
                <br></br>
                <input
                  class="bg-white rounded-md border-5 mt-4 w-12/12  "
                  type="text"
                  id="text"
                  placeholder="Email Address"
                />
              

               <br></br>
                <input
                  class="bg-white rounded-md border-5 mt-4 w-12/12 "
                  type="number"
                  id="number"
                  placeholder="Phone Number"
                />
                <br></br>

                <select class="bg-white rounded-md border-5 mt-4 w-12/12  " id="service" name="service">
                 <option value="Choose Service">Choose Service</option>
                 <option value="Solo & team fishing">Solo & team fishing</option>
                 <option value="fishing tour">fishing tour</option>
                 <option value="fishing competiton">fishing competiton</option>
                 </select>
              

              <textarea
                class="rounded-md mt-4 w-12/12 border-5 "
                id="Message"
                name="Write Message"
                rows="5"
                cols="53"
                placeholder="Write Message"
              ></textarea>

              <div>
                <button className="text-white px-6 py-3 mt-4 bg-newshade32 rounded-md ">
                  Submit Now
                </button>
              </div>
              </div>
                 </form>
               </div>
             </div>
            </div>

          </section>

      {/*ENDD*/}

      <Footer2 />
    </section>
  );
}
