import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import EC22 from"assets/img/EC22.jpg";
import EC23 from"assets/img/EC23.jpg";
import EC24 from"assets/img/EC24.jpg";
import EC25 from"assets/img/EC25.jpg";

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} text-red-500 cursor-pointer`}
      style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      {/* Custom arrow content can be added here */}
      <span className="text-2xl">{"<"}</span>
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} text-blue-500 cursor-pointer`}
      style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      {/* Custom arrow content can be added here */}
      <span className="text-2xl">{">"}</span>
    </div>
  );
};

const Slidersmall = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    responsive: [
      {
        breakpoint: 1280, // desktop breakpoint
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024, // tablet breakpoint
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900, // tablet landscape breakpoint
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600, // mobile breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <section className="">
      <Slider {...settings}>
        <div>
          <div className="">
            <div className="bg-white custom-borderrr xl:w-10/12  lg:w-10/12 xl:p-6 lg:p-6 md:w-10/12 md:p-6 w-10/12 p-6">
              <p class="xl:text-base text-grey-600 xl:ml-6 lg:text-base lg:ml-6 md:ml-4 md:text-base text-base ml-4">
                It is a long established fact that a reader will be
                distracted by the readable content of a page.
              </p>
              <div className="flex xl:mt-4 lg:mt-4 md:mt-4 mt-4">
                <p class="xl:text-base1 lg:text-base1 md:text-base1 text-base1">⭐⭐⭐⭐⭐</p>
                <p class="xl:text-4xl xl:ml-12 text-skyblue lg:text-4xl lg:ml-8 md:text-4xl md:ml-8 ml-8 text-4xl">99</p>
              </div>
            </div>

            <div className="flex xl:mt-6 lg:mt-6 md:mt-6 mt-6">
              <div>
                <img
                  class="rounded-full w-16"
                  src={EC22}
                  alt=""
                />
              </div>
              <div className="ml-4">
                <h1 class="xl:text-base1 font-bold text-darkblack lg:text-base1 md:text-base1 text-base1">
                  Gordon D Novak
                </h1>
                <p class="xl:text-sm text-skyblue lg:text-sm md:text-sm text-sm">Customer</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="">
            <div className="bg-white custom-borderrr xl:w-10/12 lg:w-10/12 xl:p-6 lg:p-6 md:w-10/12 md:p-6 w-10/12 p-6">
              <p class="xl:text-base text-grey-600 xl:ml-6 lg:text-base lg:ml-6 md:text-base md:ml-4 text-base ml-4">
                It is a long established fact that a reader will be{" "}
               distracted by the readable content of a page.
              </p>
              <div className="flex xl:mt-4 lg:mt-4 md:mt-4 mt-4">
                <p class="xl:text-base1 lg:text-base1 md:text-base1 text-base1">⭐⭐⭐⭐⭐</p>
                <p class="xl:text-4xl xl:ml-12 text-skyblue  lg:text-4xl lg:ml-8 md:text-4xl md:ml-8 ml-8 text-4xl">99</p>
              </div>
            </div>

            <div className="flex xl:mt-6 lg:mt-6 md:mt-6 mt-4">
              <div>
                <img
                  class="rounded-full w-16"
                  src={EC23}
                  alt=""
                />
              </div>
              <div className="ml-4">
                <h1 class="xl:text-base1 font-bold text-darkblack lg:text-base1 md:text-base1 text-base1">
                  Gordon D Novak
                </h1>
                <p class="xl:text-sm text-skyblue lg:text-sm md:text-sm text-sm">Customer</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="">
            <div className="bg-white custom-borderrr xl:w-10/12 lg:w-10/12 lg:p-6 xl:p-6 md:w-10/12 md:p-6 w-10/12 p-6">
              <p class="xl:text-base text-grey-600 xl:ml-6 lg:ml-6 lg:text-base md:text-base text-base ml-4">
                It is a long established fact that a reader will be{" "}
                distracted by the readable content of a page.
              </p>
              <div className="flex xl:mt-4 lg:mt-4 md:mt-4 mt-4">
                <p class="xl:text-base1 lg:text-base1 md:text-base1 text-base1">⭐⭐⭐⭐⭐</p>
                <p class="xl:text-4xl xl:ml-12 text-skyblue lg:text-4xl lg:ml-8 md:ml-8 md:text-4xl text-4xl ml-8">99</p>
              </div>
            </div>

            <div className="flex xl:mt-6 lg:mt-6 md:mt-6 mt-4">
              <div>
                <img
                  class="rounded-full w-16"
                  src={EC24}
                  alt=""
                />
              </div>
              <div className="ml-4">
                <h1 class="xl:text-base1 font-bold text-darkblack lg:text-base1 md:text-base1 text-base1 ">
                  Gordon D Novak
                </h1>
                <p class="xl:text-sm text-skyblue lg:text-sm md:text-sm text-sm">Customer</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="">
            <div className="bg-white custom-borderrr xl:w-10/12 lg:w-10/12 xl:p-6 lg:p-6 md:w-10/12 md:p-6 w-10/12 p-6">
              <p class="xl:text-base text-grey-600 xl:ml-6 lg:text-base lg:ml-6 md:text-base md:ml-4 text-base ml-4">
                It is a long established fact that a reader will be{" "}
                distracted by the readable content of a page.
              </p>
              <div className="flex xl:mt-4 lg:mt-4 md:mt-4 mt-4">
                <p class="xl:text-base1 lg:text-base1 md:text-base1 text-base1 ">⭐⭐⭐⭐⭐</p>
                <p class="xl:text-4xl xl:ml-12 text-skyblue lg:text-4xl lg:ml-8 md:ml-8 md:text-4xl text-4xl ml-8">99</p>
              </div>
            </div>

            <div className="flex xl:mt-6 lg:mt-6 md:mt-6 mt-4 ">
              <div>
                <img
                  class="rounded-full w-16"
                  src={EC23}
                  alt=""
                />
              </div>
              <div className="ml-4">
                <h1 class="xl:text-base1 font-bold text-darkblack lg:text-base1 md:text-base1 text-base1">
                  Gordon D Novak
                </h1>
                <p class="xl:text-sm text-skyblue lg:text-sm md:text-sm text-sm">Customer</p>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    </section>
  );
};

export default Slidersmall;
