import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import rplogo from "assets/img/rplogo.png";
import rplogo2 from "assets/img/rplogo2.png";
import Footer2 from "components/Footers/Footer2";
import mail from "assets/img/mail.png";
import telephone from "assets/img/telephone.png";
import alarm from "assets/img/alarm.png";
import globee from "assets/img/globee.png";
import logo1 from "assets/img/logo1.png";
import mail1 from "assets/img/mail1.png";
import telephone1 from "assets/img/telephone1.png";
import alarm1 from "assets/img/alarm1.png";
import globe1 from "assets/img/globe1.png";
export default function Indexnavbar2() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);

  return (
    <section>
      <section className="xl:block lg:block md:hidden sm:hidden hidden bg-newshade32 roboto">
        <div className="container mx-auto xl:px-20 lg:px-20">
          <div className="flex">
            <div className="flex pt-4 pb-4 ">
              <div>
                <img className="w-4 xl:pt-0 lg:pt-1" src={mail1} />
              </div>
              <div>
                <p class="xl:text-sm text-white font-semibold ml-1 lg:text-sm ">
                  {" "}
                  info@example.com
                </p>
              </div>
            </div>

            <div className="flex pt-4 pb-4 xl:ml-4 lg:ml-4">
              <div>
                <img className="w-4 xl:pt-0 lg:pt-1 " src={telephone1} />
              </div>
              <div>
                <p class="xl:text-sm text-white font-semibold ml-1 lg:text-sm ">
                  {" "}
                  +2 123 654 7898
                </p>
              </div>
            </div>

            <div className="flex pt-4 pb-4 xl:ml-6 lg:ml-6">
              <div>
                <img className="w-4 xl:pt-0 lg:pt-1" src={alarm1} />
              </div>
              <div>
                <p class="xl:text-sm text-white font-semibold ml-1 lg:text-sm">
                  {" "}
                  Sun - Fri (08AM - 10PM)
                </p>
              </div>
            </div>

            <div className="relative flex pt-4 pb-4 xl:ml-80 show-on-hover lg:ml-24">
              <div>
                <img className="w-4 xl:pt-0 lg:pt-1" src={globe1} alt="Globe" />
              </div>
              <div>
                <p className="xl:text-sm text-white font-semibold ml-1 lg:text-sm">
                  Language
                </p>
                <div className="hidden-on-hover">
                  <ul>
                    <li>English</li>
                    <li>Spanish</li>
                    <li>Russian</li>
                    <li>German</li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="flex pt-4 pb-4 xl:ml-6 lg:ml-4">
              <div>
                <p class="xl:text-sm text-white font-semibold lg:text-sm ">Follow Us:</p>
              </div>

              <div>
                <a href="/">
                  <button className=" text-white shadow-lg text-base1 items-center justify-center align-center rounded-lg xl:ml-4 lg:ml-2">
                    <i className="fab fa-facebook"></i>
                  </button>
                </a>
              </div>

              <div>
                <a href="/">
                  <button className=" text-white shadow-lg text-base1 items-center justify-center align-center rounded-lg xl:ml-4 lg:ml-2">
                    <i className="fab fa-linkedin"></i>
                  </button>
                </a>
              </div>

              <div>
                <a href="/">
                  <button className=" text-white shadow-lg text-base1 items-center justify-center align-center rounded-lg xl:ml-4 lg:ml-2">
                    <i className="fab fa-instagram"></i>
                  </button>
                </a>
              </div>

              <div>
                <a href="/">
                  <button className=" text-white shadow-lg text-base1 items-center justify-center align-center rounded-lg xl:ml-4 lg:ml-2">
                    <i className="fab fa-youtube"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:block md:hidden sm:hidden hidden bg-newshade32 roboto ">
        <div className="bg-white">
          <div className="container mx-auto xl:px-20 lg:px-20">
            <div className="flex xl:pt-6 xl:pb-6 lg:pt-6 lg:pb-6">
              <div>
                <a href="/homepage1">
                  <img
                    class="xl:w-5/12 lg:w-5/12"
                    src={logo1}
                    alt=""
                  />
                </a>
              </div>

              <div className="xl:-ml-40 lg:-ml-40">
                <a href="/homepage1">
                  <p class="xl:text-base text-darkblack blue-on-hover font-semibold xl:pt-2 lg:text-base lg:pt-2">
                    Home
                  </p>
                </a>
              </div>

              <div className="xl:ml-6 lg:ml-6">
                <a href="/About3">
                  <p class="xl:text-base text-darkblack blue-on-hover font-semibold xl:pt-2 lg:text-base lg:pt-2">
                    About
                  </p>
                </a>
              </div>

              <div className="xl:ml-6 lg:ml-6">
                <a href="/Services2">
                  <p class="xl:text-base text-darkblack blue-on-hover font-semibold xl:pt-2 lg:text-base lg:pt-2">
                    Services
                  </p>
                </a>
              </div>

              <div className="xl:ml-6 lg:ml-6">
                <a href="/Employer">
                  <p class="xl:text-base text-darkblack blue-on-hover font-semibold xl:pt-2 lg:text-base lg:pt-2">
                    Gallery
                  </p>
                </a>
              </div>

              <div className="xl:ml-6 lg:ml-6">
                <a href="/contact?">
                  <p class="xl:text-base text-darkblack blue-on-hover font-semibold xl:pt-2 lg:text-base lg:pt-2">
                    Contact
                  </p>
                </a>
              </div>

              <div className="xl:ml-88 lg:ml-28">
                <a>
                  <button className="bg-newshade32 text-white rounded-md xl:text-base xl:px-8 xl:py-2 lg:text-sm lg:px-6 lg:py-2">
                    Get a Quote ➔
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*TAB AND MOBILE VIEW*/}
      <section className=" container mx-auto xl:hidden lg:hidden md:block block sm:block md:px-12 px-4 md:pt-3 md:pb-2 pt-2 pb-2">
        <div className="w-full relative flex justify-between">
          <div className={`px-0 ${navbarOpen ? "hidden" : ""}`}>
            <span>
              <a href="http://localhost:3000/">
                <img
                  src={logo1}
                  className="   md:w-40 md:h-45  w-40 h-36  mt-2  cursor-pointer  lg:pr-0 md:pr-0   text-1xl  h-8 pb-4"
                />
              </a>
            </span>
          </div>
          <button
            className="bg-transparent cursor-pointer text-xl text-black font-bold leading-none border-transparent rounded block  outline-none focus:outline-none "
            type="button  "
            onClick={() => setNavbarOpen(!navbarOpen)}
          >
            {navbarOpen ? (
              <i className=""></i>
            ) : (
              <i className="fas fa-bars"></i>
            )}
          </button>
        </div>
      </section>

      <div className="xl:hidden lg:hidden md:block sm:block block container mx-auto md:px-12 px-4">
        <div
          className={
            "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none py-3 xl:pt-1 xl:py-0" +
            (navbarOpen ? " block bg-white" : " hidden")
          }
          id="example-navbar-warning"
        >
          <div className="px-0">
            <div className="xl:w-full relative flex justify-between lg:w-auto lg:static lg:justify-start">
              <div className="pr-4 ">
                <span>
                  <a href="/">
                    <img
                      src={logo1}
                      alt=""
                      className=" md:w-40 md:h-45 md:mt-0 h-8  cursor-pointer pr-0  md:pr-0  mt-2 w-32"
                    />
                  </a>
                </span>
              </div>
              <button
                className="cursor-pointer text-3xl text-black  font-semibold leading-none block  outline-none focus:outline-none "
                type="button"
                onClick={() => setNavbarOpen(!navbarOpen)}
              >
                x
              </button>
            </div>

            <div className="xl:hidden lg:hidden md:block block">
              <a href="/homepage1">
                <p className=" cursor-pointer  md:pt-8 blue-on-hover pt-8  md:text-base font  uppercase  text-black  bg-white figtree font-semibold text-sm ">
                  Home
                </p>
              </a>
            </div>
            <hr class="border-blue-50 md:mt-4 mt-2"></hr>

            <div className="xl:hidden lg:hidden md:block block ">
              <a href="/About3">
                <p className=" cursor-pointer md:text-base font  uppercase text-black bg-white figtree font-semibold text-sm md:pt-4 pt-4 blue-on-hover ">
                  About
                </p>
              </a>
            </div>
            <hr class="border-blue-50 md:mt-4 mt-2"></hr>

            <div className="xl:hidden lg:hidden md:block block ">
              <a href="/Services2">
                <p className=" cursor-pointer  md:text-base font  uppercase text-black  bg-white figtree font-semibold text-sm md:pt-4 pt-4">
                 Services
                </p>
              </a>
            </div>
            <hr class="border-blue-50 md:mt-4 mt-2"></hr>

            <div className="xl:hidden lg:hidden md:block block">
              <a href="/Employer">
                <p className=" cursor-pointer md:text-base font  uppercase text-black bg-white figtree font-semibold text-sm md:pt-4 pt-4">
                  Gallery
                </p>
              </a>
            </div>

            

            <div className="xl:hidden lg:hidden md:block block md:mt-20 mt-12 ">
              <a href="/contact?">
                <button className="md:px-4 md:py-1  bg-newshade32 text-white rounded-md px-4 py-1  ">
                  Contact US
                </button>
              </a>
            </div>

            <div className="flex xl:hidden lg:hidden md:block block md:mt-16 pb-56 pl-0 mt-8 ">
              <a href="/">
                <button className="bg-newshade32 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full ">
                  <i className="fab fa-facebook"></i>
                </button>
              </a>

              <a href="/">
                <button className="bg-newshade32 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full md:ml-2 ml-2">
                  <i className="fab fa-linkedin"></i>
                </button>
              </a>

              <a href="/">
                <button className="bg-newshade32 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full md:ml-2 ml-2">
                  <i className="fab fa-instagram"></i>
                </button>
              </a>

              <a href="/">
                <button className="bg-newshade32 text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full md:ml-2 ml-2">
                  <i className="fab fa-youtube"></i>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
