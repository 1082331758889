import Footer2 from "components/Footers/Footer2";
import React from "react";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Abanner4 from "assets/img/Abanner4.jpg";
import Ahome1 from "assets/img/Ahome1.jpg";
import Ahome2 from "assets/img/Ahome2.jpg";
import Ahome3 from "assets/img/Ahome3.jpg";
import Ahome4 from "assets/img/Ahome4.jpg";
import Ahome5 from "assets/img/Ahome5.jpg";
import Ahome9 from "assets/img/Ahome9.png";
import EC5 from "assets/img/EC5.png";
import EC6 from "assets/img/EC6.png";
import EC7 from "assets/img/EC7.png";
import EC8 from "assets/img/EC8.png";
import EC11 from "assets/img/EC11.png";
import EC12 from "assets/img/EC12.png";
import EC13 from "assets/img/EC13.png";
import ECmiddlebanner from "assets/img/ECmiddlebanner.png";



export default function About3() {
    return(
        <section>
        <Indexnavbar2 />
        
        <div className="xl:block lg:block md:hidden hidden sm:hidden roboto">
        <div
          style={{
            backgroundImage: `url(${Abanner4})`,
            width: "100%",
            height: "300px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
           <div class="container mx-auto xl:px-20 lg:px-20">
            <h3 class="xl:pt-28 lg:pt-32 text-white font-bold figtree xl:text-4xl xl:ml-2 lg:text-4xl text-center">
            About Us 
            </h3>
            <div className="flex justify-center xl:mt-2 lg:mt-0">
              <p class="xl:text-sm text-white blue-on-hover lg:text-sm">
                <a href="/">Home</a>
              </p>
              <p class="xl:text-base1 text-white ml-2 xl:-mt-2  lg:text-base1">
                <a href="/">»</a>
              </p>
              <p class="xl:text-sm text-skyblue ml-2 lg:text-sm">
                <a href="/">About Us</a>
              </p>
            </div>
             </div>
            </div>
            </div>


            <section className="xl:block lg:block md:hidden sm:hidden hidden xl:mt-32  lg:mt-16 roboto container mx-auto ">
        <div className="container mx-auto">
          <div className="grid grid-cols-2 xl:px-20 lg:px-20">
            <div className="xl:-mt-12 flex">
              <div className="">
                <img
                  class="xl:w-11/12 lg:w-11/12 xl:ml-6 rounded-md"
                  src={Ahome1}
                  alt=""
                />
              </div>
              <div className="xl:ml-4 xl:mt-52 absolute lg:mt-44">
                <div class="about-experience  ">
                  <span>30</span>
                  <h5>
                    Years of<br></br> Experience
                  </h5>
                </div>
              </div>

              <div>
                <div className="absolute xl:mt-72 xl:-ml-44 lg:-ml-44 lg:mt-68">
                  <img
                    class="xl:w-6/12 lg:w-4/12"
                    src={Ahome2}
                    alt=""
                  />
                </div>
              </div>
            </div>

            <div className="xl:ml-16 xl:-mt-4 lg:mt-4 lg:ml-8">
              <p class="xl:text-base text-skyblue font-semibold lg:text-base">ABOUT US</p>
              <div className="flex">
                <p class="xl:text-5xlll text-darkblack font-bold lg:text-4xl">Explore Your</p>
                <p class="xl:text-5xlll text-skyblue font-bold ml-2 lg:text-4xl">Exciting</p>
              </div>
              <div className="flex mt-2">
                <p class="xl:text-5xlll text-skyblue font-bold lg:text-4xl">Fishing</p>
                <p class="xl:text-5xlll text-darkblack font-bold ml-2 lg:text-4xl">
                  World With Us.
                </p>
              </div>
              <p class="xl:text-sm xl:pt-4 lg:text-sm lg:pt-4">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of orem
                psum you need to be sure.All the Lorem Ipsum generators on the
                Internet tend to repeat predefined chunks.
              </p>

              <div className="flex xl:mt-6 lg:mt-6">
                <div>
                  <img
                    class="w-12"
                    src={EC5}
                    alt=""
                  />
                </div>
                <div className="ml-4">
                  <h2 class="xl:text-base1 font-bold text-darkblack lg:text-base1">
                    Fishing Tournament
                  </h2>
                  <p class="xl:text-sm text-grey-600 pt-2 lg:text-sm">
                    Take a look at our round up of the best shows.
                  </p>
                </div>
              </div>

              <div className="flex xl:mt-4 lg:mt-4 ">
                <div>
                  <img
                    class="w-12"
                    src={EC6}
                    alt=""
                  />
                </div>
                <div className="ml-4">
                  <h2 class="xl:text-base1 font-bold text-darkblack lg:text-base1">
                    Free Fishing Day
                  </h2>
                  <p class="xl:text-sm text-grey-600 pt-2 lg:text-sm">
                    It has survived words which not only five centuries.
                  </p>
                </div>
              </div>

              <a>
                <button class="xl:text-base text-white xl:px-8 xl:py-2 rounded-md xl:mt-4 red-on-hover1 bg-newshade32 lg:text-base lg:px-8 lg:py-2 lg:mt-4">
                  Discover More
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:block md:hidden sm:hidden hidden  roboto bg-newshade32 xl:mt-16 lg:mt-20">
        <div className="bg-black-overlay-light">
        <div
          style={{
            backgroundImage: `url(${Ahome9})`,
            width: "100%",
            height: "250px",
            backgroundSize: "cover",
            backgroundPosition: "center",
            
          }}
        >
          <div className="xl:px-20 container mx-auto lg:px-20 ">
            <div className="grid grid-cols-4 xl:pt-16 lg:pt-16">
              <div>
                <div className="bg-white custom-borderrr xl:p-4 lg:p-4">
                  <div className="flex ">
                    <div className="border-7 rounded-full w-16 h-16 ml-4">
                      <div className="center-button">
                        <img
                          class="w-12 pt-1"
                          src={EC11}
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="xl:ml-4 lg:ml-2">
                      <p class="xl:text-5xl font-bold text-darkblack lg:text-4xl">1500</p>
                      <p class="xl:text-sm font-bold text-darkblack xl:pt-2 lg:pt-0 lg:text-sm">
                        + Project Done
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="bg-white custom-borderrr xl:p-4 ml-2 lg:p-4">
                  <div className="flex ">
                    <div className="border-7 rounded-full w-16 h-16 ml-4">
                      <div className="center-button">
                        <img
                          class="w-12 pt-1"
                          src={EC12}
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="xl:ml-4 lg:ml-2">
                      <p class="xl:text-5xl font-bold text-darkblack lg:text-4xl">250</p>
                      <p class="xl:text-sm font-bold text-darkblack xl:pt-2 lg:pt-0 lg:text-sm">
                        + Happy Clients
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="bg-white custom-borderrr xl:p-4 ml-2 lg:p-4">
                  <div className="flex ">
                    <div className="border-7 rounded-full w-16 h-16 ml-4">
                      <div className="center-button">
                        <img
                          class="w-12 pt-1"
                          src={EC13}
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="xl:ml-4 lg:ml-2">
                      <p class="xl:text-5xl font-bold text-darkblack lg:text-4xl">120</p>
                      <p class="xl:text-sm font-bold text-darkblack xl:pt-2 lg:pt-0 lg:text-sm">
                        + Experts Staff
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="bg-white custom-borderrr xl:p-4 ml-2 lg:p-4">
                  <div className="flex ">
                    <div className="border-7 rounded-full w-16 h-16 ml-4">
                      <div className="center-button">
                        <img
                          class="w-12 pt-1"
                          src={EC11}
                          alt=""
                        />
                      </div>
                    </div>

                    <div className="xl:ml-4 lg:ml-2">
                      <p class="xl:text-5xl font-bold text-darkblack lg:text-4xl">50</p>
                      <p class="xl:text-sm font-bold text-darkblack xl:pt-2 lg:pt-0 lg:text-sm">
                        + Win Awards
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>


      <section className="xl:block lg:block md:hidden sm:hidden hidden  roboto bg-newshade34  xl:pb-16 lg:pb-16">
        <div className="container mx-auto xl:px-20 lg:px-20">
          <p class="xl:text-base font-semibold xl:pt-16 text-skyblue text-center lg:text-base lg:pt-16">
            SERVICES
          </p>
          <div className="flex justify-center mt-2">
            <p class="xl:text-5xlll font-bold text-darkblack lg:text-4xl">What We</p>
            <p class="xl:text-5xlll font-bold text-skyblue ml-2 lg:text-4xl">Offers To You</p>
          </div>
          <p class="xl:text-sm text-grey-600 text-center mt-4 lg:text-sm">
            It is a long established fact that a reader will be distracted by
            the readable<br></br> content of a page when looking at its layout.
          </p>
          <div className="grid grid-cols-3 xl:mt-24 lg:mt-20">
            <div>
              <div className="bg-white custom-borderr xl:p-6 lg:p-6">
                <div>
                  <img
                    class="xl:w-12/12 lg:w-12/12 custom-borderr lg:-mt-12 xl:-mt-12"
                    src={Ahome3}
                    alt=""
                  />
                </div>

                <div className="bg-white rounded-full absolute xl:-mt-12 p-4 xl:ml-52 lg:-mt-12 lg:ml-36">
                  <div className="center-button">
                    <img
                      class="w-12"
                      src={EC5}
                      alt=""
                    />
                  </div>
                </div>

                <h2 class="xl:text-base1 xl:pt-6 font-bold text-darkblack blue-on-hover lg:text-base1 lg:pt-6">
                  Solo & Team Fishing
                </h2>
                <p class="text-smm text-grey-600 pt-4 ">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>

                <a href="/">
                  <button class="text-smm bg-newshade32 text-white font-semibold xl:px-6 xl:py-2 xl:mt-5 rounded-lg lg:px-6 lg:py-2 lg:mt-5">
                    Read More ➜{" "}
                  </button>
                </a>
              </div>
            </div>

            <div>
              <div className="bg-white custom-borderr xl:p-6 ml-2 lg:p-6">
                <div>
                  <img
                    class="xl:w-12/12 custom-borderr xl:-mt-12 lg:w-12/12 lg:-mt-12"
                    src={Ahome4}
                    alt=""
                  />
                </div>

                <div className="bg-white rounded-full absolute xl:-mt-12 p-4 xl:ml-52 lg:-mt-12 lg:ml-36">
                  <div className="center-button">
                    <img
                      class="w-12"
                      src={EC6}
                      alt=""
                    />
                  </div>
                </div>

                <h2 class="xl:text-base1 xl:pt-6 font-bold text-darkblack blue-on-hover lg:text-base1 lg:pt-6">
                  Fishing Competitions
                </h2>
                <p class="text-smm text-grey-600 pt-4">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>

                <a href="/">
                  <button class="text-smm bg-newshade32 text-white font-semibold xl:px-6 xl:py-2 xl:mt-6 rounded-lg lg:px-6 lg:py-2 lg:mt-6">
                    Read More ➜{" "}
                  </button>
                </a>
              </div>
            </div>

            <div>
              <div className="bg-white custom-borderr xl:p-6 ml-2 lg:p-6">
                <div>
                  <img
                    class="xl:w-12/12 custom-borderr xl:-mt-12 lg:w-12/12 lg:-mt-12"
                    src={Ahome5}
                    alt=""
                  />
                </div>

                <div className="bg-white rounded-full absolute xl:-mt-12 p-4 xl:ml-52 lg:-mt-12 lg:ml-36">
                  <div className="center-button">
                    <img
                      class="w-12"
                      src={EC7}
                      alt=""
                    />
                  </div>
                </div>

                <h2 class="xl:text-base1 xl:pt-6 font-bold text-darkblack blue-on-hover lg:text-base1 lg:pt-6">
                  Fishing Equipments
                </h2>
                <p class="text-smm text-grey-600 pt-4">
                  There are many variations of passages orem psum available but
                  the majority have suffered alteration in some form by
                  injected.
                </p>

                <a href="/">
                  <button class="text-smm bg-newshade32 text-white font-semibold xl:px-6 xl:py-2 xl:mt-6 rounded-lg lg:px-6 lg:py-2 lg:mt-6">
                    Read More ➜{" "}
                  </button>
                </a>
              </div>
            </div>
          </div>
          </div>
          </section>

          <section className="xl:block lg:block md:hidden sm:hidden hidden xl:mt-20 roboto container mx-auto xl:px-20 xl:pb-16 lg:px-20 lg:pb-16 lg:mt-20">
            <div className="container mx-aut0">
             <div className="grid grid-cols-2">
               <div>
                <div className="">
                  <img class="custom-borderrr xl:w-10/12 lg:w-12/12" src={Ahome2} alt=""/>
                </div>
               </div>

               <div className="bg-newshade34 xl:-ml-24 xl:p-6 lg:-ml-24 lg:p-6">
                 <h1 class="xl:text-base1 font-bold text-darkblack xl:pt-4 lg:text-base1 lg:pt-0">Get Your Quote</h1>
                 <p class="xl:text-sm pt-4 text-grey-600 lg:text-sm">It is a long established fact that a reader will be distracted by the readable content  majority have suffered alteration page when looking at its layout.</p>

                 <form>
                 <div className="xl:mt-8 lg:mt-4 ">
              <div className="flex ">
                <input
                  class="bg-white rounded-md border-5  xl:w-6/12 lg:w-6/12"
                  type="text"
                  id="text"
                  placeholder="Your Name"
                />
                <input
                  class="bg-white rounded-md border-5  xl:w-6/12 ml-2 lg:w-6/12"
                  type="text"
                  id="text"
                  placeholder="Email Address"
                />
              </div>

              <div className="flex mt-4 ">
                <input
                  class="bg-white rounded-md border-5  xl:w-6/12 lg:w-6/12"
                  type="number"
                  id="number"
                  placeholder="Phone Number"
                />
                <select class="bg-white rounded-md border-5  xl:w-6/12 ml-2 lg:w-6/12" id="service" name="service">
                 <option value="Choose Service">Choose Service</option>
                 <option value="Solo & team fishing">Solo & team fishing</option>
                 <option value="fishing tour">fishing tour</option>
                 <option value="fishing competiton">fishing competiton</option>
                 </select>
              </div>

              <textarea
                class="rounded-md mt-4 xl:w-12/12 border-5 lg:w-12/12"
                id="Message"
                name="Write Message"
                rows="5"
                cols="53"
                placeholder="Write Message"
              ></textarea>

              <div>
                <button className="text-white xl:px-6 xl:py-3 xl:mt-4 bg-newshade32 rounded-md lg:px-6 lg:p-2 lg:mt-4">
                  Submit Now
                </button>
              </div>
              </div>
                 </form>
               </div>
             </div>
            </div>

          </section>

         {/*TABVIEW*/}
         <div className="xl:hidden lg:hidden md:block hidden sm:hidden roboto">
        <div
          style={{
            backgroundImage: `url(${Abanner4})`,
            width: "100%",
            height: "300px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
           <div class="container mx-auto px-12">
            <h3 class="pt-32  text-white font-bold figtree text-4xl ml-2 text-center">
            About Us 
            </h3>
            <div className="flex justify-center">
              <p class="text-sm text-white blue-on-hover ">
                <a href="/">Home</a>
              </p>
              <p class="text-base1 text-white ml-2 mt-0 ">
                <a href="/">»</a>
              </p>
              <p class="text-sm text-skyblue ml-2 ">
                <a href="/">About Us</a>
              </p>
            </div>
             </div>
            </div>
            </div>

            <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16  roboto container mx-auto px-12 pb-12">
        <div className="container mx-auto">
          <div className="">
            <div className=" flex">
              <div className="">
                <img class="w-10/12  rounded-md" src={Ahome1} alt="" />
              </div>
              <div className="ml-4 mt-48 absolute ">
                <div class="about-experience  ">
                  <span>30</span>
                  <h5>
                    Years of<br></br> Experience
                  </h5>
                </div>
              </div>

              <div>
                <div className="absolute mt-68 -ml-52 ">
                  <img class="w-10/12 " src={Ahome2} alt="" />
                </div>
              </div>
            </div>

            <div className="mt-6">
              <p class="text-base text-skyblue font-semibold ">ABOUT US</p>
              <div className="flex">
                <p class=" text-darkblack font-bold text-4xl">Explore Your</p>
                <p class=" text-skyblue font-bold ml-2 text-4xl">Exciting</p>
              </div>
              <div className="flex mt-2">
                <p class=" text-skyblue font-bold text-4xl">Fishing</p>
                <p class=" text-darkblack font-bold ml-2 text-4xl">
                  World With Us.
                </p>
              </div>
              <p class="text-sm pt-4 ">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of orem
                psum you need to be sure.All the Lorem Ipsum generators on the
                Internet tend to repeat predefined chunks.
              </p>

              <div className="flex mt-6 ">
                <div>
                  <img class="w-12" src={EC8} alt="" />
                </div>
                <div className="ml-4">
                  <h2 class="text-base1 font-bold text-darkblack ">
                    Fishing Tournament
                  </h2>
                  <p class="text-sm text-grey-600 pt-2 ">
                    Take a look at our round up of the best shows.
                  </p>
                </div>
              </div>

              <div className="flex mt-4">
                <div>
                  <img class="w-12" src={EC5} alt="" />
                </div>
                <div className="ml-4">
                  <h2 class="text-base1 font-bold text-darkblack">
                    Free Fishing Day
                  </h2>
                  <p class="text-sm text-grey-600 pt-2">
                    It has survived words which not only five centuries.
                  </p>
                </div>
              </div>

              <a>
                <button class="text-base text-white px-8 py-2 rounded-md mt-4 red-on-hover1 bg-newshade32 ">
                  Discover More
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden  roboto bg-newshade32 ">
        <div className="bg-black-overlay-light">
          <div
            style={{
              backgroundImage: `url(${ECmiddlebanner})`,
              width: "100%",
              height: "300px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="px-12 container mx-auto  ">
              <div className="grid grid-cols-2 pt-12 ">
                <div>
                  <div className="bg-white custom-borderrr p-4 ">
                    <div className="flex ">
                      <div className="border-7 rounded-full w-16 h-16 ml-4">
                        <div className="center-button">
                          <img class="w-12 pt-1" src={EC11} alt="" />
                        </div>
                      </div>

                      <div className="ml-4 ">
                        <p class=" font-bold text-darkblack text-4xl">1500</p>
                        <p class=" font-bold text-darkblack  pt-2 text-sm">
                          + Project Done
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="bg-white custom-borderrr p-4 ml-2 ">
                    <div className="flex ">
                      <div className="border-7 rounded-full w-16 h-16 ml-4">
                        <div className="center-button">
                          <img class="w-12 pt-1" src={EC12} alt="" />
                        </div>
                      </div>

                      <div className="ml-4 ">
                        <p class=" font-bold text-darkblack text-4xl">250</p>
                        <p class=" font-bold text-darkblack pt-2 text-sm">
                          + Happy Clients
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 mt-4">
                <div>
                  <div className="bg-white custom-borderrr p-4 ml-2 ">
                    <div className="flex ">
                      <div className="border-7 rounded-full w-16 h-16 ml-4">
                        <div className="center-button">
                          <img class="w-12 pt-1" src={EC13} alt="" />
                        </div>
                      </div>

                      <div className="ml-4">
                        <p class=" font-bold text-darkblack text-4xl">120</p>
                        <p class=" font-bold text-darkblack pt-2 text-sm">
                          + Experts Staff
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="bg-white custom-borderrr p-4 ml-2 ">
                    <div className="flex ">
                      <div className="border-7 rounded-full w-16 h-16 ml-4">
                        <div className="center-button">
                          <img class="w-12 pt-1" src={EC11} alt="" />
                        </div>
                      </div>

                      <div className="ml-4 ">
                        <p class=" font-bold text-darkblack text-4xl">50</p>
                        <p class=" font-bold text-darkblack pt-2 text-sm">
                          + Win Awards
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden bg-newshade34 pb-16 roboto px-12">
        <p class="text-base font-semibold pt-12 text-skyblue text-center ">
          SERVICES
        </p>
        <div className="flex justify-center mt-2">
          <p class=" font-bold text-darkblack text-4xl ">What We</p>
          <p class=" font-bold text-skyblue ml-2 text-4xl">Offer To You</p>
        </div>
        <p class="text-sm text-grey-600 text-center mt-4 ">
          It is a long established fact that a reader will be distracted by the
          readable<br></br> content of a page when looking at its layout.
        </p>
        <div className="grid grid-cols-2 mt-16">
          <div>
            <div className="bg-white custom-borderr p-6 ">
              <div>
                <img
                  class="w-12/12  custom-borderr -mt-12 "
                  src={Ahome3}
                  alt=""
                />
              </div>

              <div className="bg-white rounded-full absolute -mt-12 p-4 ml-48 ">
                <div className="center-button">
                  <img class="w-12" src={EC5} alt="" />
                </div>
              </div>

              <h2 class="text-base1 pt-6 font-bold text-darkblack blue-on-hover text-base1 ">
                Solo & Team Fishing
              </h2>
              <p class="text-smm text-grey-600 pt-4">
                There are many variations of passages orem psum available but
                the majority have suffered alteration in some form by injected.
              </p>

              <a href="/">
                <button class="text-smm bg-newshade32 text-white font-semibold px-6 py-2 mt-4 rounded-lg ">
                  Read More ➜{" "}
                </button>
              </a>
            </div>
          </div>

          <div className="">
            <div className="bg-white custom-borderr p-6 ml-2 ">
              <div>
                <img
                  class="w-12/12 -mt-12 custom-borderr "
                  src={Ahome4}
                  alt=""
                />
              </div>

              <div className="bg-white rounded-full absolute -mt-12 p-4 ml-46 ">
                <div className="center-button">
                  <img class="w-12" src={EC6} alt="" />
                </div>
              </div>

              <h2 class="text-base1 pt-6 font-bold text-darkblack blue-on-hover ">
                Fishing Competitions
              </h2>
              <p class="text-smm text-grey-600 pt-4 ">
                There are many variations of passages orem psum available but
                the majority have suffered alteration in some form by injected.
              </p>

              <a href="/">
                <button class="text-smm bg-newshade32 text-white font-semibold px-6 py-2 mt-6 rounded-lg ">
                  Read More ➜{" "}
                </button>
              </a>
            </div>
          </div>
        </div>


         <div className="grid grid-cols-2 mt-12">
        <div>
            <div className="bg-white custom-borderr p-6 ml-2  ">
              <div>
                <img
                  class="w-12/12 custom-borderr -mt-12 "
                  src={Ahome5}
                  alt=""
                />
              </div>

              <div className="bg-white rounded-full absolute -mt-12 p-4 ml-46 ">
                <div className="center-button">
                  <img class="w-12" src={EC7} alt="" />
                </div>
              </div>

              <h2 class="text-base1 pt-6 font-bold text-darkblack blue-on-hover ">
                Fishing Equipments
              </h2>
              <p class="text-smm text-grey-600 pt-4">
                There are many variations of passages orem psum available but
                the majority have suffered alteration in some form by injected.
              </p>

              <a href="/">
                <button class="text-smm bg-newshade32 text-white font-semibold px-6 py-2 mt-6 rounded-lg ">
                  Read More ➜{" "}
                </button>
              </a>
            </div>
          </div>
          </div>

        </section>

        <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 roboto container mx-auto  pb-16 px-12 ">
            <div className="container mx-aut0">
             <div className="">
               <div>
                <div className="">
                  <img class="custom-borderrr w-10/12 " src={Ahome2} alt=""/>
                </div>
               </div>

               <div className="bg-newshade34 mt-12 p-6 pb-8">
                 <h1 class="text-base1 font-bold text-darkblack pt-4 ">Get Your Quote</h1>
                 <p class="text-sm pt-4 text-grey-600 ">It is a long established fact that a reader will be distracted by the readable content  majority have suffered alteration page when looking at its layout.</p>

                 <form>
                 <div className="mt-8  ">
              
                <input
                  class="bg-white rounded-md border-5  w-12/12 "
                  type="text"
                  id="text"
                  placeholder="Your Name"
                />
                <br></br>
                <input
                  class="bg-white rounded-md border-5 mt-4 w-12/12  "
                  type="text"
                  id="text"
                  placeholder="Email Address"
                />
              

               <br></br>
                <input
                  class="bg-white rounded-md border-5 mt-4 w-12/12 "
                  type="number"
                  id="number"
                  placeholder="Phone Number"
                />
                <br></br>

                <select class="bg-white rounded-md border-5 mt-4 w-12/12  " id="service" name="service">
                 <option value="Choose Service">Choose Service</option>
                 <option value="Solo & team fishing">Solo & team fishing</option>
                 <option value="fishing tour">fishing tour</option>
                 <option value="fishing competiton">fishing competiton</option>
                 </select>
              

              <textarea
                class="rounded-md mt-4 w-12/12 border-5 "
                id="Message"
                name="Write Message"
                rows="5"
                cols="53"
                placeholder="Write Message"
              ></textarea>

              <div>
                <button className="text-white px-6 py-3 mt-4 bg-newshade32 rounded-md ">
                  Submit Now
                </button>
              </div>
              </div>
                 </form>
               </div>
             </div>
            </div>

          </section>


         {/*MOBILE VIEW*/}
         <div className="xl:hidden lg:hidden md:hidden block sm:block roboto">
        <div
          style={{
            backgroundImage: `url(${Abanner4})`,
            width: "100%",
            height: "300px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
           <div class="container mx-auto px-4">
            <h3 class="pt-32  text-white font-bold figtree text-4xl ml-2 text-center">
            About Us 
            </h3>
            <div className="flex justify-center">
              <p class="text-sm text-white blue-on-hover ">
                <a href="/">Home</a>
              </p>
              <p class="text-base1 text-white ml-2 mt-0 ">
                <a href="/">»</a>
              </p>
              <p class="text-sm text-skyblue ml-2 ">
                <a href="/">About Us</a>
              </p>
            </div>
             </div>
            </div>
            </div>

            <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16  roboto container mx-auto px-4 pb-8">
        <div className="container mx-auto">
          <div className="">
            <div className=" flex">
              <div className="">
                <img class="w-12/12  rounded-md" src={Ahome1} alt="" />
              </div>
              <div className=" mt-24 absolute ">
                <div class="about-experience  ">
                  <span>30</span>
                  <h5>
                    Years of<br></br> Experience
                  </h5>
                </div>
              </div>

              {/*<div>
                <div className="absolute mt-80 -ml-32 ">
                  <img
                    class="w-8/12 "
                    src={Ahome2}
                    alt=""
                  />
                </div>
              </div>*/}
            </div>

            <div className="mt-6">
              <p class="text-base text-skyblue font-semibold ">ABOUT US</p>
              <div className="flex">
                <p class=" text-darkblack font-bold text-3xl">Explore Your</p>
                <p class=" text-skyblue font-bold ml-2 text-3xl">Exciting</p>
              </div>
              <div className="flex mt-2">
                <p class=" text-skyblue font-bold text-3xl">Fishing</p>
                <p class=" text-darkblack font-bold ml-2 text-3xl">
                  World With Us.
                </p>
              </div>
              <p class="text-sm pt-4 ">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour, or randomised words which don't look even
                slightly believable. If you are going to use a passage of orem
                psum you need to be sure.All the Lorem Ipsum generators on the
                Internet tend to repeat predefined chunks.
              </p>

              <div className="flex mt-6 ">
                <div>
                  <img class="w-12" src={EC8} alt="" />
                </div>
                <div className="ml-4">
                  <h2 class="text-base1 font-bold text-darkblack ">
                    Fishing Tournament
                  </h2>
                  <p class="text-sm text-grey-600 pt-2 ">
                    Take a look at our round up of the best shows.
                  </p>
                </div>
              </div>

              <div className="flex mt-4">
                <div>
                  <img class="w-12" src={EC5} alt="" />
                </div>
                <div className="ml-4">
                  <h2 class="text-base1 font-bold text-darkblack">
                    Free Fishing Day
                  </h2>
                  <p class="text-sm text-grey-600 pt-2">
                    It has survived words which not only five centuries.
                  </p>
                </div>
              </div>

              <a>
                <button class="text-base text-white px-8 py-2 rounded-md mt-4 red-on-hover1 bg-newshade32 ">
                  Discover More
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block  roboto bg-newshade32 ">
        <div className="bg-black-overlay-light">
          <div
            style={{
              backgroundImage: `url(${ECmiddlebanner})`,
              width: "100%",
              height: "600px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="px-12 container mx-auto  ">
              <div className=" pt-16 ">
                <div>
                  <div className="bg-white custom-borderrr p-4 ">
                    <div className="flex ">
                      <div className="border-7 rounded-full w-16 h-16 ml-4">
                        <div className="center-button">
                          <img class="w-12 pt-1" src={EC11} alt="" />
                        </div>
                      </div>

                      <div className="ml-4 ">
                        <p class=" font-bold text-darkblack text-4xl">1500</p>
                        <p class=" font-bold text-darkblack  pt-2 text-sm">
                          + Project Done
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="bg-white custom-borderrr p-4 mt-8">
                    <div className="flex ">
                      <div className="border-7 rounded-full w-16 h-16 ml-4">
                        <div className="center-button">
                          <img class="w-12 pt-1" src={EC12} alt="" />
                        </div>
                      </div>

                      <div className="ml-4 ">
                        <p class=" font-bold text-darkblack text-4xl">250</p>
                        <p class=" font-bold text-darkblack pt-2 text-sm">
                          + Happy Clients
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" mt-8">
                <div>
                  <div className="bg-white custom-borderrr p-4 ml-2 ">
                    <div className="flex ">
                      <div className="border-7 rounded-full w-16 h-16 ml-4">
                        <div className="center-button">
                          <img class="w-12 pt-1" src={EC13} alt="" />
                        </div>
                      </div>

                      <div className="ml-4">
                        <p class=" font-bold text-darkblack text-4xl">120</p>
                        <p class=" font-bold text-darkblack pt-2 text-sm">
                          + Experts Staff
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="bg-white custom-borderrr p-4 mt-8 ">
                    <div className="flex ">
                      <div className="border-7 rounded-full w-16 h-16 ml-4">
                        <div className="center-button">
                          <img class="w-12 pt-1" src={EC11} alt="" />
                        </div>
                      </div>

                      <div className="ml-4 ">
                        <p class=" font-bold text-darkblack text-4xl">50</p>
                        <p class=" font-bold text-darkblack pt-2 text-sm">
                          + Win Awards
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block bg-newshade34 pb-16 roboto px-4">
        <p class="text-base font-semibold pt-12 text-skyblue text-center ">
          SERVICES
        </p>
        <div className="flex justify-center mt-2">
          <p class=" font-bold text-darkblack text-3xl ">What We</p>
          <p class=" font-bold text-skyblue ml-2 text-3xl">Offer To You</p>
        </div>
        <p class="text-sm text-grey-600 text-center mt-4 ">
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout.
        </p>
        <div className=" mt-12">
          <div>
            <div className="bg-white custom-borderr p-6 ">
              <div>
                <img
                  class="w-12/12  custom-borderr -mt-12 "
                  src={Ahome3}
                  alt=""
                />
              </div>

              <div className="bg-white rounded-full absolute -mt-12 p-4 ml-52 ">
                <div className="center-button">
                  <img class="w-12" src={EC5} alt="" />
                </div>
              </div>

              <h2 class="text-base1 pt-6 font-bold text-darkblack blue-on-hover text-base1 ">
                Solo & Team Fishing
              </h2>
              <p class="text-smm text-grey-600 pt-4">
                There are many variations of passages orem psum available but
                the majority have suffered alteration in some form by injected.
              </p>

              <a href="/">
                <button class="text-smm bg-newshade32 text-white font-semibold px-6 py-2 mt-4 rounded-lg ">
                  Read More ➜{" "}
                </button>
              </a>
            </div>
          </div>

          <div className="mt-12">
            <div className="bg-white custom-borderr p-6  ">
              <div>
                <img
                  class="w-12/12 -mt-12 custom-borderr "
                  src={Ahome4}
                  alt=""
                />
              </div>

              <div className="bg-white rounded-full absolute -mt-12 p-4 ml-52 ">
                <div className="center-button">
                  <img class="w-12" src={EC6} alt="" />
                </div>
              </div>

              <h2 class="text-base1 pt-6 font-bold text-darkblack blue-on-hover ">
                Fishing Competitions
              </h2>
              <p class="text-smm text-grey-600 pt-4 ">
                There are many variations of passages orem psum available but
                the majority have suffered alteration in some form by injected.
              </p>

              <a href="/">
                <button class="text-smm bg-newshade32 text-white font-semibold px-6 py-2 mt-6 rounded-lg ">
                  Read More ➜{" "}
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className=" mt-12 ">
          <div>
            <div className="bg-white custom-borderr p-6 ml-2 ">
              <div>
                <img
                  class="w-12/12 custom-borderr -mt-12 "
                  src={Ahome5}
                  alt=""
                />
              </div>

              <div className="bg-white rounded-full absolute -mt-12 p-4 ml-52 ">
                <div className="center-button">
                  <img class="w-12" src={EC7} alt="" />
                </div>
              </div>

              <h2 class="text-base1 pt-6 font-bold text-darkblack blue-on-hover ">
                Fishing Equipments
              </h2>
              <p class="text-smm text-grey-600 pt-4">
                There are many variations of passages orem psum available but
                the majority have suffered alteration in some form by injected.
              </p>

              <a href="/">
                <button class="text-smm bg-newshade32 text-white font-semibold px-6 py-2 mt-6 rounded-lg ">
                  Read More ➜{" "}
                </button>
              </a>
            </div>
          </div>
          </div>
          </section>

          <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 roboto container mx-auto  pb-16 px-12 ">
            <div className="container mx-aut0">
             <div className="">
               <div>
                <div className="">
                  <img class="custom-borderrr w-12/12 " src={Ahome2} alt=""/>
                </div>
               </div>

               <div className="bg-newshade34 mt-12 p-6 pb-8">
                 <h1 class="text-base1 font-bold text-darkblack pt-4 ">Get Your Quote</h1>
                 <p class="text-sm pt-4 text-grey-600 ">It is a long established fact that a reader will be distracted by the readable content  majority have suffered alteration page when looking at its layout.</p>

                 <form>
                 <div className="mt-8  ">
              
                <input
                  class="bg-white rounded-md border-5  w-12/12 "
                  type="text"
                  id="text"
                  placeholder="Your Name"
                />
                <br></br>
                <input
                  class="bg-white rounded-md border-5 mt-4 w-12/12  "
                  type="text"
                  id="text"
                  placeholder="Email Address"
                />
              

               <br></br>
                <input
                  class="bg-white rounded-md border-5 mt-4 w-12/12 "
                  type="number"
                  id="number"
                  placeholder="Phone Number"
                />
                <br></br>

                <select class="bg-white rounded-md border-5 mt-4 w-12/12  " id="service" name="service">
                 <option value="Choose Service">Choose Service</option>
                 <option value="Solo & team fishing">Solo & team fishing</option>
                 <option value="fishing tour">fishing tour</option>
                 <option value="fishing competiton">fishing competiton</option>
                 </select>
              

              <textarea
                class="rounded-md mt-4 w-12/12 border-5 "
                id="Message"
                name="Write Message"
                rows="5"
                cols="53"
                placeholder="Write Message"
              ></textarea>

              <div>
                <button className="text-white px-6 py-3 mt-4 bg-newshade32 rounded-md ">
                  Submit Now
                </button>
              </div>
              </div>
                 </form>
               </div>
             </div>
            </div>

          </section>

          

         {/*ENDD*/}


            


          
        <Footer2 />
    </section>
  );
}