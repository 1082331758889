import React, { useState } from "react";
import { Link } from "react-router-dom";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Footer2 from "components/Footers/Footer2";
import Abanner4 from "assets/img/Abanner4.jpg";
import gallery1 from "assets/img/gallery1.jpg";
import gallery2 from "assets/img/gallery2.jpg";
import gallery3 from "assets/img/gallery3.jpg";
import gallery4 from "assets/img/gallery4.jpg";
import gallery5 from "assets/img/gallery5.jpg";
import gallery6 from "assets/img/gallery6.jpg";
import gallery7 from "assets/img/gallery7.jpg";
import gallery8 from "assets/img/gallery8.jpg";
import GallerySlider from "views/GallerySlider";
import GallerySlider1 from "views/GallerySlider1";
import Cloudslider from "views/Cloudslider";

export default function Employer() {
  return (
    <>
      <Indexnavbar2 fixed />

      <section className="xl:block lg:block md:hidden sm:hidden hidden roboto ">
        <div className="xl:block lg:block md:hidden hidden sm:hidden roboto">
          <div
            style={{
              backgroundImage: `url(${Abanner4})`,
              width: "100%",
              height: "300px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div class="container mx-auto xl:px-20 lg:px-20">
              <h3 class="xl:pt-28 lg:pt-32 text-white font-bold figtree xl:text-5xl xl:ml-2 lg:text-4xl text-center">
                Gallery
              </h3>
              <div className="flex justify-center xl:mt-2 lg:mt-2">
                <p class="xl:text-sm text-white blue-on-hover lg:text-sm">
                  <a href="/">Home</a>
                </p>
                <p class="xl:text-base1 text-white ml-2 xl:-mt-2 lg:text-base1 ">
                  <a href="/">»</a>
                </p>
                <p class="xl:text-sm text-skyblue ml-2 lg:text-sm">
                  <a href="/">Gallery</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:block md:hidden sm:hidden hidden xl:mt-16 roboto xl:px-20 xl:pb-16 lg:mt-16 lg:px-20 lg:pb-16">
        <p class="xl:text-base font-bold text-skyblue text-center lg:text-base ">GALLERY</p>
        <div className="justify-center flex xl:mt-2 lg:mt-2">
          <p class="xl:text-5xlll font-bold text-darkblack lg:text-5xl ">Our Photo</p>
          <p class="xl:text-5xlll font-bold text-skyblue ml-2 lg:text-5xl ">Gallery</p>
        </div>
        <p class="xl:text-sm text-center xl:pt-4 lg:text-sm lg:pt-2">
          It is a long established fact that a reader will be distracted by the
          readable<br></br> content of a page when looking at its layout.
        </p>
      </section>

     <section className="xl:block lg:block md:hidden sm:hidden hidden roboto container mx-auto xl:px-20 xl:pb-16 lg:px-20 lg:pb-16">
        <div className=" container mx-auto">
          <div className="App">
            <GallerySlider />
          </div>
        </div>

        <div className="container mx-auto ">
          <div className="App">
            <GallerySlider1 />
          </div>
        </div>
      </section>

       {/*TAB VIEW*/}
       <div className="xl:hidden lg:hidden md:block hidden sm:hidden roboto">
        <div
          style={{
            backgroundImage: `url(${Abanner4})`,
            width: "100%",
            height: "300px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div class="container mx-auto px-12">
            <h3 class="pt-32  text-white font-bold figtree text-4xl ml-2 text-center">
              Gallery
            </h3>
            <div className="flex justify-center">
              <p class="text-sm text-white blue-on-hover ">
                <a href="/">Home</a>
              </p>
              <p class="text-base1 text-white ml-2 mt-0 ">
                <a href="/">»</a>
              </p>
              <p class="text-sm text-skyblue ml-2 ">
                <a href="/">Gallery</a>
              </p>
            </div>
          </div>
        </div>
      </div>


      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-12 px-12">
        <p class="text-base font-bold text-skyblue text-center  ">GALLERY</p>
        <div className="justify-center flex mt-2 ">
          <p class=" font-bold text-darkblack text-4xl ">Our Photo</p>
          <p class=" font-bold text-skyblue ml-2 text-4xl ">Gallery</p>
        </div>
        <p class="text-sm text-center pt-2 ">
          It is a long established fact that a reader will be distracted by the
          readable<br></br> content of a page when looking at its layout.
        </p>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden roboto container mx-auto  px-12 pb-12 mt-12 -ml-4">
        <div className=" container mx-auto">
          <div className="App">
            <GallerySlider />
          </div>
        </div>

        <div className="container mx-auto ">
          <div className="App">
            <GallerySlider1 />
          </div>
        </div>
      </section>


       {/*MOBILE VIEW*/}

       <div className="xl:hidden lg:hidden md:hidden block sm:block roboto">
        <div
          style={{
            backgroundImage: `url(${Abanner4})`,
            width: "100%",
            height: "300px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div class="container mx-auto px-4">
            <h3 class="pt-32  text-white font-bold figtree text-4xl ml-2 text-center">
              Gallery
            </h3>
            <div className="flex justify-center">
              <p class="text-sm text-white blue-on-hover ">
                <a href="/">Home</a>
              </p>
              <p class="text-base1 text-white ml-2 mt-0 ">
                <a href="/">»</a>
              </p>
              <p class="text-sm text-skyblue ml-2 ">
                <a href="/">Gallery</a>
              </p>
            </div>
          </div>
        </div>
      </div>


      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-12 px-4">
        <p class="text-base font-bold text-skyblue text-center  ">GALLERY</p>
        <div className="justify-center flex mt-2 ">
          <p class=" font-bold text-darkblack text-4xl ">Our Photo</p>
          <p class=" font-bold text-skyblue ml-2 text-4xl ">Gallery</p>
        </div>
        <p class="text-sm text-center pt-2 ">
          It is a long established fact that a reader will be distracted by the
          readable content of a page when looking at its layout.
        </p>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block roboto container mx-auto  px-4 pb-12 mt-12 -ml-4">
        <div className=" container mx-auto">
          <div className="App">
            <GallerySlider />
          </div>
        </div>

        <div className="container mx-auto ">
          <div className="App">
            <GallerySlider1 />
          </div>
        </div>
      </section>


      
      <Footer2 />
    </>
  );
}
