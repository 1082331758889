import React from "react";
import AccordionFooter from "./AccordionFooter";

import facebook from "assets/img/facebook.png";
import instagram from "assets/img/instagram.png";
import instagram1 from "assets/img/instagram1.png";
import linkdin from "assets/img/linkedin.png";
import mail from "assets/img/mail.png";
import location from "assets/img/location.png";
import telephone from "assets/img/telephone.png";
import logo2 from "assets/img/logo2.png";

export default function Footer2() {
  {
    const accordionData = [
      {
        title: "Reach Us",
        content: [
          <ul key="list">
            <li className="text-xs font py-2">
              <a href="/">Address:25/B Milford Road, New York</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Mail:info@example.com</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Phone:+2 123 654 7898</a>
            </li>
          </ul>,
        ],
      },

      {
        title: "Quick Links",
        content: [
          <ul key="list">
            <li className="text-xs font py-2">
              <a href="/">About Us</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Update News</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Contact Us</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Testimonials</a>
            </li>

            <li className="text-xs font py-2">
              <a href="/">Terms Of Service</a>
            </li>

            <li className="text-xs font py-2">
              <a href="/">Privacy policy</a>
            </li>
          </ul>,
        ],
      },

      {
        title: "Our Services",
        content: [
          <ul key="list">
            <li className="text-xs font py-2">
              <a href="/">Solo & Team Fishing</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Fishing Tour</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Fishing Competitions</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Fishing Guidence</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Fishing Equipments</a>
            </li>
            <li className="text-xs font py-2">
              <a href="/">Fishing Boats</a>
            </li>
          </ul>,
        ],
      },
    ];

    return (
      <>
        {/* <section className="xl:block lg:block md:hidden hidden sm:hidden bg-ivory-black">

          <section className="container mx-auto  " >
            <div className="grid grid-cols-2">
                 <div className="xl:w-4/12 lg:w-4/12">
                  <img class=" xl:pt-16 xl:ml-12  lg:pt-12 lg:ml-16" src={rplogo2}/>
                 </div>
                 <div className="flex xl:pt-16 lg:pt-12">
                  <a href="homepage2/">
                  <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-lg xl:ml-92 lg:ml-68">
                  <i className="fab fa-facebook"></i>
                  </button>
                  </a>

                  <a href="homepage2/">
                  <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-lg xl:ml-4 lg:ml-2">
                  <i className="fab fa-linkedin"></i>
                  </button>
                  </a>

                  <a href="homepage2/">
                  <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-lg xl:ml-4 lg:ml-2">
                  <i className="fab fa-instagram"></i>
                  </button>
                  </a>

                  <a href="homepage2/">
                  <button className="bg-black text-white shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-lg xl:ml-4 lg:ml-2">
                  <i className="fab fa-youtube"></i>
                  </button>
                  </a>

                  </div>
            </div>
            </section>

            <section className="container mx-auto">
              <div className="grid grid-cols-2 xl:mt-12 lg:mt-8">

               <div>
                <a href="homepage2/">
                <p class="xl:text-base1 text-white figtree xl:ml-14 lg:text-lg lg:ml-22" >Services</p>
                </a>
                 
                 <div class="ftDropDown xl:ml-14 lg:ml-22"></div>
                <div className="flex xl:mt-4 lg:mt-2">
                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-14 red-on-hover lg:ml-22 lg:text-base " >Cyber Security</p>
                </a>

                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-20 red-on-hover lg:ml-16 lg:text-base" >AI Engineering</p>
                </a>
                </div>

                <div className="flex xl:mt-2">
                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-14 red-on-hover lg:ml-22 lg:text-base" >Cloud Engineering</p>
                </a>

                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-14 red-on-hover lg:ml-10 lg:text-base" >Infrastructure Engineering</p>
                </a>
                </div>
                
                <div className="xl:mt-2">
                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-14 red-on-hover lg:ml-22 lg:text-base" > Data Engineering</p>
                </a>
                </div>
               </div>

               <div className="">
              <a href="homepage2/">
                <p class="xl:text-base1 text-white figtree xl:ml-56 lg:ml-8 lg:text-lg" >About Us</p>
                </a>

                <div class="ftDropDown xl:ml-56 lg:ml-8"></div>

                <div className="flex xl:mt-4 lg:mt-2">
                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-56 red-on-hover lg:text-base lg:ml-8 " >Our Company</p>
                </a>

                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-20 red-on-hover lg:text-base lg:ml-16" >Certification and Recognition</p>
                </a>
                </div>

                <div className="flex xl:mt-2">
                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-56 red-on-hover lg:text-base lg:ml-8" >Why Marslab</p>
                </a>

                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-22 red-on-hover lg:text-base lg:ml-22" >Home</p>
                </a>
                </div>

                <div className="flex xl:mt-2">
                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-56 red-on-hover lg:text-base lg:ml-8" >Our Team</p>
                </a>

                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-27 red-on-hover lg:text-base lg:ml-24" >Contact Us</p>
                </a>
                </div>

                <div className="flex xl:mt-2">
                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-56 red-on-hover lg:text-base lg:ml-8" >Careers</p>
                </a>

                <a href="homepage2/">
                <p class="xl:text-sm text-white figtree xl:ml-30 red-on-hover lg:text-base lg:ml-28" >Enquiry Us</p>
                </a>
                </div>
              </div>
              </div>
    </section>

           <hr class="xl:mb-4 xl:ml-40 xl:mt-12 xl:mr-40 border-blueGray-300  lg:ml-22 lg:mr-20 lg:mt-6 lg:mb-4"></hr>

             <p class="figtree text-center text-white xl:text-base xl:pt-4 xl:pb-8 lg:pt-4 lg:pb-4">©2023 Recrutement Plus Inc, a company. All rights reserved.</p>
    </section>*/}

        {/*TAB AND MOBILE VIEW*/}
        <footer className="bg-newshade33  xl:hidden lg:hidden md:block sm:block block">
          <div className="xl:hidden lg:hidden md:block block sm:block container mx-auto md:px-12 px-8 md:pt-8 md:pb-8 pt-6 pb-6">
            <div className="app ">
              {accordionData.map((item, index) => (
                <AccordionFooter
                  key={index}
                  title={item.title}
                  content={item.content}
                />
              ))}
            </div>


            <div>
            <div className="flex justify-center md:mt-2 mt-2">
                  <p class="text-sm text-white ">
                    © Copyright 2024{" "}
                  </p>
                  <p class="text-sm text-skyblue ml-1  ">
                    {" "}
                    Fishred{" "}
                  </p>
                  <p class="text-sm text-white ml-1 ">
                    {" "}
                    All Rights Reserved.{" "}
                  </p>
                </div>
            </div>
          </div>
        </footer>

        <section className="xl:block lg:block md:hidden sm:hidden hidden bg-newshade33 roboto">
          <div className="container mx-auto xl:px-20 lg:px-20 ">
            <div className="grid grid-cols-4 xl:pt-16 xl:pb-16 lg:pt-14 lg:pb-14">
              <div>
                <div>
                  <img class="xl:w-8/12 lg:w-8/12" src={logo2} alt="" />
                </div>
                <p class="xl:text-sm text-white xl:pt-4 lg:text-sm lg:pt-4">
                  We are many variations of passages available but the majority
                  have suffered alteration in some form by injected humour words
                  believable.
                </p>

                <div className="flex xl:mt-4 lg:mt-4">
                  <div className="bg-newshade32 w-8 h-8 rounded-full">
                    <div className="center-button">
                      <img class="w-4 xl:pt-2 lg:pt-2" src={telephone} />
                    </div>
                  </div>
                  <div>
                    <p class="xl:ml-2 text-white xl:text-sm xl:pt-2 lg:ml-2 lg:text-sm lg:pt-2">
                      +2 123 654 7898
                    </p>
                  </div>
                </div>

                <div className="flex xl:mt-2 lg:mt-2">
                  <div className="bg-newshade32 w-8 h-8 rounded-full">
                    <div className="center-button">
                      <img class="w-4 xl:pt-2 lg:pt-2" src={location} />
                    </div>
                  </div>
                  <div>
                    <p class="xl:ml-2 text-white xl:text-sm xl:pt-2 lg:ml-2 lg:text-sm lg:pt-2">
                      25/B Milford Road, New York
                    </p>
                  </div>
                </div>

                <div className="flex xl:mt-2 lg:mt-2">
                  <div className="bg-newshade32 w-8 h-8 rounded-full">
                    <div className="center-button">
                      <img class="w-4 xl:pt-2 lg:pt-2" src={mail} />
                    </div>
                  </div>
                  <div>
                    <p class="xl:ml-2 text-white xl:text-sm xl:pt-2 lg:ml-2 lg:text-sm lg:pt-2">
                      info@example.com
                    </p>
                  </div>
                </div>
              </div>

              <div className="xl:ml-20 lg:ml-8 ">
                <h2 class="xl:text-base1 font-bold text-white lg:text-base1 ">
                  Quick Links
                </h2>
                <div className="flex xl:mt-8 lg:mt-6">
                  <p class="text-skyblue text-xs font-bold xl:pt-1 lg:pt-1">
                    ▶
                  </p>
                  <a href="/">
                    <p class="xl:text-sm text-white  xl:ml-2 blue-on-hover lg:text-sm lg:ml-2">
                      About Us
                    </p>
                  </a>
                </div>

                <div className="flex xl:mt-4 lg:mt-4">
                  <p class="text-skyblue text-xs font-bold xl:pt-1 lg:pt-1">
                    ▶
                  </p>
                  <a href="/">
                    <p class="xl:text-sm text-white  xl:ml-2 blue-on-hover lg:text-sm lg:ml-2">
                      Update News
                    </p>
                  </a>
                </div>

                <div className="flex xl:mt-4 lg:mt-4">
                  <p class="text-skyblue text-xs font-bold xl:pt-1 lg:pt-1">
                    ▶
                  </p>
                  <a href="/">
                    <p class="xl:text-sm text-white  xl:ml-2 blue-on-hover lg:text-sm lg:ml-2">
                      Contact Us
                    </p>
                  </a>
                </div>

                <div className="flex xl:mt-4 lg:mt-4">
                  <p class="text-skyblue text-xs font-bold xl:pt-1 lg:pt-1">
                    ▶
                  </p>
                  <a href="/">
                    <p class="xl:text-sm text-white  xl:ml-2 blue-on-hover lg:text-sm lg:ml-2">
                      Testimonials
                    </p>
                  </a>
                </div>

                <div className="flex xl:mt-4 lg:mt-4">
                  <p class="text-skyblue text-xs font-bold xl:pt-1 lg:pt-1">
                    ▶
                  </p>
                  <a href="/">
                    <p class="xl:text-sm text-white  xl:ml-2 blue-on-hover lg:text-sm lg:ml-2">
                      Terms Of Service
                    </p>
                  </a>
                </div>

                <div className="flex xl:mt-4 lg:mt-4">
                  <p class="text-skyblue text-xs font-bold xl:pt-1 lg:pt-1">
                    ▶
                  </p>
                  <a href="/">
                    <p class="xl:text-sm text-white  xl:ml-2 blue-on-hover lg:text-sm lg:ml-2">
                      Privacy policy
                    </p>
                  </a>
                </div>
              </div>

              <div className="xl:ml-12 lg:ml-6">
                <h2 class="xl:text-base1 font-bold text-white">Our Services</h2>
                <div className="flex xl:mt-8 lg:mt-6">
                  <p class="text-skyblue text-xs font-bold xl:pt-1 lg:pt-1">
                    ▶
                  </p>
                  <a href="/">
                    <p class="xl:text-sm text-white  xl:ml-2 blue-on-hover lg:text-sm lg:ml-2">
                      Solo & Team Fishing
                    </p>
                  </a>
                </div>

                <div className="flex xl:mt-4 lg:mt-4">
                  <p class="text-skyblue text-xs font-bold xl:pt-1 lg:pt-1 ">
                    ▶
                  </p>
                  <a href="/">
                    <p class="xl:text-sm text-white  xl:ml-2 blue-on-hover lg:text-sm lg:ml-2">
                      {" "}
                      Fishing Tour
                    </p>
                  </a>
                </div>

                <div className="flex xl:mt-4 lg:mt-4">
                  <p class="text-skyblue text-xs font-bold xl:pt-1 lg:pt-1">
                    ▶
                  </p>
                  <a href="/">
                    <p class="xl:text-sm text-white  xl:ml-2 blue-on-hover lg:text-sm lg:ml-2">
                      Fishing Competitions
                    </p>
                  </a>
                </div>

                <div className="flex xl:mt-4 lg:mt-4">
                  <p class="text-skyblue text-xs font-bold xl:pt-1 lg:pt-1">
                    ▶
                  </p>
                  <a href="/">
                    <p class="xl:text-sm text-white  xl:ml-2 blue-on-hover lg:text-sm lg:ml-2">
                      Fishing Guidence
                    </p>
                  </a>
                </div>

                <div className="flex xl:mt-4 lg:mt-4">
                  <p class="text-skyblue text-xs font-bold xl:pt-1 lg:pt-1">
                    ▶
                  </p>
                  <a href="/">
                    <p class="xl:text-sm text-white  xl:ml-2 blue-on-hover lg:text-sm lg:ml-2">
                      {" "}
                      Fishing Equipments
                    </p>
                  </a>
                </div>

                <div className="flex xl:mt-4 lg:mt-4">
                  <p class="text-skyblue text-xs font-bold xl:pt-1 lg:pt-1">
                    ▶
                  </p>
                  <a href="/">
                    <p class="xl:text-sm text-white  xl:ml-2 blue-on-hover lg:text-sm lg:ml-2">
                      Fishing Boats
                    </p>
                  </a>
                </div>
              </div>

              <div className="xl:ml-8 lg:ml-0">
                <h2 class="xl:text-base1 font-bold text-white lg:text-base1">
                  Newsletter
                </h2>
                <p class="xl:text-sm text-white xl:pt-8 lg:text-sm lg:pt-8 lg:text-sm">
                  Subscribe Our Newsletter To Get<br></br> Latest Update And
                  News
                </p>
                <div>
                  <button class="xl:text-base bg-white rounded-full xl:px-20 xl:py-2 xl:mt-12 text-skyblue lg:text-base lg:px-16 lg:py-2 lg:mt-6">
                    Your Email
                  </button>
                </div>

                <div>
                  <button class="xl:text-base bg-newshade32 rounded-full xl:px-16 xl:py-2 xl:mt-4 text-white lg:text-base lg:px-12 lg:py-2 lg:mt-4">
                    Subscribe Now
                  </button>
                </div>
              </div>
            </div>

            <hr class="border-blue-50"></hr>
            <div className="grid grid-cols-2 xl:mt-6 xl:pb-6 lg:mt-4 lg:pb-6">
              <div>
                <div className="flex">
                  <p class="xl:text-sm text-white lg:text-sm">
                    © Copyright 2024{" "}
                  </p>
                  <p class="xl:text-sm text-skyblue ml-1 lg:text-sm ">
                    {" "}
                    Fishred{" "}
                  </p>
                  <p class="xl:text-sm text-white ml-1 lg:text-sm">
                    {" "}
                    All Rights Reserved.{" "}
                  </p>
                </div>
              </div>

              <div className="flex">
                <div>
                  <a href="/">
                    <button className=" text-white shadow-lg text-2xl items-center justify-center align-center rounded-lg xl:ml-96 lg:ml-72">
                      <i className="fab fa-facebook"></i>
                    </button>
                  </a>
                </div>

                <div>
                  <a href="/">
                    <button className=" text-white shadow-lg text-2xl items-center justify-center align-center rounded-lg xl:ml-4 lg:ml-4">
                      <i className="fab fa-linkedin"></i>
                    </button>
                  </a>
                </div>

                <div>
                  <a href="/">
                    <button className=" text-white shadow-lg text-2xl items-center justify-center align-center rounded-lg xl:ml-4 lg:ml-4">
                      <i className="fab fa-instagram"></i>
                    </button>
                  </a>
                </div>

                <div>
                  <a href="/">
                    <button className=" text-white shadow-lg text-2xl items-center justify-center align-center rounded-lg xl:ml-4 lg:ml-4">
                      <i className="fab fa-youtube"></i>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
