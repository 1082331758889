import React, { useState } from "react";
import { Link } from "react-router-dom";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Footer2 from "components/Footers/Footer2";
import blue from "assets/img/blue.jpeg";

export default function PrivacyPolicy() {
    return (
      <>
        <Indexnavbar2 fixed />

        <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:-mt-52 lg:-mt-56">
        <div>
          <div
            style={{
              backgroundImage: `url(${blue})`,
              width: "100%",
              height: "220px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            </div>
          </div>
          </section>

          <section className="xl:block lg:hidden md:hidden sm:hidden hidden container mx-auto xl:mt-24 figtree lg:mt-24">
            <div className="xl:pb-24 ">
                <p class="text-darkblack font-bold text-center xl:text-5xl ">Privacy Policy</p>

                <div className="xl:ml-16">
                    <div>
                <p class="text-black xl:text-xl xl:pt-12 font-bold">Introduction</p>
                <p class="xl:text-base1 xl:pt-2">At recrutementplus, we are committed to protecting the privacy and confidentiality of all individuals who engage with our recruitment process. This Recruitment Privacy Policy outlines how we collect, use, and protect your personal information<br></br> during the recruitment process.</p>
                </div>

                <div>
                <p class="text-black xl:text-xl xl:pt-8 font-bold">Scope of this Policy</p>
                <p class="xl:text-base1 xl:pt-2">The scope of a recruitment privacy policy, often referred to as a Recruitment Plus policy, encompasses various aspects<br></br> related to the collection, use, and protection of personal information during the recruitment process. Here's an expanded<br></br> view of the scope:</p>
                </div>

                <div>
                    <p class="text-black xl:text-xl xl:pt-8 font-bold">1.Information Collection:</p>
                    <p class="xl:text-base1 xl:pt-2">Detail what types of personal information are collected from job applicants. This can include resumes, contact details,<br></br> employment history, education, skills, qualifications, references, and any other relevant information provided by applicants</p>
                </div>

                <div>
                    <p class="text-black xl:text-xl xl:pt-8 font-bold">2.Purpose of Collection:</p>
                    <p class="xl:text-base1 xl:pt-2">Clearly state the purpose for which the collected information will be used. This typically includes assessing qualifications<br></br> and suitability for employment, communicating with applicants, conducting interviews and assessments, making <br></br>hiring decisions, and fulfilling legal and regulatory requirements.</p>
                </div>

                <div>
                    <p class="text-black xl:text-xl xl:pt-8 font-bold">3.Data Usage:</p>
                    <p class="xl:text-base1 xl:pt-2">Explain how the collected data will be used throughout the recruitment process. This involves evaluating applications,<br></br> scheduling interviews, conducting assessments, performing reference checks, and making employment decisions.</p>
                </div>

                <div>
                    <p class="text-black xl:text-xl xl:pt-8 font-bold">4.Policy Updates:</p>
                    <p class="xl:text-base1 xl:pt-2"> State that the recruitment privacy policy may be updated from time to time to reflect changes in practices or legal<br></br> requirements. Communicate that any updates will be posted on the company's website and encourage applicants <br></br>to review the policy periodically for changes.</p>
                </div>

                <div >
                    <p class="text-black xl:text-xl xl:pt-8 font-bold">5.Compliance:</p>
                    <p class="xl:text-base1 xl:pt-2">Ensure that the policy complies with relevant data protection laws and regulations, such as GDPR, CCPA,<br></br> or other applicable legislation in your jurisdiction.</p>
                </div>

                </div>
                </div>
          </section>


          {/*LANDSCAPE VIEW*/}

          <section className="xl:hidden lg:block md:hidden sm:hidden hidden  lg:-mt-56">
        <div>
          <div
            style={{
              backgroundImage: `url(${blue})`,
              width: "100%",
              height: "240px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            </div>
          </div>
          </section>

          <section className="xl:hidden lg:block md:hidden sm:hidden hidden container mx-auto  figtree lg:mt-16">
            <div className="lg:pb-24 ">
                <p class="text-darkblack font-bold text-center lg:text-4xl ">Privacy Policy</p>

                <div className="lg:ml-16">
                    <div>
                <p class="text-black lg:text-xl lg:pt-12 font-bold">Introduction</p>
                <p class="lg:text-base1 lg:pt-2">At recrutementplus, we are committed to protecting the privacy and confidentiality of all individuals <br></br>who engage with our recruitment process. This Recruitment Privacy Policy outlines how we collect, use,<br></br> and protect your personal information during the recruitment process.</p>
                </div>

                <div>
                <p class="text-black lg:text-xl lg:pt-8 font-bold">Scope of this Policy</p>
                <p class="lg:text-base1 lg:pt-2">The scope of a recruitment privacy policy, often referred to as a Recruitment Plus policy, encompasses various aspects<br></br> related to the collection, use, and protection of personal information during the recruitment process.<br></br> Here's an expanded view of the scope:</p>
                </div>

                <div>
                    <p class="text-black lg:text-xl lg:pt-8 font-bold">1.Information Collection:</p>
                    <p class="xl:text-base1 xl:pt-2">Detail what types of personal information are collected from job applicants. This can include resumes, contact details,<br></br>employment history, education, skills, qualifications, references, and any other relevant information provided by applicants</p>
                </div>

                <div>
                    <p class="text-black lg:text-xl lg:pt-8 font-bold">2.Purpose of Collection:</p>
                    <p class="xl:text-base1 xl:pt-2">Clearly state the purpose for which the collected information will be used. This typically includes assessing<br></br> qualifications and suitability for employment, communicating with applicants, conducting interviews and assessments,<br></br>making hiring decisions, and fulfilling legal and regulatory requirements.</p>
                </div>

                <div>
                    <p class="text-black lg:text-xl lg:pt-8 font-bold">3.Data Usage:</p>
                    <p class="lg:text-base1 lg:pt-2">Explain how the collected data will be used throughout the recruitment process. This involves evaluating applications,<br></br> scheduling interviews, conducting assessments, performing reference checks, and making employment decisions.</p>
                </div>

                <div>
                    <p class="text-black lg:text-xl lg:pt-8 font-bold">4.Policy Updates:</p>
                    <p class="lg:text-base1 lg:pt-2"> State that the recruitment privacy policy may be updated from time to time to reflect changes in practices<br></br> or legal requirements. Communicate that any updates will be posted on the company's website and<br></br> encourage applicants to review the policy periodically for changes.</p>
                </div>

                <div >
                    <p class="text-black lg:text-xl lg:pt-8 font-bold">5.Compliance:</p>
                    <p class="xl:text-base1 xl:pt-2">Ensure that the policy complies with relevant data protection laws and regulations, such as GDPR, CCPA,<br></br> or other applicable legislation in your jurisdiction.</p>
                </div>

                </div>
                </div>
          </section>


          
          {/*TAB VIEW*/}

         {/* <section className="xl:hidden lg:hidden md:block sm:hidden hidden  md:-mt-56">
        <div>
          <div
            style={{
              backgroundImage: `url(${blue})`,
              width: "100%",
              height: "240px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            </div>
          </div>
          </section>*/}

          <section className="xl:hidden lg:hidden md:block sm:hidden hidden container mx-auto  figtree mt-16">
            <div className="pb-24 ">
                <p class="text-darkblack font-bold text-center text-4xl ">Privacy Policy</p>

                <div className="ml-10">
                    <div>
                <p class="text-black text-xl pt-12 font-bold">Introduction</p>
                <p class="text-base pt-2">At recrutementplus, we are committed to protecting the privacy and confidentiality <br></br>of all individuals who engage with our recruitment process. This Recruitment Privacy Policy<br></br> outlines how we collect, use, and protect your personal information during<br></br> the recruitment process.</p>
                </div>

                <div>
                <p class="text-black text-xl pt-8 font-bold">Scope of this Policy</p>
                <p class="text-base pt-2">The scope of a recruitment privacy policy, often referred to as a Recruitment Plus policy, encompasses various aspects related to the collection, use, and protection of personal<br></br> information during the recruitment process. Here's an expanded view of the scope:</p>
                </div>

                <div>
                    <p class="text-black text-xl pt-8 font-bold">1.Information Collection:</p>
                    <p class="text-base pt-2">Detail what types of personal information are collected from job applicants. This can include<br></br> resumes, contact details,employment history, education, skills, qualifications, references,<br></br> and any other relevant information provided by applicants</p>
                </div>

                <div>
                    <p class="text-black text-xl pt-8 font-bold">2.Purpose of Collection:</p>
                    <p class="text-base pt-2">Clearly state the purpose for which the collected information will be used. This typically<br></br> includes assessing qualifications and suitability for employment, communicating with<br></br> applicants, conducting interviews and assessments,making hiring decisions, and fulfilling <br></br>legal and regulatory requirements.</p>
                </div>

                <div>
                    <p class="text-black text-xl pt-8 font-bold">3.Data Usage:</p>
                    <p class="text-base pt-2">Explain how the collected data will be used throughout the recruitment process. This involves evaluating applications, scheduling interviews, conducting assessments, performing reference checks, and making employment decisions.</p>
                </div>

                <div>
                    <p class="text-black text-xl pt-8 font-bold">4.Policy Updates:</p>
                    <p class="text-base pt-2"> State that the recruitment privacy policy may be updated from time to time to reflect<br></br> changes in practices or legal requirements. Communicate that any updates will be<br></br> posted on the company's website and encourage applicants to review the policy periodically for changes.</p>
                </div>

                <div >
                    <p class="text-black text-xl pt-8 font-bold">5.Compliance:</p>
                    <p class="text-base pt-2">Ensure that the policy complies with relevant data protection laws and regulations, such as<br></br> GDPR, CCPA, or other applicable legislation in your jurisdiction.</p>
                </div>

                </div>
                </div>
          </section>


          {/*MOBILE VIEW*/}

          <section className="xl:hidden lg:hidden md:hidden sm:block block container mx-auto  figtree mt-16">
            <div className="pb-12 ">
                <p class="text-darkblack font-bold text-center text-4xl ">Privacy Policy</p>

                <div className="ml-10 mr-8">
                    <div>
                <p class="text-black text-xl pt-12 font-bold">Introduction</p>
                <p class="text-base pt-2">At recrutementplus, we are committed to protecting the privacy and confidentiality of all individuals who engage with our recruitment process. This Recruitment Privacy Policy outlines how we collect, use, and protect your personal information during the recruitment process.</p>
                </div>

                <div>
                <p class="text-black text-xl pt-8 font-bold">Scope of this Policy</p>
                <p class="text-base pt-2">The scope of a recruitment privacy policy, often referred to as a Recruitment Plus policy, encompasses various aspects related to the collection, use, and protection of personal information during the recruitment process. Here's an expanded view of the scope:</p>
                </div>

                <div>
                    <p class="text-black text-xl pt-8 font-bold">1.Information Collection:</p>
                    <p class="text-base pt-2">Detail what types of personal information are collected from job applicants. This can include resumes, contact details,employment history, education, skills, qualifications, references, and any other relevant information provided by applicants</p>
                </div>

                <div>
                    <p class="text-black text-xl pt-8 font-bold">2.Purpose of Collection:</p>
                    <p class="text-base pt-2">Clearly state the purpose for which the collected information will be used. This typically includes assessing qualifications and suitability for employment, communicating with applicants, conducting interviews and assessments,making hiring decisions, and fulfilling legal and regulatory requirements.</p>
                </div>

                <div>
                    <p class="text-black text-xl pt-8 font-bold">3.Data Usage:</p>
                    <p class="text-base pt-2">Explain how the collected data will be used throughout the recruitment process. This involves evaluating applications, scheduling interviews, conducting assessments, performing reference checks, and making employment decisions.</p>
                </div>

                <div>
                    <p class="text-black text-xl pt-8 font-bold">4.Policy Updates:</p>
                    <p class="text-base pt-2"> State that the recruitment privacy policy may be updated from time to time to reflect changes in practices or legal requirements. Communicate that any updates will be posted on the company's website and encourage applicants to review the policy periodically for changes.</p>
                </div>

                <div >
                    <p class="text-black text-xl pt-8 font-bold">5.Compliance:</p>
                    <p class="text-base pt-2">Ensure that the policy complies with relevant data protection laws and regulations, such as GDPR, CCPA, or other applicable legislation in your jurisdiction.</p>
                </div>

                </div>
                </div>
          </section>
        <Footer2/>
        </>
  );
  }