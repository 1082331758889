import React, { useState } from "react";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Footer2 from "components/Footers/Footer2";
import gallery1 from "assets/img/gallery1.jpg";
import gallery2 from "assets/img/gallery2.jpg";
import gallery3 from "assets/img/gallery3.jpg";
import gallery4 from "assets/img/gallery4.jpg";
import gallery5 from "assets/img/gallery5.jpg";
import gallery6 from "assets/img/gallery6.jpg";
import gallery7 from "assets/img/gallery7.jpg";
import gallery8 from "assets/img/gallery8.jpg";

const CancellationPolicy = () => {
   

  return (
    <>
      <Indexnavbar2 fixed />

      <Footer2 />
    </>
  );
};

export default CancellationPolicy;
