import React, { useState } from "react";
import { Link } from "react-router-dom";
import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import Footer2 from "components/Footers/Footer2";
import blue from "assets/img/blue.jpeg";

export default function PrivacyPolicy() {
    return (
      <>
        <Indexnavbar2 fixed />
        <section className="xl:block lg:hidden md:hidden sm:hidden hidden xl:-mt-52 lg:-mt-84">
        <div>
          <div
            style={{
              backgroundImage: `url(${blue})`,
              width: "100%",
              height: "220px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            </div>
          </div>
          </section>

          <section className="xl:block lg:hidden md:hidden sm:hidden hidden container mx-auto figtree xl:pb-24">
            <div className="">
               <p class="text-darkblack text-center font-bold xl:text-5xl xl:mt-24">Terms Of Use</p>
            </div>

            <div className="xl:ml-16 xl:mt-16">
              <p class="xl:text-xl font-bold text-black">Acceptance of Terms:</p>
              <p class="xl:text-base1 xl:pt-2">By accessing or using RecruitmentPlus, users agree to abide by the terms and conditions outlined by the platform.</p>
            </div>

            <div className="xl:ml-16 xl:mt-8">
              <p class="xl:text-xl font-bold text-black">User Accounts:</p>
              <p class="xl:text-base1 xl:pt-2">Users may need to create an account to access certain features. They are responsible for maintaining the confidentiality<br></br> of their account credentials.</p>
            </div>

            <div className="xl:ml-16 xl:mt-8">
              <p class="xl:text-xl font-bold text-black">Services Provided:</p>
              <p class="xl:text-base1 xl:pt-2"> RecruitmentPlus provides a platform for connecting job seekers with employers. The platform may offer features<br></br> such as job listings, resume posting, and communication tools.</p>
            </div>

            <div className="xl:ml-16 xl:mt-8">
              <p class="xl:text-xl font-bold text-black">User Responsibilities: </p>
              <p class="xl:text-base1 xl:pt-2">Users are responsible for the accuracy of the information they provide on the platform. They must also comply with<br></br> all applicable laws and regulations.</p>
            </div>

            <div className="xl:ml-16 xl:mt-8">
              <p class="xl:text-xl font-bold text-black">Intellectual Property:</p>
              <p class="xl:text-base1 xl:pt-2"> Users retain ownership of the content they post on the platform (e.g., resumes, job listings). However, by posting content,<br></br> users grant RecruitmentPlus a license to use and display that content.</p>
            </div>

            <div className="xl:ml-16 xl:mt-8">
              <p class="xl:text-xl font-bold text-black">Privacy Policy:</p>
              <p class="xl:text-base1 xl:pt-2"> RecruitmentPlus collects and uses personal information in accordance with its privacy policy, which should be<br></br> reviewed separately.</p>
            </div>

            <div className="xl:ml-16 xl:mt-8">
              <p class="xl:text-xl font-bold text-black">Prohibited Activities: </p>
              <p class="xl:text-base1 xl:pt-2"> Users may not engage in activities such as spamming, harassment, or posting illegal content on the platform.</p>
            </div>

            <div className="xl:ml-16 xl:mt-8">
              <p class="xl:text-xl font-bold text-black">Termination: </p>
              <p class="xl:text-base1 xl:pt-2">  RecruitmentPlus reserves the right to suspend or terminate user accounts for violations of the terms of use.</p>
            </div>

            <div className="xl:ml-16 xl:mt-8">
              <p class="xl:text-xl font-bold text-black">Disclaimer of Warranties: </p>
              <p class="xl:text-base1 xl:pt-2">  The platform is provided "as is" without any warranties. RecruitmentPlus does not guarantee the accuracy or availability<br></br> of job listings or other content on the platform.</p>
            </div>

            <div className="xl:ml-16 xl:mt-8">
              <p class="xl:text-xl font-bold text-black">Limitation of Liability: </p>
              <p class="xl:text-base1 xl:pt-2">  RecruitmentPlus limits its liability for damages arising from the use of the platform.</p>
            </div>
        </section>



        {/*LANSCAPE VIEW*/}

        <section className="xl:hidden lg:block md:hidden sm:hidden hidden  lg:-mt-56">
        <div>
          <div
            style={{
              backgroundImage: `url(${blue})`,
              width: "100%",
              height: "240px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            </div>
          </div>
          </section>

          <section className="xl:hidden lg:block md:hidden sm:hidden hidden container mx-auto figtree lg:pb-24">
            <div className="">
               <p class="text-darkblack text-center font-bold lg:text-4xl lg:mt-16">Terms Of Use</p>
            </div>

            <div className="lg:ml-16 lg:mt-16">
              <p class="lg:text-xl font-bold text-black">Acceptance of Terms:</p>
              <p class="lg:text-base1 lg:pt-2">By accessing or using RecruitmentPlus, users agree to abide by the terms and conditions outlined by the platform.</p>
            </div>

            <div className="lg:ml-16 lg:mt-8">
              <p class="lg:text-xl font-bold text-black">User Accounts:</p>
              <p class="lg:text-base1 lg:pt-2">Users may need to create an account to access certain features. They are responsible for maintaining the confidentiality<br></br> of their account credentials.</p>
            </div>

            <div className="lg:ml-16 lg:mt-8">
              <p class="lg:text-xl font-bold text-black">Services Provided:</p>
              <p class="lg:text-base1 lg:pt-2"> RecruitmentPlus provides a platform for connecting job seekers with employers. The platform may offer<br></br> features such as job listings, resume posting, and communication tools.</p>
            </div>

            <div className="lg:ml-16 lg:mt-8">
              <p class="lg:text-xl font-bold text-black">User Responsibilities: </p>
              <p class="lg:text-base1 lg:pt-2">Users are responsible for the accuracy of the information they provide on the platform. They must also comply<br></br> with all applicable laws and regulations.</p>
            </div>

            <div className="lg:ml-16 lg:mt-8">
              <p class="lg:text-xl font-bold text-black">Intellectual Property:</p>
              <p class="lg:text-base1 lg:pt-2"> Users retain ownership of the content they post on the platform (e.g., resumes, job listings). However, by posting content, <br></br>users grant RecruitmentPlus a license to use and display that content.</p>
            </div>

            <div className="lg:ml-16 lg:mt-8">
              <p class="lg:text-xl font-bold text-black">Privacy Policy:</p>
              <p class="lg:text-base1 lg:pt-2"> RecruitmentPlus collects and uses personal information in accordance with its privacy policy, which should be reviewed<br></br> separately.</p>
            </div>

            <div className="lg:ml-16 lg:mt-8">
              <p class="lg:text-xl font-bold text-black">Prohibited Activities: </p>
              <p class="lg:text-base1 lg:pt-2"> Users may not engage in activities such as spamming, harassment, or posting illegal content on the platform.</p>
            </div>

            <div className="lg:ml-16 lg:mt-8">
              <p class="lg:text-xl font-bold text-black">Termination: </p>
              <p class="lg:text-base1 lg:pt-2">  RecruitmentPlus reserves the right to suspend or terminate user accounts for violations of the terms of use.</p>
            </div>

            <div className="lg:ml-16 lg:mt-8">
              <p class="lg:text-xl font-bold text-black">Disclaimer of Warranties: </p>
              <p class="lg:text-base1 lg:pt-2">  The platform is provided "as is" without any warranties. RecruitmentPlus does not guarantee the accuracy or availability <br></br>of job listings or other content on the platform.</p>
            </div>

            <div className="lg:ml-16 lg:mt-8">
              <p class="lg:text-xl font-bold text-black">Limitation of Liability: </p>
              <p class="lg:text-base1 lg:pt-2">  RecruitmentPlus limits its liability for damages arising from the use of the platform.</p>
            </div>
        </section>


        {/*TAB VIEW*/}
        <section className="xl:hidden lg:hidden md:block sm:hidden hidden container mx-auto figtree pb-24">
            <div className="">
               <p class="text-darkblack text-center font-bold text-4xl mt-16">Terms Of Use</p>
            </div>

            <div className="ml-16 mt-16">
              <p class="text-xl font-bold text-black">Acceptance of Terms:</p>
              <p class="text-base1 pt-2 mr-8">By accessing or using RecruitmentPlus, users agree to abide by the terms and conditions outlined by the platform.</p>
            </div>

            <div className="ml-16 mt-8">
              <p class="text-xl font-bold text-black">User Accounts:</p>
              <p class="text-base1 pt-2  mr-8">Users may need to create an account to access certain features. They are responsible for maintaining the confidentiality of their account credentials.</p>
            </div>

            <div className="ml-16 mt-8">
              <p class="text-xl font-bold text-black">Services Provided:</p>
              <p class="text-base1 pt-2  mr-8"> RecruitmentPlus provides a platform for connecting job seekers with employers. The platform may offer features such as job listings, resume posting, and communication tools.</p>
            </div>

            <div className="ml-16 mt-8">
              <p class="text-xl font-bold text-black">User Responsibilities: </p>
              <p class="text-base1 pt-2  mr-8">Users are responsible for the accuracy of the information they provide on the platform. They must also comply with all applicable laws and regulations.</p>
            </div>

            <div className="ml-16 mt-8">
              <p class="text-xl font-bold text-black">Intellectual Property:</p>
              <p class="text-base1 pt-2  mr-8"> Users retain ownership of the content they post on the platform (e.g., resumes, job listings). However, by posting content, users grant RecruitmentPlus a license to use and display that content.</p>
            </div>

            <div className="ml-16 mt-8">
              <p class="text-xl font-bold text-black">Privacy Policy:</p>
              <p class="text-base1 pt-2  mr-8"> RecruitmentPlus collects and uses personal information in accordance with its privacy policy, which should be reviewed separately.</p>
            </div>

            <div className="ml-16 mt-8">
              <p class="text-xl font-bold text-black">Prohibited Activities: </p>
              <p class="text-base1 pt-2  mr-8"> Users may not engage in activities such as spamming, harassment, or posting illegal content on the platform.</p>
            </div>

            <div className="ml-16 mt-8">
              <p class="text-xl font-bold text-black">Termination: </p>
              <p class="text-base1 pt-2  mr-8">  RecruitmentPlus reserves the right to suspend or terminate user accounts for violations of the terms of use.</p>
            </div>

            <div className="ml-16 mt-8">
              <p class="text-xl font-bold text-black">Disclaimer of Warranties: </p>
              <p class="text-base1 pt-2  mr-8">  The platform is provided "as is" without any warranties. RecruitmentPlus does not guarantee the accuracy or availability of job listings or other content on the platform.</p>
            </div>

            <div className="ml-16 mt-8 pb-8">
              <p class="text-xl font-bold text-black">Limitation of Liability: </p>
              <p class="text-base1 pt-2  mr-8">  RecruitmentPlus limits its liability for damages arising from the use of the platform.</p>
            </div>
        </section>


        {/*MOBILE VIEW*/}

        <section className="xl:hidden lg:hidden md:hidden sm:block block container mx-auto figtree pb-12">
            <div className="">
               <p class="text-darkblack text-center font-bold text-4xl mt-16">Terms Of Use</p>
            </div>

            <div className="ml-8 mt-16">
              <p class="text-xl font-bold text-black">Acceptance of Terms:</p>
              <p class="text-base1 pt-2 mr-8">By accessing or using RecruitmentPlus, users agree to abide by the terms and conditions outlined by the platform.</p>
            </div>

            <div className="ml-8 mt-8">
              <p class="text-xl font-bold text-black">User Accounts:</p>
              <p class="text-base1 pt-2  mr-8">Users may need to create an account to access certain features. They are responsible for maintaining the confidentiality of their account credentials.</p>
            </div>

            <div className="ml-8 mt-8">
              <p class="text-xl font-bold text-black">Services Provided:</p>
              <p class="text-base1 pt-2  mr-8"> RecruitmentPlus provides a platform for connecting job seekers with employers. The platform may offer features such as job listings, resume posting, and communication tools.</p>
            </div>

            <div className="ml-8 mt-8">
              <p class="text-xl font-bold text-black">User Responsibilities: </p>
              <p class="text-base1 pt-2  mr-8">Users are responsible for the accuracy of the information they provide on the platform. They must also comply with all applicable laws and regulations.</p>
            </div>

            <div className="ml-8 mt-8">
              <p class="text-xl font-bold text-black">Intellectual Property:</p>
              <p class="text-base1 pt-2  mr-8"> Users retain ownership of the content they post on the platform (e.g., resumes, job listings). However, by posting content, users grant RecruitmentPlus a license to use and display that content.</p>
            </div>

            <div className="ml-8 mt-8">
              <p class="text-xl font-bold text-black">Privacy Policy:</p>
              <p class="text-base1 pt-2  mr-8"> RecruitmentPlus collects and uses personal information in accordance with its privacy policy, which should be reviewed separately.</p>
            </div>

            <div className="ml-8 mt-8">
              <p class="text-xl font-bold text-black">Prohibited Activities: </p>
              <p class="text-base1 pt-2  mr-8"> Users may not engage in activities such as spamming, harassment, or posting illegal content on the platform.</p>
            </div>

            <div className="ml-8 mt-8">
              <p class="text-xl font-bold text-black">Termination: </p>
              <p class="text-base1 pt-2  mr-8">  RecruitmentPlus reserves the right to suspend or terminate user accounts for violations of the terms of use.</p>
            </div>

            <div className="ml-8 mt-8">
              <p class="text-xl font-bold text-black">Disclaimer of Warranties: </p>
              <p class="text-base1 pt-2  mr-8">  The platform is provided "as is" without any warranties. RecruitmentPlus does not guarantee the accuracy or availability of job listings or other content on the platform.</p>
            </div>

            <div className="ml-8 mt-8 pb-8">
              <p class="text-xl font-bold text-black">Limitation of Liability: </p>
              <p class="text-base1 pt-2  mr-8">  RecruitmentPlus limits its liability for damages arising from the use of the platform.</p>
            </div>
        </section>

        <Footer2/>

        
        </>
    );
}