import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Abanner1 from "assets/img/Abanner1.jpg";
import Abanner2 from "assets/img/Abanner2.jpg";
import Abanner3 from "assets/img/Abanner3.jpg";

const images = [
  "https://live.themewild.com/fishred/assets/img/hero/slider-1.jpg",
  "https://live.themewild.com/fishred/assets/img/hero/slider-2.jpg",
  "https://live.themewild.com/fishred/assets/img/hero/slider-3.jpg",
];
const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} text-red-500 cursor-pointer`}
      style={{ ...style, display: "block", left: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      {/* Custom arrow content can be added here */}
      <span className="text-2xl">{"<"}</span>
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} text-blue-500 cursor-pointer`}
      style={{ ...style, display: "block", right: "10px", zIndex: 1 }}
      onClick={onClick}
    >
      {/* Custom arrow content can be added here */}
      <span className="text-2xl">{">"}</span>
    </div>
  );
};

const Cloudslider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true, // Enable autoplay
    responsive: [
      {
        breakpoint: 1280, // desktop breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024, // tablet breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 900, // tablet landscape breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 600, // mobile breakpoint
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <section className="">
      <Slider {...settings}>
        <div className="">
          
          <div className="relative"
          style={{
            backgroundImage:`url(${Abanner1})`,
            width: "100%",
            height: "600px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="bg-black-overlay">
          <div className="container mx-auto relative">
            <div className="absolute xl:px-20 xl:pt-44 lg:px-20 lg:pt-48 md:px-12 md:pt-40 roboto px-4 pt-48">
              <div className="bg-newshade32 rounded-full xl:w-6/12 lg:w-4/12 md:w-5/12 w-2/12">
                <p class="xl:text-base1 lg:text-base1 font-bold text-white text-center md:text-base1 text-base1">
                  WELCOME TO FISHRED
                </p>
              </div>
              <h1 class="xl:text-5xlll lg:text-4xl  font-bold text-white xl:mt-4 md:text-4xl text-3xl">
                Gear Up For Your Next<br></br> Fishing Expedition
              </h1>
              <p class=" xl:block lg:bock md:block sm:hidden hidden xl:text-base text-white xl:mt-4 lg:text-base lg:mt-4 md:text-base ">
                Lorem Ipsum, sometimes referred to as 'lipsum', is the
                placeholder text used<br></br> in design when creating content.{" "}
              </p>
              <p class="xl:hidden lg:hidden md:hidden sm:block block text-base1 text-white mt-2">Lorem Ipsum, sometimesreferred to as <br></br>'lipsum', is placeholder text used in design<br></br> when creating content.</p>

              <div className="flex xl:mt-6 lg:mt-6 md:mt-6 mt-4">
                <a>
                  <button class="bg-newshade32 text-white rounded-md px-8 py-2 ">
                    About More
                  </button>
                </a>

                <a href="/">
                  <button class="bg-white text-skyblue rounded-md px-8 py-2 xl:ml-4 lg:ml-4 md:ml-4 ml-2">
                    Learn More
                  </button>
                </a>
              </div>
            </div>
          </div>
          </div>
        </div>
        </div>

        <div className="bg-black-overlay">
         
           <div
          style={{
            backgroundImage: `url(${Abanner2})`,
            width: "100%",
            height: "600px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >

          <div className="container mx-auto">
            <div className="absolute xl:px-20 xl:pt-44 lg:pt-48 roboto md:pt-40 md:px-12 px-4 pt-48 ">
              <div className="bg-newshade32 rounded-full xl:w-6/12 lg:w-4/12 md:w-5/12 w-2/12">
                <p class="xl:text-base1 font-bold text-white text-center lg:text-base1 md:text-base1 text-base1">
                  WELCOME TO FISHRED
                </p>
              </div>
              <h1 class="xl:text-5xlll font-bold text-white xl:mt-4 lg:text-4xl lg:mt-4 md:text-4xl text-3xl">
                Gear Up For Your Next<br></br> Fishing Expedition
              </h1>
              <p class=" xl:block lg:bock md:block sm:hidden hidden xl:text-base text-white xl:mt-4 lg:text-base lg:mt-4 md:text-base ">
                Lorem Ipsum, sometimes referred to as 'lipsum', is the
                placeholder text used<br></br> in design when creating content.{" "}
              </p>
              <p class="xl:hidden lg:hidden md:hidden sm:block block text-base1 text-white mt-2">Lorem Ipsum, sometimesreferred to as <br></br>'lipsum', is placeholder text used in design<br></br> when creating content.</p>

              <div className="flex xl:mt-6 lg:mt-6 md:mt-6 mt-4">
                <a href="/">
                  <button class="bg-newshade32 text-white rounded-md px-8 py-2 ">
                    About More
                  </button>
                </a>

                <a>
                  <button class="bg-white text-skyblue rounded-md px-8 py-2 xl:ml-4 lg:ml-4 md:ml-4 ml-2">
                    Learn More
                  </button>
                </a>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div className="bg-black-overlay">
          
          <div
          style={{
            backgroundImage:`url(${Abanner3})`,
            width: "100%",
            height: "600px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          
          <div className="container mx-auto">
            <div className="absolute xl:px-20 xl:pt-44 lg:pt-48 roboto md:pt-40 md:px-12 px-4 pt-48  ">
              <div className="bg-newshade32 rounded-full xl:w-6/12 lg:w-4/12 md:w-5/12 w-2/12">
                <p class="xl:text-base1 font-bold text-white text-center md:text-base1 text-base1">
                  WELCOME TO FISHRED
                </p>
              </div>
              <h1 class="xl:text-5xlll font-bold text-white xl:mt-4 lg:text-4xl lg:mt-4 md:text-4xl text-3xl">
                Gear Up For Your Next<br></br> Fishing Expedition
              </h1>
              <p class=" xl:block lg:bock md:block sm:hidden hidden xl:text-base text-white xl:mt-4 lg:text-base lg:mt-4 md:text-base ">
                Lorem Ipsum, sometimes referred to as 'lipsum', is the
                placeholder text used<br></br> in design when creating content.{" "}
              </p>
              <p class="xl:hidden lg:hidden md:hidden sm:block block text-base1 text-white mt-2">Lorem Ipsum, sometimesreferred to as <br></br>'lipsum', is placeholder text used in design<br></br> when creating content.</p>

              <div className="flex xl:mt-6 lg:mt-6 md:mt-6 mt-4">
                <a href="/">
                  <button class="bg-newshade32 text-white rounded-md px-8 py-2 ">
                    About More
                  </button>
                </a>

                <a>
                  <button class="bg-white text-skyblue rounded-md px-8 py-2 xl:ml-4 lg:ml-4 md:ml-4 ml-2">
                    Learn More
                  </button>
                </a>
              </div>
            </div>
          </div>
          </div>
          </div>
        
      </Slider>
    </section>
  );
};

export default Cloudslider;
