import Indexnavbar2 from "components/Navbars/Indexnavbar2";
import React, { FC, useState, Fragment, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import videoplay from "assets/img/videoplay.png";
import quotations from "assets/img/quotations1.png";
import quotations2 from "assets/img/quotation2.png";
import pic1 from "assets/img/pic1.jpg";
import pic2 from "assets/img/pic2.png";
import training from "assets/img/training.png";
import consultation from "assets/img/consultation.png";

import Footer2 from "components/Footers/Footer2";

export default function Homepage2() {
  return (
    <section>
      <Indexnavbar2 />

      <div className="xl:block lg:block md:hideden hidden sm:hidden xl:-mt-52 lg:-mt-84">
        <div
          style={{
            backgroundImage: `url("https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/revslider/slider-demo-1/n-slider-01.jpg")`,
            width: "100%",
            height: "900px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div class="xl:ml-32 lg:ml-22">
            <h3 class="xl:pt-88 lg:pt-116 text-white font-semibold figtree xl:text-base xl:ml-2 lg:text-base">
              HR IS ALL ABOUT PEOPLE
            </h3>

            <div class="flex xl:mt-4 lg:mt-2">
              <p class="xl:text-7xl text-white font-bold figtree lg:text-4xl ">
                Human
              </p>
              <p class="xl:text-7xl text-skyblue font-bold xl:ml-4 figtree lg:text-4xl lg:ml-2">
                Resource
              </p>
            </div>

            <div class="flex xl:mt-4 lg:mt-2 ">
              <p class="xl:text-7xl text-white font-bold figtree lg:text-4xl">
                Solution
              </p>
              <p class="xl:text-7xl text-white font-bold xl:ml-4 figtree lg:text-4xl lg:ml-2">
                Partner
              </p>
            </div>

            <div className="flex xl:mt-12 lg:mt-6 xl:ml-2 lg:ml-1">
              <button class="border rounded-md px-4 py-1 text-white xl:text-base font-bold bg-transparent figtree lg:px-4  lg:text-sm">
                CONTACT US
              </button>
              <div class="flex">
                <a href="https://emphires-demo.pbminfotech.com/elementor-demo1/">
                  <img
                    class="w-12 xl:ml-12 lg:ml-8 rounded-full"
                    src={videoplay}
                  />
                </a>
                <p class="text-white font-bold xl:text-base xl:pt-4 xl:ml-4 figtree lg:pt-4 lg:ml-2">
                  HOW WE WORK
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="bg-newshade6 xl:block lg:hidden md:hidden hidden sm:hidden">
        <div
          style={{
            backgroundImage: `url("https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-bg-02.png")`,
            width: "100%",
            height: "500px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="grid grid-cols-3 xl:gap-8 lg:gap-1">
            <div className="border rounded-lg xl:w-10/12  xl:-mt-24 xl:ml-32 bg-white lg:w-8/12 lg:-mt-16 lg:ml-22 ">
              <div className="flex">
                <div>
                  <img
                    class="xl:w-10/12 xl:mt-8 xl:ml-8 lg:w-2/12 lg:ml-6 lg:mt-8"
                    src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-icon-01.png"
                    alt=""
                  />
                </div>
                <div class="box-1">
                  <span class="transparent-text lg:mt-8">01</span>
                </div>
              </div>
              <h2 class=" xl:text-xl figtree font-semibold text-darkblack xl:pt-8 xl:ml-8 lg:ml-6 lg:pt-2">
                Compensation Consulting
              </h2>
              <p class="xl:text-base font-italic xl:pt-2 xl:ml-8  lg:ml-6 lg:pt-1 lg:text-sm">
                We provide broad menu employment<br></br> services for a
                companies.
              </p>
            </div>

            <div className="border rounded-lg xl:w-10/12  xl:-mt-24 xl:ml-8  bg-white lg:-mt-16 lg:ml-6">
              <div className="flex">
                <div>
                  <img
                    class="xl:w-10/12 xl:mt-8 xl:ml-8 lg:w-2/12 lg:ml-6 lg:mt-8"
                    src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-icon-02.png"
                    alt=""
                  />
                </div>
                <div class="box-1">
                  <span class="transparent-text">02</span>
                </div>
              </div>
              <h2 class=" xl:text-xl figtree font-semibold text-darkblack xl:pt-8 xl:ml-8 lg:ml-6 lg:pt-2">
                Leadership Training
              </h2>
              <p class="xl:text-base font-italic xl:pt-2 xl:ml-8 xl:pb-12 lg:pb-4 lg:ml-6 lg:pt-1 lg:text-sm">
                We provide broad menu employment<br></br> services for a
                companies.
              </p>
            </div>
            <div className="border rounded-lg xl:w-10/12 xl:-mt-24 xl:-ml-16  bg-white lg:-mt-16 lg:-ml-6">
              <div className="flex">
                <div>
                  <img
                    class="xl:w-10/12 xl:mt-8 xl:ml-8 lg:w-2/12 lg:ml-6 lg:mt-8 "
                    src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-icon-03.png"
                    alt=""
                  />
                </div>
                <div class="box-1">
                  <span class="transparent-text">03</span>
                </div>
              </div>
              <h2 class=" xl:text-xl figtree font-semibold text-darkblack xl:pt-8 xl:ml-8 lg:ml-6 lg:pt-2">
                Talent Acquisition
              </h2>
              <p class="xl:text-base font-italic xl:pt-2 xl:ml-8 xl:pb-12 lg:pb-4 lg:ml-6 lg:pt-1 lg:text-sm">
                We provide broad menu employment<br></br> services for a
                companies.
              </p>
            </div>
          </div>

          <div class="grid grid-cols-2">
            <div>
              <div class="flex">
                <div>
                  <img
                    class="w-12  xl:ml-36 xl:mt-24 lg:mt-16 lg:ml-24"
                    src={quotations2}
                  />
                </div>
                <p class="text-white xl:text-xl xl:mt-24 xl:ml-16 font-italic figtree lg:mt-16 lg:ml-16 lg:text-base">
                  Train people quickly well with e-business.So they highly
                  efficient manufactured products.
                </p>
                <div class="vl"></div>
              </div>

              <p class="text-white text-center xl:text-2xl font-semibold xl:-mt-6 figtree lg:text-base lg:-mt-4">
                .....Richard Branson
              </p>
            </div>

            <div>
              <p class="text-white xl:text-base xl:mt-24 font-italic figtree lg:mt-20 lg:text-xs xl:ml-26 lg:ml-12">
                Our consultants believe in the value that you manage your
                regulatory<br></br> compliance, policies, and procedures. We
                have specialist for managed<br></br> employee
                performance,comparable to internal HR function.
              </p>
            </div>
          </div>
        </div>
      </section>


      {/*LANDSCAPE VIEW*/}

      <section className="bg-newshade6 xl:hidden lg:block md:hidden hidden sm:hidden">
        <div
          style={{
            backgroundImage: `url("https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-bg-02.png")`,
            width: "100%",
            height: "500px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="grid grid-cols-3 ">
            <div className="border rounded-lg  bg-white lg:w-10/12 lg:-mt-16 lg:ml-22 ">
              <div className="flex">
                <div>
                  <img
                    class="w-24 lg:ml-6 lg:mt-8"
                    src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-icon-01.png"
                    alt=""
                  />
                </div>
                <div class="box-6">
                  <span class="transparent-text lg:mt-8">01</span>
                </div>
              </div>
              <h2 class="  figtree font-semibold text-darkblack  lg:ml-6 lg:pt-2">
                Compensation Consulting
              </h2>
              <p class="xl:text-base font-italic   lg:ml-6 lg:pt-1 lg:text-sm">
                We provide broad menu employment<br></br> services for a
                companies.
              </p>
            </div>

            <div className="border rounded-lg bg-white lg:-mt-16 lg:ml-6 lg:w-10/12">
              <div className="flex">
                <div>
                  <img
                    class="w-24 lg:ml-6 lg:mt-8"
                    src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-icon-02.png"
                    alt=""
                  />
                </div>
                <div class="box-6">
                  <span class="transparent-text">02</span>
                </div>
              </div>
              <h2 class=" figtree font-semibold text-darkblack lg:ml-6 lg:pt-2">
                Leadership Training
              </h2>
              <p class=" font-italic lg:pb-4 lg:ml-6 lg:pt-1 lg:text-sm">
                We provide broad menu employment<br></br> services for a
                companies.
              </p>
            </div>

            <div className="border rounded-lg  bg-white lg:-mt-16 lg:-ml-6 lg:w-10/12">
              <div className="flex">
                <div>
                  <img
                    class="w-24 lg:ml-6 lg:mt-8 "
                    src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-icon-03.png"
                    alt=""
                  />
                </div>
                <div class="box-6">
                  <span class="transparent-text">03</span>
                </div>
              </div>
              <h2 class="  figtree font-semibold text-darkblack lg:ml-6 lg:pt-2">
                Talent Acquisition
              </h2>
              <p class="xl:text-base font-italic  lg:pb-4 lg:ml-6 lg:pt-1 lg:text-sm">
                We provide broad menu employment<br></br> services for a
                companies.
              </p>
            </div>
          </div>

          <div class="grid grid-cols-2">
            <div>
              <div class="flex">
                <div>
                  <img
                    class="w-12 lg:mt-28 lg:ml-24"
                    src={quotations2}
                  />
                </div>
                <p class="text-white  font-italic figtree lg:mt-28 lg:ml-16 lg:text-base">
                  Train people quickly well with e-business.So they highly
                  efficient manufactured products.
                </p>
                <div class="vl-3"></div>
              </div>

              <p class="text-white text-center font-semibold figtree lg:text-base lg:-mt-4">
                .....Richard Branson
              </p>
            </div>

            <div>
              <p class="text-white font-italic figtree lg:mt-28 lg:text-xs  lg:ml-12">
                Our consultants believe in the value that you manage your
                regulatory<br></br> compliance, policies, and procedures. We
                have specialist for managed<br></br> employee
                performance,comparable to internal HR function.
              </p>
            </div>
          </div>
        </div>
      </section>


      <section className="xl:block lg:block md:hidden hidden sm:hidden">
        <div className="xl:mt-24 lg:mt-16">
          <p class="text-center figtree font-semibold xl:text-lg lg:text-lg">
            WHO WE ARE
          </p>
          <h1 class="text-center xl:pt-2 font-bold figtree xl:text-4xl text-darkblack lg:text-2xl lg:pt-1">
            Why Choose HR Consulting
          </h1>
          <h1 class="text-center xl:pt-2 font-bold figtree xl:text-4xl text-skyblue lg:text-2xl lg:pt-1">
            Services Company
          </h1>
        </div>
      </section>

      <section className="xl:mt-16 lg:mt-16 xl:block lg:block md:hidden hidden sm:hidden">
        <div className="grid grid-cols-2">
          <div>
            <img
              class="xl:w-8/12 rounded xl:ml-44 lg:w-8/12 lg:ml-22"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-img-01.jpg"
              alt=""
            />
          </div>
          <div>
            <p class="xl:text-lg figtree xl:ml-8 lg:-ml-4 lg:text-base xl:mt-8">
              Our advisers provides a broad menu service that identifies the
              <br></br> task requirements in the organization job and designs
              the task<br></br> description with skill requirements for the
              task.
            </p>

            <div className="flex">
              <div>
                <img
                  class="xl:w-24 xl:mt-12 xl:ml-6 lg:w-20 lg:mt-4 lg:-ml-4"
                  src={pic1}
                />
              </div>
              <div className="">
                <h1 class="xl:mt-12 figtree text-darkblack font-bold xl:text-xl xl:ml-4 lg:mt-4 lg:text-lg lg:-ml-2">
                  Strategic Partners
                </h1>
                <p class="figtree xl:ml-4 xl:text-base lg:text-xs lg:-ml-2">
                  We believe in the value that our functions added to a business
                  <br></br> Our strategic partners that specialist role for HR
                  is success of a<br></br> business.
                </p>
              </div>
            </div>

            <div className="flex">
              <div>
                <img
                  class="xl:w-24 xl:mt-12 xl:ml-6 lg:w-20 lg:mt-4 lg:-ml-4"
                  src={pic2}
                />
              </div>
              <div className="">
                <h1 class="xl:mt-12 figtree text-darkblack font-bold xl:text-xl xl:ml-4 lg:mt-4 lg:text-lg lg:-ml-2">
                  Corporate Programs
                </h1>
                <p class="figtree xl:ml-4 xl:text-base lg:text-xs lg:-ml-2">
                  We believe in the value that our functions added to a business
                  <br></br>Our strategic partners that specialist role for HR is
                  success of a<br></br> business.
                </p>
              </div>
            </div>
            <div class="flex xl:text-lg figtree lg:text-sm">
              <p class="xl:ml-8 figtree xl:pt-8 lg:-ml-2 lg:pt-6">
                Get intrigued about our service pricing?
              </p>
              <div class="flex xl:mt-8 xl:ml-2 lg:mt-6 lg:ml-1">
                <u>Learn More</u>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:block md:hidden hidden sm:hidden bg-newshade22 xl:mt-20 lg:mt-16">
        <div className="container mx-auto overflow-hidden xl:px-16 lg:px-24 px-4">
          <div className="grid grid-cols-2">
            <div className="figtree">
              <p class=" xl:pt-20 font-semibold xl:text-lg lg:pt-12 lg:text-base xl:ml-10">
                OUR SERVICES
              </p>
              <h1 class=" xl:pt-2 xl:text-4xl text-darkblack font-bold lg:pt-1 lg:text-2xl xl:ml-10">
                Deliver Outsourced
              </h1>
              <h1 class=" xl:pt-2 xl:text-4xl text-skyblue font-bold lg:pt-0 lg:text-2xl xl:ml-10">
                HR Service's
              </h1>
              <div class="figtree  text-darkblack xl:text-base font-semibold xl:mt-6 lg:text-sm lg:mt-2 xl:ml-10">
                <u>VIEW ALL SERVICES</u>
              </div>
            </div>

            <div>
              <p class="figtree xl:text-lg xl:pt-28 lg:pt-16 lg:text-sm xl:-ml-24 lg:-ml-12">
                We offer to include employment services such as payroll and our
                priorities have always remained the same: to benefits
                administration, HR management, and assistance with a employer.
              </p>
            </div>
          </div>

          <div className="grid grid-cols-3 xl:mt-12 xl:gap-0 lg:mt-8 lg:gap-3">
            <div>
              <img
                class="xl:w-11/12 rounded-lg lg:w-12/12 xl:ml-8"
                src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/05/service-new-01-770x500.jpg"
                alt=""
              />
            </div>

            <div>
              <img
                class="xl:w-11/12 rounded-lg  lg:w-12/12 xl:ml-4"
                src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/05/service-new-02-770x500.jpg"
                alt=""
              />
            </div>

            <div>
              <img
                class="xl:w-11/12 rounded-lg  lg:w-12/12"
                src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/05/service-new-03-770x500.jpg"
                alt=""
              />
            </div>
          </div>

          <div className="grid grid-cols-3 ">
            <div className="border xl:w-9/12 xl:-mt-8 xl:ml-15 bg-white rounded-md lg:-mt-12 lg:ml-8">
              <p class="figtree text-center text-skyblue xl:pt-6 xl:text-sm font-semibold lg:pt-4 lg:text-sm">
                MANAGEMENT
              </p>
              <h5 class="figtree text-center text-darkblack xl:pt-1 xl:text-xl font-bold lg:pt-0 lg:text-lg">
                HR Consulting
              </h5>
              <p class="figtree text-center xl:pt-1 xl:pb-12 xl:text-base lg:text-xs lg:pt-1 lg:pb-8">
                HR consultants are provides broad<br></br> menu Human Resource
                Service
              </p>
            </div>

            <div className="border xl:w-9/12 xl:-mt-8  xl:ml-12 bg-white rounded-md lg:-mt-12 lg:ml-8">
              <p class="figtree text-center text-skyblue xl:pt-6 xl:text-sm font-semibold lg:pt-4 lg:text-sm">
                PLANNING
              </p>
              <h5 class="figtree text-center text-darkblack xl:pt-1 xl:text-xl font-bold lg:pt-0 lg:text-lg">
                Improving Resource
              </h5>
              <p class="figtree text-center xl:pt-1 xl:pb-12 xl:text-base lg:text-xs lg:pt-1 lg:pb-8">
                HR consultants are provides broad<br></br> menu Human Resource
                Service
              </p>
            </div>

            <div className="border xl:w-9/12 xl:-mt-8 xl:ml-10 bg-white rounded-md lg:-mt-12 lg:ml-10">
              <p class="figtree text-center text-skyblue xl:pt-6 xl:text-sm font-semibold lg:pt-4 lg:text-sm">
                TRAINING
              </p>
              <h5 class="figtree text-center text-darkblack xl:pt-1 xl:text-xl font-bold lg:pt-0 lg:text-lg">
                Strategic Planning
              </h5>
              <p class="figtree text-center xl:pt-1 xl:pb-12 xl:text-base lg:text-xs lg:pt-1 lg:pb-8">
                HR consultants are provides broad<br></br> menu Human Resource
                Service
              </p>
            </div>
          </div>

          <div className="grid grid-cols-3 ">
            <div>
              <div className="center-button xl:ml-5 xl:-mt-8 lg:-mt-6">
                <div className="text-white bg-newshade xl:p-3 xl:text-2xl lg:p-3 lg:text-2xl rounded-full w-14 red-on-hover1">
                  <a href="/homepage2">➜</a>
                </div>
              </div>
            </div>

            <div>
              <div className="center-button xl:ml-4 xl:-mt-8 lg:-mt-6">
                <div className="text-white bg-newshade p-3 text-2xl rounded-full w-14 red-on-hover1 ">
                  <a href="/homepage2">➜</a>
                </div>
              </div>
            </div>

            <div>
              <div className="center-button xl:ml-4 xl:-mt-8 lg:-mt-6">
                <div className="text-white bg-newshade p-3 text-2xl rounded-full w-14 red-on-hover1 ">
                  <a href="/homepage2">➜</a>
                </div>
              </div>
            </div>
          </div>

          <div className="flex xl:mt-16  xl:pb-20 justify-center lg:mt-12 lg:pb-8 ">
            <p class="figtree xl:text-base text-center figtree ">
              Get the Human Resource Outsourcing Service’s.
            </p>
            <div className="figtree blue-on-hover xl:text-base text-black">
              <u>Book an appointment!</u>
            </div>
          </div>
        </div>
      </section>

      <div className="xl:block lg:block md:hidden hidden sm:hidden">
        <div
          style={{
            backgroundImage: `url("https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-bg-01.jpg")`,
            width: "100%",
            height: "600px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="center-button">
            <a
              class="xl:pt-24 lg:pt-24"
              href="https://www.youtube.com/watch?v=WvyK40w1EPY"
            >
              <img class="xl:w-20 lg:w-16 rounded-full" src={videoplay} />
            </a>
          </div>
          <div>
            <p class="xl:text-4xl figtree text-white text-center xl:pt-12 font-bold lg:text-3xl lg:pt-12">
              With our specialists, and experts at<br></br>your disposal, you
              will face complex.
            </p>
          </div>
        </div>
      </div>

      <section className="xl:block lg:hidden md:hidden hidden sm:hidden">
        <div className="grid grid-cols-2">
          <div className="xl:w-12/12  rounded-md bg-newshade6 xl:-mt-32 lg:w-12/12 lg:-mt-20 ">
            <p class="text-white figtree font-semibold xl:text-lg xl:ml-36 xl:pt-12 lg:text-base lg:pt-10 lg:ml-22">
              WHO WE ARE
            </p>
            <div className="flex">
              <p class="text-white figtree font-bold xl:text-4xl xl:ml-36 xl:pt-2 lg:ml-22 lg:text-2xl">
                Subscribe
              </p>
              <p class="text-darkblack font-bold figtree xl:text-4xl xl:ml-2 xl:pt-2 lg:ml-2 lg:text-2xl ">
                Newsletter
              </p>
            </div>
            <div>
              <form class="xl:ml-36 xl:mt-8 figtree xl:mb-12 lg:mb-12 lg:mt-2 lg:ml-22">
                <input
                  class="xl:w-9/12 lg:w-10/12 border rounded-md "
                  type="text"
                  id="text"
                  placeholder="Your Name"
                />
                <input
                  class="xl:w-9/12 lg:w-10/12 border rounded-md xl:mt-4 lg:mt-2"
                  type="text"
                  id="text"
                  placeholder="Your Email"
                />
                <textarea
                  class="border rounded-md xl:mt-4 lg:mt-2 lg:w-10/12 xl:w-9/12"
                  id="Message"
                  name="Message"
                  rows="3"
                  cols="53"
                  placeholder="Message"
                ></textarea>
                <br></br>
                <button class="text-white xl:px-6 xl:py-3 lg:px-4 lg:py-2 rounded-md xl:mt-4 lg:mt-2 xl:text-base1 bg-black">
                  Subscribe
                </button>
              </form>
            </div>
          </div>

          <div>
            <div className="flex">
              <div className="">
                <img
                  class="xl:w-12/12 xl:mt-12 xl:ml-12 lg:w-10/12 lg:mt-12 lg:ml-10 rounded-md"
                  src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-img-06.jpg"
                  alt=""
                />
              </div>
              <div className="">
              <div class="btn">
                <button className="bg-black text-white rounded-lg  xl:text-base widd xl:py-2 font-bold xl:w-8/12 lg:w-2/12">
                  100% Success
                </button>
              </div>
              </div>
           </div>
           </div>
        </div>
      </section>


{/*LANDSCAPE VIEW*/}

<section className="xl:hidden lg:block md:hidden hidden sm:hidden">
        <div className="grid grid-cols-2">
          <div className=" rounded-md bg-newshade6 lg:w-12/12 lg:-mt-20 ">
            <p class="text-white figtree font-semibold  lg:text-base lg:pt-10 lg:ml-22">
              WHO WE ARE
            </p>
            <div className="flex">
              <p class="text-white figtree font-bold  lg:ml-22 lg:text-2xl">
                Subscribe
              </p>
              <p class="text-darkblack font-bold figtree lg:ml-2 lg:text-2xl ">
                Newsletter
              </p>
            </div>
            <div>
              <form class="figtree  lg:mb-12 lg:mt-2 lg:ml-22">
                <input
                  class=" lg:w-10/12 border rounded-md "
                  type="text"
                  id="text"
                  placeholder="Your Name"
                />
                <input
                  class="lg:w-10/12 border rounded-md lg:mt-2"
                  type="text"
                  id="text"
                  placeholder="Your Email"
                />
                <textarea
                  class="border rounded-md lg:mt-2 lg:w-10/12"
                  id="Message"
                  name="Message"
                  rows="3"
                  cols="53"
                  placeholder="Message"
                ></textarea>
                <br></br>
                <button class="text-white  lg:px-4 lg:py-2 rounded-md  lg:mt-2  bg-black">
                  Subscribe
                </button>
              </form>
            </div>
          </div>

          <div>
            <div className="">
              <div className="">
                <img
                  class=" lg:w-10/12 lg:mt-12 lg:ml-10 rounded-md"
                  src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-img-06.jpg"
                  alt=""
                />
              </div>
              <div className="">
                <button class=" lg:w-10/12 mt-4 text-white bg-black px-12 py-2 rounded-md lg:ml-10">100% Success</button>
                </div>
           </div>
           </div>
        </div>
      </section>
     

      <section className="xl:block lg:block md:hidden hidden sm:hidden container mx-auto xl:mt-24 lg:mt-16">
        <div>
          <p class="text-center figtree xl:text-lg font-semibold lg:text-base">
            OUR APPROACH
          </p>
          <div className="flex justify-center items-center text-center align-center align-middle xl:mt-4">
            <p class="text-darkblack xl:text-4xl figtree text-center font-bold lg:text-2xl xl:-mt-2">
              We Choose a Human
            </p>
            <p class="text-skyblue xl:text-4xl figtree font-bold lg:text-2xl xl:ml-2 xl:-mt-2">
              Resources
            </p>
          </div>
          <p class="text-center figtree xl:text-base1 xl:pt-1 lg:text-sm lg:pt-2">
            We have our special functions are add to a business. We feel that
            this special functions is part of<br></br> HR its piece of the
            success business.
          </p>
        </div>
      </section>

      <section className="xl:block lg:block md:hidden hidden sm:hidden container mx-auto xl:mt-24 lg:mt-16">
        <div className="grid grid-cols-3 xl:gap-0 lg:gap-0">
          <div>
            <div class=" ">
              <img
                class="xl:w-24  xl:ml-36 lg:w-20 lg:ml-32"
                src={consultation}
              />
            </div>
            <h2 class="figtree xl:pt-6 text-darkblack text-center xl:text-2xl font-bold lg:text-base lg:pt-2">
              Compensation Consulting
            </h2>
            <p class="figtree xl:pt-2 text-center xl:text-base lg:text-xs lg:pt-1">
              Compensation Consulting are always help for<br></br> a companies
              marketing, customer growths.
            </p>
          </div>

          <div>
            <div>
              <img class="xl:w-24 xl:ml-36 lg:w-20 lg:ml-32" src={training} />
            </div>
            <h2 class="figtree xl:pt-6 text-darkblack text-center xl:text-2xl font-bold lg:text-base lg:pt-2">
              Leadership Training
            </h2>
            <p class="figtree xl:pt-2 text-center xl:text-base lg:text-xs lg:pt-1">
              Compensation Consulting are always help for<br></br> a companies
              marketing, customer growths.
            </p>
          </div>

          <div>
            <div>
              <img
                class="xl:w-24 xl:ml-36 lg:w-20 lg:ml-32"
                src={consultation}
              />
            </div>
            <h2 class="figtree xl:pt-6 text-darkblack text-center xl:text-2xl font-bold lg:text-base lg:pt-2">
              Corporated Programs
            </h2>
            <p class="figtree xl:pt-2 text-center xl:text-base lg:text-xs lg:pt-1">
              Compensation Consulting are always help for<br></br> a companies
              marketing, customer growths.
            </p>
          </div>
        </div>
      </section>

      <section className="xl:block lg:block md:hidden hidden sm:hidden bg-newshade6 xl:mt-32 xl:mb-20 lg:mt-32 ">
        <div
          style={{
            backgroundImage: `url("https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/01/map-02.png")`,
            width: "100%",
            height: "600px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="container mx-auto ">
            <div className="grid grid-cols-5 xl:gap-4 lg:gap-0 ">
              <div>
                <img
                  class="rounded-lg xl:-mt-16 xl:w-12/12 lg:w-10/12 lg:-mt-12 xl:ml-0 lg:ml-12"
                  src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/project-new-01-770x770.jpg"
                  alt=""
                />
              </div>

              <div>
                <img
                  class="rounded-lg xl:-mt-16 xl:w-12/12 lg:w-10/12 lg:-mt-12 xl:ml-0 lg:ml-8"
                  src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/project-new-02-770x770.jpg"
                  alt=""
                />
              </div>

              <div>
                <img
                  class="rounded-lg xl:-mt-16 xl:w-12/12 lg:w-10/12 lg:-mt-12 xl:ml-0 lg:ml-4"
                  src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/project-new-03-770x770.jpg"
                  alt=""
                />
              </div>

              <div>
                <img
                  class="rounded-lg xl:-mt-16 xl:w-12/12 lg:w-10/12 lg:-mt-12 xl:ml-0 lg:ml-1"
                  src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/project-new-04-770x770.jpg"
                  alt=""
                />
              </div>

              <div>
                <img
                  class="rounded-lg xl:-mt-16 xl:w-12/12 lg:w-10/12 lg:-mt-12 xl:ml-0 lg:-ml-4"
                  src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/project-new-05-770x770.jpg"
                  alt=""
                />
              </div>
            </div>

            <div className="container mx-auto xl:mt-20 lg:mt-24 ">
              <div className=" grid grid-cols-2 ">
                <div>
                  <p class="text-white figtree  xl:text-lg font-semibold xl:ml-12 lg:text-base lg:ml-16">
                    OUR TESTIMONIAL
                  </p>
                  <h3 class="text-white figtree font-bold xl:text-4xl xl:pt-4 xl:ml-12 lg:text-2xl lg:ml-16 lg:pt-2">
                    What Employ Say About
                  </h3>
                  <h3 class="text-darkblack figtree font-bold xl:text-4xl xl:pt-0 xl:ml-12 lg:text-2xl lg:ml-16 ">
                    a Human Resources
                  </h3>
                  <p class="text-white figtree font-semibold xl:text-lg xl:pt-4 xl:ml-12 lg:text-base lg:ml-16 lg:pt-2">
                    We have introduced the value that our functions. We<br></br>{" "}
                    feel that this specialist part of Human Resources are{" "}
                    <br></br>contribution in digital marketing
                  </p>
                  <div class=" flex xl:mt-2 xl:text-base text-darkblack figtree font-semibold xl:ml-12 lg:text-base lg:ml-16 lg:pt-2">
                    <u>VIEW ALL TESTIMONIALS</u>
                    <a class="xl:ml-4" href="/homepage2">
                      ➜
                    </a>
                  </div>
                </div>

                <div>
                  <div className="">
                    <img
                      class="xl:w-24 lg:w-24 xl:p-0 border rounded-md xl:ml-8 lg:-ml-12"
                      src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/testimonial-01.jpg"
                      alt=""
                    />
                  </div>
                  <div className="border rounded-md xl:w-10/12 bg-white xl:-mt-12 xl:ml-12 lg:w-10/12 lg:-mt-16">
                    <p class="figtree xl:text-lg text-center xl:p-12 lg:text-sm lg:p-12">
                      HR Adviser responded quickly to questions and work with
                      thanks for picking the most expert employee’s for our
                      company. These guys are real best adviser & helped so many
                      times
                    </p>
                    <div className="flex justify-center xl:-mt-8 xl:pb-8 lg:-mt-4 lg:pb-8">
                      <p class="text-skyblue xl:text-lg figtree font-bold ">
                        Jasics Lenon
                      </p>
                      <p class="xl:text-lg figtree font-semibold ">-Manager</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:block md:hidden hidden sm:hidden container mx-auto xl:mt-24 lg:mt-16">
        <div className="grid grid-cols-5 xl:gap-2 lg:gap-0">
          <div>
            <img
              class="xl:w-8/12 lg:w-7/12 xl:ml-20 lg:ml-16"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/client-01-1.png"
              alt=""
            />
          </div>

          <div>
            <img
              class="xl:w-8/12 lg:w-7/12 xl:ml-6 lg:ml-6"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/client-02-1.png"
              alt=""
            />
          </div>

          <div>
            <img
              class="xl:w-8/12 lg:w-7/12 xl:-ml-2 lg:ml-6"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/client-03-1.png"
              alt=""
            />
          </div>

          <div>
            <img
              class="xl:w-8/12 lg:w-7/12 xl:-ml-6 lg:ml-6"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/client-04-1.png"
              alt=""
            />
          </div>

          <div>
            <img
              class="xl:w-8/12 lg:w-7/12 xl:-ml-12 lg:ml-6"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/client-05-1.png"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="xl:block lg:block md:hidden hidden sm:hidden container mx-auto xl:mt-24 lg:mt-16">
        <div>
          <p class="text-center figtree xl:text-lg font-semibold  lg:text-base">
            SUCCESS STORIES
          </p>
          <div className="flex justify-center xl:mt-4 lg:mt-2">
            <p class="text-darkblack figtree font-bold xl:text-4xl lg:text-2xl">
              Cources From Human
            </p>
            <p class="text-skyblue figtree font-bold xl:text-4xl xl:ml-2 lg:text-2xl lg:ml-1">
              Resource
            </p>
          </div>
        </div>
      </section>

      <section className="xl:block lg:block md:hidden hidden sm:hidden container mx-auto">
        <div className="grid grid-cols-3 xl:mt-20 lg:mt-16">
          <div className="">
            <img
              class="rounded-md xl:w-11/12 xl:ml-8 lg:w-8/12 lg:ml-20"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/blog-01-1-770x500.jpg"
              alt=""
            />

            <div className="flex xl:mt-8 lg:mt-4">
              <p class="text-base xl:ml-16 lg:ml-22 ">🚹</p>
              <p class="xl:text-base figtree xl:ml-1 lg:text-xs xl:pt-0 lg:pt-1">
                by admin
              </p>
              <p class="text-base xl:ml-4">📂</p>
              <p class="xl:text-base figtree xl:ml-1 lg:text-xs xl:pt-0 lg:pt-1">
                Consultation
              </p>
              <p class="text-base xl:ml-4">🥃</p>
              <p class="xl:text-base figtree  xl:ml-1  lg:text-xs xl:pt-0 lg:pt-1">
                0
              </p>
            </div>

            <h1 class="text-darkblack font-semibold figtree xl:pt-4 xl:text-2xl xl:ml-16 lg:ml-22 lg:text-base lg:pt-1">
              Best Service provides for<br></br> Small Businesses
            </h1>
            <p class="xl:text-lg figtree xl:ml-16 xl:pt-2 lg:pt-1 lg:text-xs lg:ml-22">
              There are full service engage company is<br></br> to provide
              solution for employees needs…
            </p>

            <div class=" flex xl:ml-16 text-darkblack xl:text-base figtree font-semibold xl:mt-4 lg:mt-2 lg:ml-22 lg:text-sm">
              <u>READ MORE</u>
              <p class="xl:ml-2">➔</p>
            </div>
          </div>

          <div className="">
            <img
              class="rounded-md xl:w-11/12 xl:ml-4 lg:w-8/12 lg:ml-10"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/blog-002-770x500.jpg"
              alt=""
            />

            <div className="flex xl:mt-8 lg:mt-4">
              <p class="text-base xl:ml-16 lg:ml-12">🚹</p>
              <p class="xl:text-base figtree  xl:ml-1 lg:text-xs xl:pt-0 lg:pt-1">
                by admin
              </p>
              <p class="text-base xl:ml-4">📂</p>
              <p class="xl:text-base figtree  xl:ml-1 lg:text-xs xl:pt-0 lg:pt-1 ">
                Consultation
              </p>
              <p class="text-base xl:ml-4">🥃</p>
              <p class="xl:text-base figtree  xl:ml-1 lg:text-xs xl:pt-0 lg:pt-1">
                0
              </p>
            </div>

            <h1 class="text-darkblack font-semibold figtree xl:pt-4 xl:text-2xl xl:ml-16 lg:text-base lg:pt-2 lg:ml-12">
              Guide to HR adviser and<br></br> Clients lessening
            </h1>
            <p class="xl:text-lg figtree xl:ml-16 xl:pt-2 lg:text-xs lg:pt-1 lg:ml-12">
              There are full service engage company is<br></br> to provide
              solution for employees needs…
            </p>

            <div class=" flex xl:ml-16 text-darkblack xl:text-base figtree font-semibold xl:mt-4 lg:mt-2 lg:text-sm lg:ml-12">
              <u>READ MORE</u>
              <p class="xl:ml-2">➔</p>
            </div>
          </div>

          <div className="">
            <img
              class="rounded-md xl:w-11/12 xl:ml-1 lg:w-8/12 lg:ml-4"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/blog-003-770x500.jpg"
              alt=""
            />

            <div className="flex xl:mt-8 lg:mt-4">
              <p class="text-base xl:ml-12 lg:ml-8">🚹</p>
              <p class="xl:text-base figtree xl:ml-1 lg:text-xs xl:pt-0 lg:pt-1">
                by admin
              </p>
              <p class="text-base xl:ml-4">📂</p>
              <p class="xl:text-base figtree  xl:ml-1 lg:text-xs xl:pt-0 lg:pt-1 ">
                Consultation
              </p>
              <p class="text-base xl:ml-4">🥃</p>
              <p class="xl:text-base figtree  xl:ml-1 lg:text-xs xl:pt-0 lg:pt-1">
                0
              </p>
            </div>

            <h1 class="text-darkblack font-semibold figtree xl:pt-4 xl:text-2xl xl:ml-14 lg:text-base lg:pt-2 lg:ml-10">
              Our strength Proper<br></br> Business in your Path
            </h1>
            <p class="xl:text-lg figtree xl:ml-14 xl:pt-2 lg:text-xs lg:pt-2 lg:ml-10">
              There are full service engage company is<br></br> to provide
              solution for employees needs…
            </p>

            <div class=" flex xl:ml-14 text-darkblack xl:text-base figtree font-semibold xl:mt-4 lg:text-sm lg:mt-2 lg:ml-10">
              <u>READ MORE</u>
              <p class="xl:ml-2">➔</p>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:block lg:block md:hidden hidden sm:hidden bg-newshade6 xl:mt-24 lg:mt-16">
        <div className="flex justify-center xl:p-12 lg:p-12">
          <p class="xl:text-5xl lg:text-3xl bg-white rounded-full">📆</p>
          <p class="xl:text-base1 font-bold text-white figtree xl:pt-4 xl:ml-12 lg:text-lg lg:pt-1 lg:ml-4">
            Call adviser for Emphires HR outsourcing service business
          </p>
          <button class="border xl:px-12 xl:py-4 bg-white text-darkblack xl:ml-16 rounded-lg lg:px-4 lg:py-1 lg:ml-4">
            Book A Consultant
          </button>
        </div>
      </section>

      {/*TAB VIEW*/}
      <section className="xl:hidden lg:hidden md:block sm:hidden hidden md:mt-4">
        <div className="">
          <div
            style={{
              backgroundImage: `url("https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/revslider/slider-demo-1/n-slider-01.jpg")`,
              width: "100%",
              height: "500px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <p class=" figtree  md:ml-6 md:text-sm md:pt-44 text-white ">
              HR IS ALL ABOUT PEOPLE
            </p>
            <div className="flex md:mt-2 md:ml-6">
              <p class="text-white figtree md:text-4xl font-bold ">Human</p>
              <p class="text-skyblue figtree md:text-4xl md:ml-2 font-bold">
                Resource
              </p>
            </div>
            <div class="flex md:ml-6">
              <p class="text-white figtree md:text-4xl font-bold">Solution</p>
              <p class="text-white figtree md:text-4xl md:ml-2 font-bold">
                Partner
              </p>
            </div>
            <div className="flex md:ml-6 md:mt-4">
              <a href="/Homepage2">
                <button className="md:px-4 md:py-1 bg-transparent text-white figtree rounded-md border">
                  Contact Us
                </button>
              </a>
              <a href="/Homepage2">
                <img class="md:w-8 text-white md:ml-4" src={videoplay} />
              </a>
              <p class="text-white figtree md:text-sm font-bold md:ml-4 md:pt-1">
                HOW WE WORK
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-newshade6 xl:hidden lg:hidden md:block sm:hidden hidden">
        <section className="container mx-auto">
          <div
            style={{
              backgroundImage: `url("https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-bg-02.png")`,
              width: "100%",
              height: "500px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="grid grid-cols-3 md:pt-16">
              <div className=" border rounded-md  bg-white md:ml-4">
                <div className="flex">
                  <div className="">
                    <img
                      class="md:w-12 md:ml-4 md:mt-10"
                      src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-icon-01.png"
                      alt=""
                    />
                  </div>
                  <div class="box-3">
                    <span class="transparent-text">01</span>
                  </div>
                </div>
                <p class="text-darkblack figtree md:text-base md:pt-2 font-bold md:ml-4">
                  Compensation{" "}
                </p>
                <p class="text-darkblack figtree md:text-base  font-bold md:ml-4">
                  Consulting
                </p>
                <p class="md:ml-4 figtree md:text-xs md:pb-4">
                  We provide broad menu<br></br> employment services for a
                  <br></br> companies.
                </p>
              </div>

              <div className=" border rounded-md  bg-white md:ml-4">
                <div className="flex">
                  <div className="">
                    <img
                      class="md:w-12 md:ml-4 md:mt-10"
                      src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-icon-01.png"
                      alt=""
                    />
                  </div>
                  <div class="box-3">
                    <span class="transparent-text">02</span>
                  </div>
                </div>
                <p class="text-darkblack figtree md:text-base md:pt-2 font-bold md:ml-4">
                  Leadership{" "}
                </p>
                <p class="text-darkblack figtree md:text-base  font-bold md:ml-4">
                  Training
                </p>
                <p class="md:ml-4 figtree md:text-xs md:pb-4">
                  We provide broad menu<br></br> employment services for a
                  <br></br> companies.
                </p>
              </div>

              <div className=" border rounded-md  bg-white md:ml-4 md:mr-4">
                <div className="flex">
                  <div className="">
                    <img
                      class="md:w-12 md:ml-4 md:mt-10"
                      src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-icon-01.png"
                      alt=""
                    />
                  </div>
                  <div class="box-3">
                    <span class="transparent-text">03</span>
                  </div>
                </div>
                <p class="text-darkblack figtree md:text-base md:pt-2 font-bold md:ml-4">
                  Talent{" "}
                </p>
                <p class="text-darkblack figtree md:text-base  font-bold md:ml-4">
                  Acquisition
                </p>
                <p class="md:ml-4 figtree md:text-xs md:pb-4">
                  We provide broad menu<br></br> employment services for a
                  <br></br> companies.
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2">
              <div className="flex">
                <div>
                  <img class="md:w-8 md:ml-8 md:mt-16" src={quotations2} />
                </div>
                <div>
                  <p class="text-white figtree md:text-base md:ml-12 md:mt-16 ">
                    Train people quickly well with e-business.So they highly
                    <br></br> efficient manufactured products.
                  </p>
                  <p class="text-white figtree md:text-lg md:ml-14">
                    .....Richard Branson
                  </p>
                </div>

                <div class="vl-1"></div>
              </div>
              <div>
                <p class="text-white md:text-xs figtree md:mt-16 md:ml-12">
                  Our consultants believe in the value<br></br> that you manage
                  your regulatory compliance, policies, and procedures.<br></br>{" "}
                  We have specialist for managed employee performance<br></br>
                  ,comparable to internal HR function.
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>

      <div className="xl:hidden lg:hidden md:block sm:hidden hidden md:mt-20">
        <p class="text-center figtree font-semibold">WHO WE ARE</p>
        <p class="text-center figtree md:text-3xl md:pt-1 font-bold text-darkblack">
          Why Choose HR Consulting
        </p>
        <p class="text-center figtree md:text-3xl md:pt-1 font-bold text-skyblue">
          {" "}
          Services Company
        </p>
      </div>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden">
        <div className="grid grid-rows-2 md:mt-20">
          <div>
            <div>
              <img
                class="md:w-10/12 rounded-md md:ml-16"
                src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-img-01.jpg"
                alt=""
              />
            </div>
          </div>

          <div>
            <p class=" md:mt-12 figtree text-base md:ml-14">
              Our advisers provides a broad menu service that identifies the
              task requirements<br></br> in the organization job and designs the
              task description with skill requirements for the task.
            </p>
          </div>

          <div className="flex md:mt-12">
            <div className="flex">
              <img
                class="md:w-24 md:ml-14"
                src="http://localhost:3000/static/media/consultation.d4f90c5f4bb38146222a.png"
                alt=""
              />

              <div>
                <p class="text-darkblack figtree text-2xl md:ml-2 font-bold md:mt-4">
                  Strategic Partners
                </p>
                <p class="text-sm md:ml-2 md:pt-1">
                  We believe in the value that our functions added to a business
                  Our strategic partners that specialist role for HR is success
                  of a business.
                </p>
              </div>
            </div>
          </div>

          <div className="flex md:mt-8">
            <div className="flex">
              <img
                class="md:w-24 md:ml-14 md:mt-2"
                src="http://localhost:3000/static/media/training.b7d36a241c1b615f438b.png"
                alt=""
              />

              <div>
                <p class="text-darkblack figtree text-2xl md:ml-2 font-bold md:mt-4">
                  Corporate Programs
                </p>
                <p class="text-sm md:ml-2 md:pt-1">
                  We believe in the value that our functions added to a business
                  Our strategic partners that specialist role for HR is success
                  of a business.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex md:mt-4">
          <p class="text-lg figtree md:ml-14">
            Get intrigued about our service pricing?
          </p>
          <div className="text-darkblack text-lg figtree">
            <a href="/Homepage2">
              <u>Learn More</u>
            </a>
          </div>
        </div>
      </section>

      <section className=" xl:hidden lg:hidden md:block sm:hidden hidden bg-newshade22 md:mt-16">
        <div className="figtree md:ml-14">
          <p class="text-base font-semibold md:pt-8">OUR SERVICES</p>
          <div className="flex">
            <p class="text-darkblack text-3xl font-bold md:pt-2">
              Deliver Outsourced
            </p>
            <p class="text-skyblue text-3xl font-bold md:pt-2 md:ml-1">
              HR Service's
            </p>
          </div>
          <div className="text-darkblack text-lg md:pt-2">
            <u>VIEW ALL SERVICES</u>
          </div>
          <p class="text-base md:pt-8 figtree">
            We offer to include employment services such as payroll and our
            priorities<br></br> have always remained the same: to benefits
            administration,<br></br> HR management, and assistance with a
            employer.
          </p>
        </div>
        <div className="grid grid-cols-3 md:mt-8 ">
          <div>
            <div>
              <img
                class="md:w-10/12 md:ml-12 rounded-md"
                src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/05/service-new-01-770x500.jpg"
                alt=""
              />
            </div>
          </div>

          <div>
            <div>
              <img
                class="md:w-10/12 rounded-md md:ml-6"
                src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/05/service-new-02-770x500.jpg"
                alt=""
              />
            </div>
          </div>

          <div>
            <div>
              <img
                class="md:w-10/12 rounded-md"
                src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/05/service-new-03-770x500.jpg"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 figtree">
          <div className="border md:w-8/12 bg-white md:ml-18 md:-mt-4">
            <p class="text-center text-skyblue text-sm md:pt-4 font-semibold">
              MANAGEMENT
            </p>
            <p class="text-darkbalck text-base font-bold text-center">HR </p>
            <p class="text-darkbalck text-base font-bold text-center">
              Consulting
            </p>
            <p class="text-center md:pt-1 text-base pb-12">
              HR consultants are provides broad menu Human Resource Service
            </p>
          </div>

          <div className="border md:w-8/12 bg-white md:ml-10 md:-mt-4">
            <p class="text-center text-skyblue text-sm md:pt-4 font-semibold">
              PLANNING
            </p>
            <p class="text-darkbalck text-base font-bold text-center">
              Improving{" "}
            </p>
            <p class="text-darkbalck text-base font-bold text-center">
              Resource
            </p>
            <p class="text-center md:pt-1 text-base pb-12">
              HR consultants are provides broad menu Human Resource Service
            </p>
          </div>

          <div className="border md:w-8/12 bg-white md:ml-4 md:-mt-4">
            <p class="text-center text-skyblue text-sm md:pt-4 font-semibold">
              TRAINING
            </p>
            <p class="text-darkbalck text-base font-bold text-center">
              Strategic{" "}
            </p>
            <p class="text-darkbalck text-base font-bold text-center">
              Planning
            </p>
            <p class="text-center md:pt-1 text-base pb-12">
              HR consultants are provides broad menu Human Resource Service
            </p>
          </div>
        </div>

        <div className="grid grid-cols-3 justify-center">
          <div className="w-12 h-12 rounded-full bg-newshade6 button-center md:ml-32 md:-mt-8">
            <a href="/Homepage2">
              <p class="text-2xl text-center pt-1 text-white">➔</p>
            </a>
          </div>

          <div className="w-12 h-12 rounded-full bg-newshade6 button-center md:ml-24 md:-mt-8">
            <a href="/Homepage2">
              <p class="text-2xl text-center pt-1 text-white">➔</p>
            </a>
          </div>

          <div className="w-12 h-12 rounded-full bg-newshade6 button-center md:ml-20 md:-mt-8">
            <a href="/Homepage2">
              <p class="text-2xl text-center pt-1 text-white">➔</p>
            </a>
          </div>
        </div>

        <div className="flex md:mt-12 text-center pb-16 ">
          <p class=" figtree text-sm md:ml-36">
            Get the Human Resource Outsourcing Service’s
          </p>
          <div className="text-black text-sm md:ml-1">
            <u>Book an appointment!</u>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden">
        <div
          style={{
            backgroundImage: `url("https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-bg-01.jpg")`,
            width: "100%",
            height: "500px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="justify-center">
            <img class="w-12 text-white md:ml-84 pt-32" src={videoplay} />
          </div>
          <p class="text-center text-3xl text-white font-bold p-8">
            With our specialists, and experts at<br></br> your disposal, you
            will face complex.
          </p>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden  figtree ">
        <div className="border bg-newshade6 ">
          <p class="text-white text-sm  pt-8 md:ml-14">WHO WE ARE</p>
          <div className="flex pt-1">
            <p class="text-white text-4xl font-bold md:ml-14">Subscribe</p>
            <p class="text-darkblack text-4xl font-bold md:ml-1">Newsletter</p>
          </div>
          <form className="md:ml-14 pt-4 pb-8">
            <input
              class="md:w-10/12 mt-2 border rounded-md"
              type="text"
              id="text"
              name="YOUR NAME"
              placeholder="YOUR NAME"
            />
            <br></br>
            <input
              class="md:w-10/12 mt-2 border rounded-md"
              type="text"
              id="text"
              name="YOUR EMAIL"
              placeholder="YOUR EMAIL"
            />
            <textarea
              class="mt-2 md:w-10/12 border rounded-md"
              id="Message"
              name="Message"
              rows="5"
              cols="55"
              placeholder="MESSAGE"
            ></textarea>
            <br></br>
            <button className="text-white bg-black rounded-md mt-4 px-4 py-1 ">
              Subscribe
            </button>
          </form>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-8">
        <div>
          <div>
            <img
              class="md:w-10/12 rounded-md ml-16 "
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-img-06.jpg"
              alt=""
            />
          </div>
          <button class="text-white bg-black md:w-10/12 mt-2 text-center text-lg rounded-md ml-16 p-2">
            100% Sucess
          </button>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-12">
        <div className="justify-center figtree">
          <p class="text-lg font-semibold text-center ">OUR APPROACH</p>
          <div className="flex justify-center mt-4">
            <p class="text-darkblack font-bold text-3xl">We Choose a Human</p>
            <p class="text-skyblue font-bold text-3xl md:ml-1">Resources</p>
          </div>
          <p class="text-base text-center px-12 mt-4">
            We have our special functions are add to a business. We feel that
            this special functions is part ofHR its piece of the success
            business.
          </p>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-12">
        <div className="grid grid-cols-2">
          <div>
            <div>
              <img
                class="w-24 ml-32"
                src="http://localhost:3000/static/media/consultation.d4f90c5f4bb38146222a.png"
                alt=""
              />
            </div>
            <p class="text-center text-darkblack font-bold text-2xl figtree">
              Compensation Consulting
            </p>
            <p class="text-center text-sm figtree px-12 ">
              Compensation Consulting are always help for a companies marketing,
              customer growths.
            </p>
          </div>

          <div>
            <div>
              <img
                class="w-24 ml-32"
                src="http://localhost:3000/static/media/consultation.d4f90c5f4bb38146222a.png"
                alt=""
              />
            </div>
            <p class="text-center text-darkblack font-bold text-2xl figtree">
              Leadership Training
            </p>
            <p class="text-center text-sm figtree px-12 ">
              Compensation Consulting are always help for a companies marketing,
              customer growths.
            </p>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden justify-center mt-12">
        <div className="">
          <div>
            <img
              class="w-24 md:ml-84"
              src="http://localhost:3000/static/media/training.b7d36a241c1b615f438b.png"
              alt=""
            />
          </div>
          <p class="text-center text-darkblack font-bold text-2xl figtree">
            Corporated Programs
          </p>
          <p class="text-center text-sm figtree  ">
            Compensation Consulting are always help for<br></br> a companies
            marketing, customer growths.
          </p>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden bg-newshade6 mt-20">
        <div
          style={{
            backgroundImage: `url("https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/01/map-02.png")`,
            width: "100%",
            height: "800px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="grid grid-cols-3 ">
            <div>
              <img
                class="md:w-10/12 rounded-md md:-mt-8 ml-8"
                src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/project-new-01-770x770.jpg"
                alt=""
              />
            </div>

            <div>
              <img
                class="md:w-10/12 rounded-md md:-mt-8 ml-4"
                src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/project-new-03-770x770.jpg"
                alt=""
              />
            </div>

            <div>
              <img
                class="md:w-10/12 rounded-md md:-mt-8"
                src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/project-new-04-770x770.jpg"
                alt=""
              />
            </div>
          </div>

          <p class="text-white font-semibold figtree pt-8 ml-8">
            OUR TESTIMONIAL
          </p>
          <div className=" mt-2">
            <p class="text-white text-3xl font-bold ml-8">
              What Employ Say About
            </p>
            <p class="text-darkblack text-3xl font-bold ml-8">
              a Human Resources
            </p>
          </div>
          <p class="text-white text-sm font-semibold ml-8 mt-2">
            We have introduced the value that our functions. We<br></br> feel
            that this specialist part of Human Resources are contribution in
            digital marketing
          </p>
          <div className="text-darkblack text-lg figtree font-bold ml-8 mt-2">
            <a href="/Homepage2">
              <u>VIEW ALL TESTIMONIALS ➜</u>
            </a>
          </div>

          <div>
            <div className="mt-12">
              <img
                class="w-24 rounded-md ml-12"
                src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/testimonial-01.jpg"
                alt=""
              />
            </div>
            <div className="border rounded-md md:w-10/12 md:-mt-8 bg-white text-darkblack ml-16">
              <p class="figtree p-8 text-center ">
                HR Adviser responded quickly to questions and work with thanks
                for picking the most expert employee’s for our company. These
                guys are real best adviser & helped so many times
              </p>
              <div className="flex justify-center md:-mt-4 pb-12 ">
                <p class="text-skyblue figtree font-bold ">Jasics Lenon</p>
                <p class=" figtree font-semibold ">-Manager</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-12">
        <div className="grid grid-cols-3">
          <div>
            <img
              class="md:w-8/12 ml-16"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/client-01-1.png"
              alt=""
            />
          </div>

          <div>
            <img
              class="md:w-8/12 ml-10"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/client-04-1.png"
              alt=""
            />
          </div>

          <div>
            <img
              class="md:w-8/12"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/client-02-1.png"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-20">
        <div>
          <p class="text-center font-semibold text-lg">SUCCESS STORIES</p>
          <div className="flex justify-center">
            <p class="text-darkblack text-3xl font-bold">Cources From Human</p>
            <p class="text-skyblue text-3xl font-bold md:ml-1">Resource</p>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16">
        <div>
          <div>
            <img
              class="md:w-8/12 ml-32 rounded-md"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/blog-01-1-770x500.jpg"
              alt=""
            />
          </div>
          <div className="flex mt-4 ">
            <p class="text-3xl ml-40">🚹</p>
            <p class="text-xl pt-2 font-bold">by admin</p>
            <p class=" text-3xl ml-12">📂</p>
            <p class="text-xl pt-2 font-bold">Consultation</p>
            <p class="text-3xl ml-8">🥃</p>
            <p class="text-xl pt-2 font-bold">0</p>
          </div>
          <div className="mt-4">
            <p class="text-center text-3xl text-darkblack font-bold">
              Best Service provides for
            </p>
            <p class="text-center text-3xl text-darkblack font-bold">
              Small Businesses
            </p>
            <p class="text-center text-lg pt-2 ml-4">
              There are full service engage company is<br></br> to provide
              solution for employees needs…
            </p>
          </div>
          <div className="flex text-darkblack justify-center text-xl font-bold mt-4">
            <a href="/Homepage2">
              <u>READ MORE</u>
            </a>
          </div>


          <div>
            <img
              class="md:w-8/12 ml-32 mt-16 rounded-md"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/blog-002-770x500.jpg"
              alt=""
            />
          </div>
          <div className="flex mt-4 ">
            <p class="text-3xl ml-40">🚹</p>
            <p class="text-xl pt-2 font-bold">by admin</p>
            <p class=" text-3xl ml-12">📂</p>
            <p class="text-xl pt-2 font-bold">Consultation</p>
            <p class="text-3xl ml-8">🥃</p>
            <p class="text-xl pt-2 font-bold">0</p>
          </div>
          <div className="mt-4">
            <p class="text-center text-3xl text-darkblack font-bold">
              Best Service provides for
            </p>
            <p class="text-center text-3xl text-darkblack font-bold">
              Small Businesses
            </p>
            <p class="text-center text-lg pt-2 ml-4">
              There are full service engage company is<br></br> to provide
              solution for employees needs…
            </p>
          </div>
          <div className="flex text-darkblack justify-center text-xl font-bold mt-4">
            <a href="/Homepage2">
              <u>READ MORE</u>
            </a>
          </div>



          <div>
            <img
              class="md:w-8/12 ml-32 rounded-md mt-16"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/blog-003-770x500.jpg"
              alt=""
            />
          </div>
          <div className="flex mt-4 ">
            <p class="text-3xl ml-40">🚹</p>
            <p class="text-xl pt-2 font-bold">by admin</p>
            <p class=" text-3xl ml-12">📂</p>
            <p class="text-xl pt-2 font-bold">Consultation</p>
            <p class="text-3xl ml-8">🥃</p>
            <p class="text-xl pt-2 font-bold">0</p>
          </div>
          <div className="mt-4">
            <p class="text-center text-3xl text-darkblack font-bold">
              Best Service provides for
            </p>
            <p class="text-center text-3xl text-darkblack font-bold">
              Small Businesses
            </p>
            <p class="text-center text-lg pt-2 ml-4">
              There are full service engage company is<br></br> to provide
              solution for employees needs…
            </p>
          </div>
          <div className="flex text-darkblack justify-center text-xl font-bold mt-4">
            <a href="/Homepage2">
              <u>READ MORE</u>
            </a>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:block sm:hidden hidden mt-16 bg-newshade6">
        <div className="justify-center pb-8">
          <p class="text-4xl text-center pt-8">📆</p>
          <p class="text-xl text-white font-bold text-center pt-4">
            Call adviser for Emphires HR outsourcing service business
          </p>
          <button class="text-white bg-black px-8 py-2 rounded-md ml-72 mt-4">
            Book A Consultant
          </button>
        </div>
      </section>

      {/*MOBILE VIEW*/}

      <section className="xl:hidden lg:hidden md:hidden sm:block block">
        <div className="">
          <div
            style={{
              backgroundImage: `url("https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/revslider/slider-demo-1/n-slider-01.jpg")`,
              width: "100%",
              height: "300px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <p class=" figtree text-white pt-20 ml-6 text-sm ">
              HR IS ALL ABOUT PEOPLE
            </p>
            <div className="flex mt-2 ml-6">
              <p class="text-white  figtree text-3xl font-bold ">Human</p>
              <p class="text-skyblue figtree  text-3xl ml-2 font-bold">
                Resource
              </p>
            </div>
            <div class="flex ml-6">
              <p class="text-white figtree text-3xl font-bold">Solution</p>
              <p class="text-white figtree text-3xl ml-2 font-bold">Partner</p>
            </div>
            <div className="flex ml-6 mt-4">
              <a href="/Homepage2">
                <button className="px-4 py-1 bg-transparent text-white figtree rounded-md border">
                  Contact Us
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block bg-newshade6">
        <div className="pt-8">
          <div className="w-10/12 rounded-md bg-white ml-8 ">
            <div className=" flex">
              <img
                class="w-24 pt-8 ml-6"
                src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-icon-01.png"
                alt=""
              />
              <div class="box-4">
                <span class="transparent-text">01</span>
              </div>
            </div>
            <p class="text-xl text-darkblack font-bold ml-6 figtree">
              Compensation
            </p>
            <p class="text-xl text-darkblack font-bold ml-6 figtree">
              Consulting
            </p>
            <p class="text-base figtree ml-6 pt-1 pb-8">
              We provide broad menu<br></br> employment services for a<br></br>{" "}
              companies.
            </p>
          </div>
        </div>

        <div className="pt-8">
          <div className="w-10/12 rounded-md bg-white ml-8 ">
            <div className=" flex">
              <img
                class="w-24 pt-8 ml-6"
                src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-icon-02.png"
                alt=""
              />
              <div class="box-4">
                <span class="transparent-text">02</span>
              </div>
            </div>
            <p class="text-xl text-darkblack font-bold ml-6 figtree">
              Leadership
            </p>
            <p class="text-xl text-darkblack font-bold ml-6 figtree">
              Training
            </p>
            <p class="text-base figtree ml-6 pt-1 pb-8">
              We provide broad menu<br></br> employment services for a<br></br>{" "}
              companies.
            </p>
          </div>
        </div>

        <div className="pt-8 pb-8">
          <div className="w-10/12 rounded-md bg-white ml-8 ">
            <div className=" flex">
              <img
                class="w-24 pt-8 ml-6"
                src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-icon-03.png"
                alt=""
              />
              <div class="box-4">
                <span class="transparent-text">03</span>
              </div>
            </div>
            <p class="text-xl text-darkblack font-bold ml-6 figtree">Talent </p>
            <p class="text-xl text-darkblack font-bold ml-6 figtree">
              Acquisition
            </p>
            <p class="text-base figtree ml-6 pt-1 pb-8">
              We provide broad menu<br></br> employment services for a<br></br>{" "}
              companies.
            </p>
          </div>
        </div>

        <div class="flex mt-12 pb-12">
          <div>
            <img class="w-12 ml-12" src={quotations2} />
          </div>
          <div>
            <p class="text-white text-base ml-4 figtree">
              Train people quickly well<br></br> with e-business.So they
              <br></br> highly efficient<br></br> manufactured products.
            </p>
            <p class="text-center text-lg text-white figtree">
              .....Richard Branson
            </p>
          </div>
          <div class="vl-2"></div>
        </div>

        <div className="mt-4 pb-12">
          <p class="text-sm text-white ml-12 figtree">
            Our consultants believe in the value that you<br></br> manage your
            regulatory compliance, policies,<br></br> and procedures. We have
            specialist for managed employee performance<br></br>,comparable to
            internal HR function.
          </p>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-12">
        <div>
          <p class="figtree text-lg text-center figtree">WHO WE ARE</p>
          <p class="text-darkblack text-2xl font-bold text-center figtree">
            Why Choose HR Consulting
          </p>
          <p class="text-skyblue text-2xl font-bold text-center figtree">
            Services Company
          </p>
        </div>

        <div>
          <img
            class="w-10/12 ml-8 mt-12"
            src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-img-01.jpg"
            alt=""
          />
        </div>

        <div>
          <p class="ml-8 text-base mt-12 figtree">
            Our advisers provides a broad menu<br></br> service that identifies
            the task<br></br> requirements in the organization job<br></br> and
            designs the task description with<br></br> skill requirements for
            the task.
          </p>
        </div>

        <div className="flex mt-12">
          <div>
            <img
              class="w-24 ml-8"
              src="http://localhost:3000/static/media/consultation.d4f90c5f4bb38146222a.png"
              alt=""
            />
          </div>
          <div>
            <p class="text-darkblack text-base ml-4 font-bold figtree">
              Strategic Partners
            </p>
            <p class="text-sm ml-4 pt-1 figtree ">
              We believe in the value that our<br></br> functions added to a
              business<br></br> Our strategic partners that<br></br> specialist
              role for HR is success<br></br> of a business
            </p>
          </div>
        </div>

        <div className="flex mt-4">
          <div>
            <img
              class="w-24 ml-8"
              src="http://localhost:3000/static/media/training.b7d36a241c1b615f438b.png"
              alt=""
            />
          </div>
          <div>
            <p class="text-darkblack text-base ml-4 font-bold figtree">
              Corporate Programs
            </p>
            <p class="text-sm ml-4 pt-1 figtree">
              We believe in the value that our<br></br> functions added to a
              business<br></br> Our strategic partners that<br></br> specialist
              role for HR is success<br></br> of a business
            </p>
          </div>
        </div>

        <div>
          <p class="text-darkblack text-base mt-8 ml-8 figtree">
            Get intrigued about our service pricing?
          </p>
          <div className="text-darkblack text-base ml-8 figtree">
            <a href="/Homepage2">
              <u>Learn More</u>
            </a>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block bg-newshade22 mt-12">
        <div className="pt-16">
          <p class=" ml-8  text-sm figtree">OUR SERVICES</p>
          <p class="text-darkblack ml-8 text-2xl font-bold pt-1 figtree">
            Deliver Outsourced
          </p>
          <p class="text-skyblue ml-8 text-2xl font-bold figtree">
            HR Service's
          </p>
          <div className="ml-8 mt-4 text-base text-darkblack font-bold figtree">
            <u>VIEW ALL SERVICES</u>
          </div>
          <p class="text-sm ml-8 pt-6 figtree">
            We offer to include employment services<br></br> such as payroll and
            our priorities have<br></br> always remained the same: to benefits
            <br></br> administration, HR management, and<br></br> assistance
            with a employer.
          </p>
        </div>

        <div className="mt-8">
          <div>
            <img
              class="w-10/12 ml-8 rounded-md"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/05/service-new-01-770x500.jpg"
              alt=""
            />
          </div>
          <div className="z-50 absolute w-8/12 bg-white border -mt-12 rounded-md ml-16 figtree">
              <p class="text-base text-skyblue text-center pt-8">MANAGEMENT</p>
              <p class="text-xl text-darkblack text-center font-bold">HR Consulting</p>
              <p class="text-sm text-center pb-8">HR consultants are provides<br></br> broad menu Human Resource<br></br> Service</p>
              </div>

              <div className="">
              <div className="w-12 h-12 z-50 absolute rounded-full bg-black mt-26 ml-44">
          <p class="text-2xl text-center text-white pt-1">➔</p>
              </div>
              </div>
             </div>

             
         <div className="mt-48">
          <div>
            <img
              class="w-10/12 ml-8 rounded-md"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/05/service-new-02-770x500.jpg"
              alt=""
            />
          </div>
          <div className="z-50 absolute w-8/12 bg-white border -mt-12 rounded-md ml-16 figtree">
              <p class="text-base text-skyblue text-center pt-8">PLANNING</p>
              <p class="text-xl text-darkblack text-center font-bold">Improving Resource</p>
              <p class="text-sm text-center pb-8">HR consultants are provides<br></br> broad menu Human Resource<br></br> Service</p>
              </div>

              <div className="">
              <div className="w-12 h-12 z-50 absolute rounded-full bg-black mt-26 ml-44">
          <p class="text-2xl text-center text-white pt-1">➔</p>
              </div>
              </div>
        </div>

        <div className="mt-48">
          <div>
            <img
              class="w-10/12 ml-8 rounded-md"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/05/service-new-03-770x500.jpg"
              alt=""
            />
          </div>
          <div className="z-50 absolute w-8/12 bg-white border -mt-12 rounded-md ml-16 figtree">
              <p class="text-base text-skyblue text-center pt-8">TRAINING</p>
              <p class="text-xl text-darkblack text-center font-bold">Strategic Planning</p>
              <p class="text-sm text-center pb-8">HR consultants are provides<br></br> broad menu Human Resource<br></br> Service</p>
              </div>

              <div className="">
              <div className="w-12 h-12 z-50 absolute rounded-full bg-black mt-26 ml-44">
          <p class="text-2xl text-center text-white pt-1">➔</p>
              </div>
              </div>
        </div>

        <div className="mt-48 figtree pb-12">
          <p class="text-lg mt-12 text-center">
            Get the Human Resource Outsourcing
          </p>
          <div className="flex justify-center">
            <p class="text-lg"> Service’s</p>
            <div className="text-darkblack text-lg ml-1">
              <u>Book an appointment</u>
            </div>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-12">
        <div
          style={{
            backgroundImage: `url("https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-bg-01.jpg")`,
            width: "100%",
            height: "300px",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="justify-center center-button pt-16">
            <img class="w-12 " src={videoplay} />
          </div>
          <div>
            <p class="text-center text-white text-2xl font-bold figtree pt-1">
              With our<br></br> specialists, and<br></br> experts at your
              disposal,<br></br> you will face<br></br> complex.
            </p>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block bg-newshade6 ">
        <div>
          <p class="text-white text-lg font-seminbold pt-8 ml-12 figtree">
            WHO WE ARE
          </p>
        </div>
        <div className="flex figtree">
          <p class="text-white text-2xl ml-12 font-bold">Subscribe</p>
          <p class="text-darkblack text-2xl ml-1 font-bold ">Newsletter</p>
        </div>
        <div>
          <form className="pt-8 ml-12 pb-8">
            <input
              class="w-10/12 border rounded-md"
              type="text"
              id="text"
              name="text"
              placeholder="YOUR NAME"
            />
            <input
              class="w-10/12 mt-2 border rounded-md"
              type="text"
              id="text"
              name="text"
              placeholder="YOUR EMAIL"
            />
            <textarea
              class="mt-2 w-10/12 border rounded-md"
              id="Message"
              name="Message"
              rows="3"
              cols="55"
              placeholder="MESSAGE"
            ></textarea>
            <br></br>
            <button className="text-white bg-black rounded-md mt-4 px-4 py-1 ">
              Subscribe
            </button>
          </form>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-12">
        <div>
          <img
            class="w-10/12 rounded-md ml-8"
            src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2020/02/n-img-06.jpg"
            alt=""
          />
        </div>
        <button class="w-10/12 bg-black text-white figtree px-20 py-2 rounded-md ml-8 mt-2">
          100% Success
        </button>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-16 figtree">
        <div>
          <p class="text-center text-lg font-semibold ">OUR APPROACH</p>
          <div className="justify-center mt-2">
            <p class="text-darkblack text-2xl font-bold text-center">
              We Choose a Human
            </p>
            <p class="text-skyblue text-2xl font-bold text-center">Resources</p>
          </div>
          <p class="text-base text-center mt-2 ">
            We have our special functions are add<br></br> to a business. We
            feel that this special<br></br> functions is part of HR its piece of
            the<br></br> success business.
          </p>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block mt-12 figtree">
        <div>
          <div className="center-button">
            <img
              class="w-24"
              src="http://localhost:3000/static/media/consultation.d4f90c5f4bb38146222a.png"
              alt=""
            />
          </div>

          <p class="text-center text-lg text-darkblack pt-4 font-bold">
            Compensation Consulting
          </p>
          <p class="text-center text-sm pt-1">
            Compensation Consulting are always<br></br> help for a companies
            marketing,<br></br> customer growths
          </p>
        </div>

        <div className="mt-8">
          <div className="center-button">
            <img
              class="w-24"
              src="http://localhost:3000/static/media/training.b7d36a241c1b615f438b.png"
              alt=""
            />
          </div>

          <p class="text-center text-lg text-darkblack pt-4 font-bold">
            Leadership Training
          </p>
          <p class="text-center text-sm pt-1">
            Compensation Consulting are always<br></br> help for a companies
            marketing,<br></br> customer growths
          </p>
        </div>

        <div className="mt-8">
          <div className="center-button">
            <img
              class="w-24"
              src="http://localhost:3000/static/media/consultation.d4f90c5f4bb38146222a.png"
              alt=""
            />
          </div>

          <p class="text-center text-lg text-darkblack pt-4 font-bold">
            Corporated Programs
          </p>
          <p class="text-center text-sm pt-1">
            Compensation Consulting are always<br></br> help for a companies
            marketing,<br></br> customer growths
          </p>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block bg-newshade6 figtree mt-24 pb-20">
        <div>
          <div className="center-button ">
            <img
              class="w-10/12 -mt-12 rounded-md"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/project-new-01-770x770.jpg"
              alt=""
            />
          </div>
          <p class="text-white text-lg ml-8 pt-8">OUR TESTIMONIAL</p>
          <div className="flex">
            <p class="text-white text-2xl font-bold ml-8 pt-1">
              What Employ Say About
            </p>
            <p class="text-darkblack text-2xl font-bold ml-1">A</p>
          </div>
          <p class="text-darkblack text-2xl font-bold ml-8 ">Human Resources</p>
          <p class="text-sm text-white ml-8 pt-4">
            We have introduced the value that our<br></br> functions. We feel
            that this specialist<br></br> part of Human Resources are<br></br>{" "}
            contribution in digital marketing
          </p>
          <div className="text-darkblack text-sm mt-4 ml-8">
            <u>VIEW ALL TESTIMONIALS</u>
          </div>

          <div>
            <img
              class="w-24 rounded-md ml-12 mt-8"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/testimonial-01.jpg"
              alt=""
            />
          </div>

          <div>
            <div className=" w-8/12 bg-white ml-16 -mt-4 rounded-md p-8">
              <p class="text-sm text-black">
                HR Adviser responded<br></br> quickly to questions and<br></br>{" "}
                work with thanks for<br></br> picking the most expert<br></br>{" "}
                employee’s for our<br></br> company. These guys are<br></br>{" "}
                real best adviser &<br></br> helped so many times
              </p>
              <p class="text-skyblue text-base pt-2">Jasics Lenon</p>
            </div>
          </div>
        </div>
      </section>

      <div className="ml-24 mt-8 xl:hidden lg:hidden md:hidden sm:block block">
        <img
          class="w-8/12"
          src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/client-01-1.png"
          alt=""
        />
      </div>

      <section className="xl:hidden lg:hidden md:hidden sm:block block figtree mt-24">
        <div>
          <div>
            <p class="text-sm text-center font-semibold">SUCCESS STORIES</p>
            <p class="text-2xl text-center text-darkblack font-bold">
              Cources From Human
            </p>
            <p class="text-2xl text-center text-skyblue font-bold">Resource</p>
          </div>

          <div>
            <img
              class="w-10/12 ml-8 mt-8 rounded-md"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/blog-01-1-770x500.jpg"
              alt=""
            />
          </div>
          <div className="flex mt-4 ">
            <p class="text-2xl ml-12">🚹</p>
            <p class="text-sm pt-2 font-bold">by admin</p>
            <p class=" text-2xl ml-4">📂</p>
            <p class="text-sm pt-2 font-bold">Consultation</p>
            <p class="text-2xl ml-4">🥃</p>
            <p class="text-sm pt-2 font-bold">0</p>
          </div>
          <div className="mt-4">
            <p class="text-center text-2xl text-darkblack font-bold">
              Best Service provides for
            </p>
            <p class="text-center text-2xl text-darkblack font-bold">
              Small Businesses
            </p>
            <p class="text-center text-sm pt-2 ml-4">
              There are full service engage company is<br></br> to provide
              solution for employees needs…
            </p>
          </div>
          <div className="flex text-darkblack justify-center text-xl font-bold mt-4">
            <a href="/Homepage2">
              <u>READ MORE</u>
            </a>
          </div>



          <div>
            <img
              class="w-10/12 ml-8 mt-12 rounded-md"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/blog-002-770x500.jpg"
              alt=""
            />
          </div>
          <div className="flex mt-4 ">
            <p class="text-2xl ml-12">🚹</p>
            <p class="text-sm pt-2 font-bold">by admin</p>
            <p class=" text-2xl ml-4">📂</p>
            <p class="text-sm pt-2 font-bold">Consultation</p>
            <p class="text-2xl ml-4">🥃</p>
            <p class="text-sm pt-2 font-bold">0</p>
          </div>
          <div className="mt-4">
            <p class="text-center text-2xl text-darkblack font-bold">
              Best Service provides for
            </p>
            <p class="text-center text-2xl text-darkblack font-bold">
              Small Businesses
            </p>
            <p class="text-center text-sm pt-2 ml-4">
              There are full service engage company is<br></br> to provide
              solution for employees needs…
            </p>
          </div>
          <div className="flex text-darkblack justify-center text-xl font-bold mt-4">
            <a href="/Homepage2">
              <u>READ MORE</u>
            </a>
          </div>



          <div>
            <img
              class="w-10/12 ml-8 mt-12 rounded-md"
              src="https://emphires-demo.pbminfotech.com/elementor-demo1/wp-content/uploads/sites/14/2019/06/blog-003-770x500.jpg"
              alt=""
            />
          </div>
          <div className="flex mt-4 ">
            <p class="text-2xl ml-12">🚹</p>
            <p class="text-sm pt-2 font-bold">by admin</p>
            <p class=" text-2xl ml-4">📂</p>
            <p class="text-sm pt-2 font-bold">Consultation</p>
            <p class="text-2xl ml-4">🥃</p>
            <p class="text-sm pt-2 font-bold">0</p>
          </div>
          <div className="mt-4">
            <p class="text-center text-2xl text-darkblack font-bold">
              Best Service provides for
            </p>
            <p class="text-center text-2xl text-darkblack font-bold">
              Small Businesses
            </p>
            <p class="text-center text-sm pt-2 ml-4">
              There are full service engage company is<br></br> to provide
              solution for employees needs…
            </p>
          </div>
          <div className="flex text-darkblack justify-center text-xl font-bold mt-4">
            <a href="/Homepage2">
              <u>READ MORE</u>
            </a>
          </div>
        </div>
      </section>

      <section className="xl:hidden lg:hidden md:hidden sm:block block bg-newshade6 mt-16 figtree pb-12">
        <div className="flex pt-8">
          <p class="text-5xl ml-12 ">📆</p>
          <p class="text-2xl font-bold text-white ml-4">
            Call adviser for<br></br> Emphires HR<br></br> outsourcing service
            <br></br> business
          </p>
        </div>
        <button class="text-white px-12 py-2 mt-4 ml-16 text-lg border rounded-md">
          Book A Consultant
        </button>
      </section>
      <Footer2 />
    </section>
  );
}
