import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Modal from 'react-modal';
import EC22 from "assets/img/EC22.jpg";
import EC23 from "assets/img/EC23.jpg";
import EC24 from "assets/img/EC24.jpg";
import EC25 from "assets/img/EC25.jpg";
import gallery1 from "assets/img/gallery1.jpg";
import gallery2 from "assets/img/gallery2.jpg";
import gallery3 from "assets/img/gallery3.jpg";
import gallery4 from "assets/img/gallery4.jpg";
import gallery5 from "assets/img/gallery5.jpg";
import gallery6 from "assets/img/gallery6.jpg";
import gallery7 from "assets/img/gallery7.jpg";
import gallery8 from "assets/img/gallery8.jpg";

const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} text-red-500 cursor-pointer`}
      style={{ ...style, display: "block", zIndex: 1 }}
      onClick={onClick}
    >
      <span className="text-2xl">{"<"}</span>
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} text-blue-500 cursor-pointer`}
      style={{ ...style, display: "block", zIndex: 1 }}
      onClick={onClick}
    >
      <span className="text-2xl">{">"}</span>
    </div>
  );
};

const GallerySlider = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [gallery1, gallery2, gallery3, gallery4, gallery5, gallery6, gallery7, gallery8];

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <section className="gallery-slider">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} onClick={() => openModal(index)}>
            <img className="w-full h-auto rounded-md cursor-pointer" src={image} alt={`gallery-${index}`} />
          </div>
        ))}
      </Slider>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <button onClick={closeModal} className="close-button">X</button>
        <div className="modal-content">
          <button onClick={prevImage} className="nav-button">{"<"}</button>
          <img className="modal-image" src={images[currentImageIndex]} alt={`gallery-${currentImageIndex}`} />
          <button onClick={nextImage} className="nav-button">{">"}</button>
        </div>
      </Modal>
    </section>
  );
};

export default GallerySlider;
